import React from "react";
import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import { unfollowUser } from "../../store/follow/followSlice";
import { useDispatch } from "react-redux";
import Defaultimg from "../../assets/images/profile/placeholder.jpg"

function FollowingCard({ details }) {
  const dispatch = useDispatch();

  const handleUnfollow = (userId) => {
    dispatch(unfollowUser(userId));
  };

  return (
    <Box sx={{ flexGrow: 1, p: 3 }}>
      <Grid container spacing={2}>
        {Array.isArray(details) && details.length > 0 ? (
          details.map((item, index) => (
            <Grid item xs={12} sm={6} md={3} key={item.id || index}>
              <Card
                sx={{
                  width: "100%",
                  boxShadow: "none",
                  border: "none",
                  borderRadius: 0,
                }}
              >
                <CardActionArea>
                  <CardMedia
                    component="img"
                    height="194px"
                    width="223px"
                    image={item.image || Defaultimg}
                    alt={`${item.firstName} ${item.lastName}`}
                  />
                  <CardContent>
                    <Typography
                      variant="h6"
                      component="div"
                      sx={{ marginLeft: "-10px", marginTop: "-10px" }}
                    >
                      {item.firstName} {item.lastName}
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        marginLeft: "-10px",
                      }}
                    >
                      <Typography variant="body2">
                        {item.follower || "No followers"}
                      </Typography>{" "}
                      <Divider
                        orientation="vertical"
                        variant="middle"
                        flexItem
                        style={{
                          marginLeft: "4px",
                          marginRight: "4px",
                          height: "15px",
                          color: "black",
                        }}
                      />
                      <Typography variant="body2">{item.ads}</Typography>
                    </Box>
                  </CardContent>
                </CardActionArea>
                <CardActions sx={{ p: 0 }}>
                  <Button
                    variant="contained"
                    fullWidth
                    sx={{
                      marginTop: "-10px",
                      boxShadow: "none",
                      border: "none",
                      borderRadius: 0,
                      backgroundColor: "#F7F7F8",
                      color: "red",
                      "&:hover": {
                        backgroundColor: "#F7F7F8",
                        boxShadow: "none",
                        border: "none",
                        borderRadius: 0,
                      },
                    }}
                    onClick={() => handleUnfollow(item.id)}
                  >
                    Unfollow
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          ))
        ) : (
          <Grid item xs={12}>
            <Typography variant="h6" color="textSecondary">
              No follows found.
            </Typography>
          </Grid>
        )}
      </Grid>
    </Box>
  );
}

export default FollowingCard;
