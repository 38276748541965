import React from "react";
import {
  Dialog,
  DialogContent,
  Box,
} from "@mui/material";
import {
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";
import { useSelector } from "react-redux";

const ProductFeedback = {
  scam: "Scam or fraud?",
  illegal: "Selling illegal or regulated goods?",
  nudity: "Nudity or sexual acts?",
  hate: "Hate speech or symbols?",
  other: "Other reasons?",
};

const reportIcon = {
  color: "#BB1A1A",
};

const reportBtn = {
  textTransform: "none",
  color: "#000",
  fontSize: "13px",
  lineHeight: "18px",
  font: "Work Sans",
};

const ShareAdsDialogBox = ({ open, onClose, slug }) => {
  
  const baseUrl = `${window.location.origin}/product-details`;
  // const productUrl = slug ? `${baseUrl}/${slug}` : `${baseUrl}/${adId}`;
  // const productUrl = `${baseUrl}/${slug}`
  const productUrl = slug ? `${baseUrl}/${slug}` : baseUrl;

  return (
    <>
      <Box mt={2}>
        <Dialog open={open} onClose={onClose} PaperProps={{}}>
          <DialogContent>
            <Box
              sx={{
                display: "flex",
                gap: 2,
              }}
            >
              <FacebookShareButton
                url={productUrl}
                quote={"https://www.facebook.com/ashi.dwivedi.5895"}
              >
                <FacebookIcon size={32} round />
              </FacebookShareButton>

              <TwitterShareButton
                url={"https://x.com/i/flow/login"}
                title={"twitter"}
              >
                <TwitterIcon size={32} round />
              </TwitterShareButton>

              <LinkedinShareButton
                url={"https://www.linkedin.com/home"}
                title={"linkedin"}
              >
                <LinkedinIcon size={32} round />
              </LinkedinShareButton>

              <WhatsappShareButton url={productUrl}>
                <WhatsappIcon size={32} round />
              </WhatsappShareButton>
            </Box>
          </DialogContent>
        </Dialog>
      </Box>
    </>
  );
};

export default ShareAdsDialogBox;
