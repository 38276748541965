import React from "react";
import { Box, List, Typography } from "@mui/material";
import Header from "../Header/Header";
import { Link } from "react-router-dom";

// Components

function TermsConditions() {

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  return (
    <>
      <Box sx={{ dispay: "flex" }} mt={10.4}>
        <Header />
        <Box
          sx={{
            padding: "20px",
            backgroundColor: "#fff",
            margin: "20px",
          }}
        >
          <Typography
            variant="h3"
            display="flex"
            justifyContent="center"
            textAlign="center"
            fontSize={40}
            fontWeight={600}
          >
            Allgemeine Nutzungs- und Geschäftsbedingungen März 2024
          </Typography>
          <Typography
            variant="h5"
            fontSize={35}
            fontWeight={800}
            mt={2.5}
            display="flex"
            textAlign="center"
            justifyContent="center"
          >
            Einleitende Bestimmungen
          </Typography>
          <ol>
            <Typography variant="h5" fontSize={25} fontWeight={600}>
              <li>Vorwort</li>
            </Typography>
            <Typography variant="body1" mt={1.7}>
              Die VerkaufAlles Internet Services GmbH (in Folge „VerkaufAlles“)
              betreibt eine Online Plattform unter der Seite{" "}
              <Link
                href="https://verkaufweb.semseosmo.com/"
                target="_blank"
                rel="noopener"
              >
                https://verkaufweb.semseosmo.com/
              </Link>{" "}
              Ziel dieser Plattform, welche als App und als Homepage betrieben
              wird, ist es, privaten Personen wie auch gewerblichen Kunden einen
              Marktplatz für diverse Produktkategorien zu bieten für:
            </Typography>
            <ol
              style={{
                listStyle: "disc",
                marginTop: "5px",
              }}
            >
              <li>
                diverse Gebrauchsgegenstände, für die keine Sonderbestimmungen
                gelten,
              </li>
              <li>die Vermittlung von Immobilien,</li>
              <li>die Vermittlung von Fortbewegungsmitteln und Zubehör</li>
              <li>die Vermittlung von Tieren und Zubehör</li>
            </ol>
            <Typography variant="body1" mt={1.7}>
              wie auch ein Suchportal für diverse Suchanfragen (in Folge
              „Leistungen“) Das Ankündigungs- und Suchportal kann von privaten
              Personen wie auch gewerblichen Kunden (in Folge „Kunden“) genutzt
              werden in dem die von den Kunden angebotenen Leistungen oder
              gestellten Suchanfragen durch Inserate durch VerkaufAlles
              veröffentlicht werden oder die veröffentlichten Inserate von den
              Kunden eingesehen werden und mit den Inserenten Kontakt
              aufgenommen werden.
            </Typography>
            <Typography variant="h5" fontSize={25} fontWeight={600} mt={2.5}>
              <li>Allgemeines</li>
            </Typography>
            <Typography variant="body1" mt={1.7}>
              Die nachstehenden Allgemeinen Nutzungs- und Geschäftsbedindungen
              (in Folge „AGB“) gelten für die Internetseite{" "}
              <Link
                href="https://verkaufweb.semseosmo.com/"
                target="_blank"
                rel="noopener"
              >
                https://verkaufweb.semseosmo.com/
              </Link>{" "}
              sowie alle dazu gehörenden Subdomains und Webseiten, wie auch für
              die APP „VerkaufAlles“.
            </Typography>
            <Typography variant="body1" mt={1.7}>
              VerkaufAlles behält sich das Recht vor die AGB jederzeit
              abzuändern oder zu ergänzen. Die geänderten Bedingungen finden auf
              laufende Verträge keine Anwendung, sofern die Parteien die
              Anwendung der neuen AGB auf bestehende Vertragsverhältnisse nicht
              schriftlich, wobei dafür Textform und daher auch die E-Mail-Form
              genügt, vereinbaren. Allfällige Vertragsverlängerungen unterliegen
              den AGB in der zum Verlängerungszeitpunkt gültigen Fassung.
            </Typography>
            <Typography variant="body1" mt={1.7}>
              Diese AGB sind anwendbar auf die Vertragsbeziehung zwischen
              VerkaufAlles und den privaten und gewerblichen Kunden.
            </Typography>
            <Typography variant="body1" mt={1.7}>
              Maßgeblich ist jeweils die zum Zeitpunkt des Vertragsabschlusses
              gültige Fassung der AGB. VerkaufAlles schließt nur zu diesen AGB
              ab. Sämtliche Rechte und Pflichten aus der Vertragsbeziehung
              zwischen den eben genannten Kunden unterliegen diesen AGB.
            </Typography>
            <Typography variant="body1" mt={1.7}>
              Die Richtlinien für Inserate gelten als Teil dieser AGB.
            </Typography>
            <Typography variant="body1" mt={1.7}>
              Mündliche Absprachen, Zusagen und Auskünfte insbesondere von
              VerkaufAlles Mitarbeitern/Verkäufern gelten ohne die schriftliche
              Bestätigung von VerkaufAlles nicht, außer dem stehen
              anderslautende zwingende gesetzliche Bestimmungen (z.B. KSchG)
              entgegen. Es gelten die Bestimmungen für Unternehmer (UGB, ABGB
              oder sonstige relevante Gesetzesbestimmungen) sofern nicht
              gesetzlich zwingend die Bestimmungen des Konsumentenschutzgesetzes
              zur Anwendung gelangen.
            </Typography>
            <Typography
              variant="h5"
              fontSize={35}
              fontWeight={800}
              mt={2.5}
              display="flex"
              textAlign="center"
              justifyContent="center"
            >
              Leistungsgegenstand
            </Typography>
            <Typography variant="h5" fontSize={25} fontWeight={600} mt={2.5}>
              <li>Allgemeines</li>
            </Typography>
            <Typography variant="body1" mt={1.7}>
              VerkaufAlles stellt seinen Kunden wie in RZ 1.1 beschrieben eine
              Ankündigungs- und Suchplattform zur Verfügung.
            </Typography>
            <Typography variant="body1" mt={1.7}>
              Die Nutzung von VerkaufAlles ist nur geschäftsfähigen, natürlichen
              Personen gestattet. Juristische Personen können nach den
              gesetzlichen Vorschriften ihrer Vertretungsbefugnis VerkaufAlles
              nutzen.
            </Typography>
            <Typography variant="body1" mt={1.7}>
              Die geposteten Inserate stellen keine verbindlichen Angebote von
              VerkaufAlles dar. VerkaufAlles ist kein Makler und kein
              Vermittler. Die auf Grund der Inserate abgeschlossenen Verträge
              kommen zwischen den Kunden von VerkaufAlles zustande.
            </Typography>
            <Typography variant="body1" mt={1.7}>
              Der Kunde muss sich zur Nutzung der Ankündigungs- und
              Suchplattform als Kunde registrieren. Durch die selbständige
              Registrierung wird zwischen dem Kunden und VerkaufAlles ein
              unbefristeter Vertrag abgeschlossen. Dieser Vertrag unterliegt den
              gegenständlichen AGBs.
            </Typography>
            <Typography variant="body1" mt={1.7}>
              Für die Registrierung auf VerkaufAlles fällt kein Entgelt an. Die
              Registrierung ist erforderlich für die kostenlose oder
              kostenpflichtige weitere Nutzung der Plattform. Die Schaltung von
              Anzeigen auf dem Ankündigungs- und Suchportal von VerkaufAlles
              setzt eine Registrierung voraus. Ein Anspruch auf Registrierung
              besteht nicht.
            </Typography>
            <Typography variant="h5" fontSize={25} fontWeight={600} mt={2.5}>
              <li>Inseratschaltung</li>
            </Typography>
            <Typography variant="body1" mt={1.7}>
              Der Kunde kann nach kostenloser Registrierung ein Inserat
              schalten. Zu diesem Zweck wird mit VerkaufAlles ein Vertrag
              abgeschlossen, welcher diesen AGB unterliegt. Der Kunde kann
              kostenpflichtige Optionen zur Inseratschaltung (Zusatzoptionen)
              wählen. Der Vertrag über die Zusatzoption kommt durch
              schriftliche, E-Mail oder Pop-Up Annahme, interaktives Auswählen
              des Produktes auf der Plattform, Annahme des bindenden Angebots
              oder durch tatsächliche Leistungserbringung zustande.
            </Typography>
            <Typography variant="body1" mt={1.7}>
              Der Kunde berücksichtigt die Richtlinien für Inserate, welche Teil
              dieser AGB sind und nimmt zur Kenntnis, dass Inserate die nicht
              diesen Richtlinien entsprechen nicht veröffentlicht bzw ersatzlos
              gelöscht werden.
            </Typography>
            <Typography variant="body1" mt={1.7}>
              VerkaufAlles kann muss aber keine Prüfung der Inseratschaltung auf
              Rechtskonformität und zur Anregung notwendig erscheinender
              Änderungen vornehmen. Rechtlich notwendige Eingriffe kann
              VerkaufAlles auch ohne vorherige Rücksprache mit dem Kunden
              vornehmen (z.B. Löschen von Verlinkungen zu Mitbewerbern,
              Kennzeichnung als „Werbung“, etc.).
            </Typography>
            <Typography variant="body1" mt={1.7}>
              VerkaufAlles hat auf Grund dessen das Recht das Inserat erst bis
              zu 48 Stunden (oder in besonderen Fällen bzw. bei besonderen
              Umständen auch darüber hinaus) nach der Erfassung zu
              veröffentlichen.
            </Typography>
            <Typography variant="body1" mt={1.7}>
              Die Inserate bleiben für die vereinbarte Laufzeit online. Nach
              Ablauf der vereinbarten Laufzeit werden die Inseratschaltungen
              automatisch deaktiviert. Ist eine zeitlich unbegrenzte
              Inseratschaltung vereinbart, bleibt das Inserat solange aktiv bis
              der Kunde dieses deaktiviert oder löscht - jedenfalls jedoch nur
              für maximal 12 Monate.
            </Typography>
            <Typography variant="body1" mt={1.7}>
              VerkaufAlles ermöglicht es die den Nutzern angezeigten
              Suchergebnisse zu sortieren anhand verschiedener Kriterien bzw.
              die Anzeige der Suchergebnisse einzuschränken. Mittels
              kostenpflichtiger Optionen können Inserate auch hervorgehoben
              werden.
            </Typography>
            <ol
              style={{
                listStyle: "disc",
                marginTop: "5px",
              }}
            >
              <li>
                Die Inseratschaltung für gewerbliche Kunden ist kostenpflichtig.
                Die vereinbarte Leistung wird durch VerkaufAlles nur
                durchgeführt bei Einhaltung der vereinbarten Zahlungstermine.
                Gerät der Kunde in Zahlungsverzug ist VerkaufAlles berechtigt
                ohne Setzung einer Nachfrist die Leistungserbringung zu
                unterbrechen oder zur Auflösung des Vertrages mit sofortiger
                Wirkung.
              </li>
              <li>
                Immobilienverkauf bzw.-vermittlung hat in jedem Fall
                entsprechend den gesetzlichen Bestimmungen zu erfolgen und ist
                ohne entsprechenden notwendigen Vermittlungsauftrag verboten.
              </li>
              <li>
                Pro E-Mail-Adresse kann nur ein Account registriert werden.
              </li>
            </ol>
            <Typography variant="h5" fontSize={25} fontWeight={600} mt={2.5}>
              <li>Rechte und Pflichten des Kunden</li>
            </Typography>
            <Typography variant="body1" mt={1.7}>
              Der Kunde verpflichtet sich, dass die von ihm angegebenen Daten
              richtig, vollständig, wahrheitsgemäß und den gesetzlichen
              Bestimmungen entsprechen.
            </Typography>
            <Typography variant="body1" mt={1.7}>
              Der Inserent ist verantwortlich für die korrekte Erfassung der
              Inserate, Fotos- und des sonstigen Materials. Diese haben den
              Richtlinien für Inserate zu entsprechen.
            </Typography>
            <Typography variant="body1" mt={1.7}>
              Der Kunde ist dazu verpflichtet allfällige Änderung des Inserats
              in Bezug auf inhaltliche, rechtliche, zeitliche oder sonstige
              Richtigkeit, Aktualität, etc zu überprüfen. Ab Aktivierung des
              Inserats bis zum Ablauf des vereinbarten Leistungszeitraums
              obliegt die Deaktivierung und Löschung der Inserate dem Kunden und
              ist verpflichtet nicht (mehr) aktuelle Inserate (z.B. Verkauf von
              angebotener Ware) sofort zu deaktivieren oder zu löschen.
            </Typography>
            <Typography variant="body1" mt={1.7}>
              Der Kunde ist verpflichtet Inserate in die richtige Rubrik, der
              richtigen Region zu schalten. Mehrfachschaltungen sind nicht
              erlaubt.
            </Typography>
            <Typography variant="body1" mt={1.7}>
              Die Nutzung der Inhalte der Inserate dürfen nur unter Einholung
              der vorherigen Zustimmung der Rechteinhaber kopiert, bearbeitet,
              verarbeitet, veröffentlicht oder öffentlich zur Verfügung gestellt
              werden oder in sonstiger Weise genutzt werden.
            </Typography>
            <Typography variant="body1" mt={1.7}>
              Angebotene gebrauchte Artikel müssen sich im physischen Besitz des
              Kunden befinden.
            </Typography>
            <Typography variant="body1" mt={1.7}>
              Die Kunden des VerkaufAlles Ankündigungs- und Suchportals
              verpflichten sich dazu die Nutzung des Ankündigungs- und
              Suchportals nur im Rahmen der vertraglichen oder gesetzlichen
              Bestimmung und ohne Verletzung von Rechten Dritter vorzunehmen und
              insbesondere die urheberrechtlichen, wettbewerbsrechtlichen,
              markenschutzrechtlichen und sonstigen zustehenden Rechte Dritter
              nicht ohne deren Zustimmung zu nutzen, zu bearbeiten, zu
              verfälschen, zu löschen oder unkenntlich zu machen.
            </Typography>
            <Typography variant="body1" mt={1.7}>
              Der Kunde verpflichtet sich VerkaufAlles in jedem Fall von
              etwaigen Ansprüchen Dritter, die in seinem Verantwortungsbereich
              gegründet sind, vollkommen schad- und klaglos zu halten, ua
              hinsichtlich der Rechtsanwalts- und Gerichtsverfahrenskosten falls
              VerkaufAlles wegen vom Kunden eingestellter und damit
              veröffentlichter Inhalte zivil- oder strafrechtlich, gerichtlich
              oder außergerichtlich, insbesondere durch Privatanklagen wegen
              Beleidigung, übler Nachrede, Kreditschädigung nach dem Strafgesetz
              oder durch Verfahren nach dem MedienG., dem UrheberrechtsG sowie
              sonstigen Immaterialgüterrechten, dem UWG (Gesetz gegen unlauteren
              Wettbewerb) oder wegen zivilrechtlicher Ehrenbeleidigung und/oder
              Kreditschädigung (§ 1330 ABGB) in Anspruch genommen wird. Kunden
              haften für Dritte wie für eigenes Verschulden.
            </Typography>
            <Typography variant="body1" mt={1.7}>
              Der Kunde hat dafür zu sorgen, seine Zugangsdaten (z.B.
              Benutzername, Passwort, etc.) geheim zu halten und vor unbefugtem
              Zugriff durch Dritte zu schützen. Ein Missbrauch bzw. unbefugte
              Nutzung ist VerkaufAlles sofort schriftlich zu melden. Bis dahin
              wird dem Kunden jeder Zugriff und jede mit seinem Benutzerkonto
              zusammenhängende Handlung oder Inanspruchnahme von Leistungen
              zugerechnet.
            </Typography>
            <Typography variant="body1" mt={1.7}>
              Kunden sind dafür verantwortlich, Daten, die sie benötigen (z.B.
              für Auftragsabwicklung, Beweissicherung, Archivierung, etc.)
              mittels unabhängigem/externem Speichermedium geeignet zu sichern
              und aufzubewahren.
            </Typography>
            <Typography variant="body1" mt={1.7}>
              Alle Rechte und Pflichten des Ankündigungs- und Suchportals von
              VerkaufAlles gelten auch für Erfüllungsgehilfen (sonstige Dritte)
              und Mitarbeiter des Kunden denen dieser Dienst berechtigterweise
              zur Nutzung überlassen wurde.
            </Typography>
            <Typography variant="h5" fontSize={25} fontWeight={600} mt={2.5}>
              <li>Besondere Bestimmungen für gewerbliche Kunden:</li>
            </Typography>
            <Typography variant="body1" mt={1.7}>
              Unternehmer, die auf Grund ihres Inserates auf VerkaufAlles als
              Unternehmer mit Verbrauchern Verträge abschließen haben vor Abgabe
              der Vertragserklärung des Verbrauchers die einschlägigen
              Verbraucherschutzbestimmungen (insb. des FAGG und KschG)
              insbesondere betreffend das Rücktrittsrecht einzuhalten, weiters
              sind die Informationspflichten nach § 6 Abs 1 ECG bzw. § 63
              Gewerbeordnung einzuhalten.
            </Typography>
            <Typography variant="body1" mt={1.7}>
              Der Kunde ist verpflichtet eine Änderung von Daten (z.B.
              Firmenname, Anschrift) VerkaufAlles unverzüglich, spätestens aber
              14 Tage nach Änderung, schriftlich zu melden.
            </Typography>
            <Typography variant="h5" fontSize={25} fontWeight={600} mt={2.5}>
              <li>Rechte und Pflichten von VerkaufAlles</li>
            </Typography>
            <Typography variant="body1" mt={1.7}>
              VerkaufAlles erbringt die Leistungen ordnungsgemäß, so dass die
              Verfügbarkeit der Leistungen nach Maßgabe des Vertrages
              sichergestellt wird, nach den Grundsätzen ordnungsgemäßer
              Berufsausübung, nach dem aktuellen Stand der Technik und nach
              anerkannten Technik- und Qualitätsstandards zum Zeitpunkt des
              Vertragsschlusses.
            </Typography>
            <Typography variant="body1" mt={1.7}>
              VerkaufAlles ist berechtigt seine Leistungen jederzeit zu
              erweitern bzw. abzuändern und ist berechtigt, Werbung auf{" "}
              <Link
                href="https://verkaufweb.semseosmo.com/"
                target="_blank"
                rel="noopener"
              >
                https://verkaufweb.semseosmo.com/
              </Link>{" "}
              zu schalten. VerkaufAlles kann, muss aber nicht, die vom Kunden
              übermittelten Unterlagen (z.B. Fotos, Videos, etc.) für die
              Veröffentlichung geeignet zu optimieren bzw. aus
              Sicherheitsgründen zu verändern. In diesem Fall ist VerkaufAlles
              berechtigt, seinen Firmenwortlaut und/oder Logos in angemessener
              Größe und Platzierung am Inserat oder Multimediaobjekt
              anzubringen.
            </Typography>
            <Typography variant="body1" mt={1.7}>
              VerkaufAlles behält sich das Recht vor Mehrfachschaltungen zu
              löschen und Inserate unter der richtigen Rubrik zu veröffentlichen
              ohne vorherige Benachrichtigung des Kunden und das Inserat in der
              falschen Rubrik ohne gleichzeitige Korrektur durch
              Veröffentlichung in der richtigen Rubrik zu löschen.
            </Typography>
            <Typography variant="body1" mt={1.7}>
              VerkaufAlles ist berechtigt, alle Daten inklusive Fotos nach
              Ablauf bzw Deaktivierung, etc. des Inserats zu löschen.
            </Typography>
            <Typography variant="body1" mt={1.7}>
              Grundsätzlich erfolgt die Veröffentlichung der Inserate auf{" "}
              <Link
                href="https://verkaufweb.semseosmo.com/"
                target="_blank"
                rel="noopener"
              >
                https://verkaufweb.semseosmo.com/
              </Link>{" "}
              bzw der App VerkaufAlles. VerkaufAlles kann, muss jedoch nicht das
              vertragsgegenständliche Inserat auch in verbundenen Partnermedien
              und/oder anderen Medien (z.B. als Online-/Printinserat, auf
              elektronischem oder digitalem Weg, E-Mail, Telefon, SMS/MMS, etc.)
              veröffentlichen ohne dass dem Kunden dadurch Kosten entstehen.
              Dies kann zu Qualitätsabweichungen des Inserates führen.
            </Typography>
            <Typography variant="body1" mt={1.7}>
              VerkaufAlles ist jederzeit berechtigt, vom Kunden geeignete
              Nachweise zu den bekannt gegebenen Informationen zu fordern
              (Gewerbeberechtigung, Vollmacht, Lichtbildausweis
              Firmenbuchauszug, Bescheide wie zB Züchterschein oder Bestätigung
              über den Betrieb einer Landwirtschaft, Auszug aus dem
              Vereinsregister, etc.).
            </Typography>
            <Typography variant="body1" mt={1.7}>
              VerkaufAlles ist berechtigt, Kunden Erklärungen rechtswirksam an
              die zuletzt bekannt gegebene Anschrift zu senden.
            </Typography>
            <Typography variant="body1" mt={1.7}>
              VerkaufAlles behält sich vor, den Vertrag abzulehnen, aufzulösen,
              den Kunden zu sperren bzw. eingestellte Inserate ersatzlos zu
              löschen ohne jeglichen Ersatzanspruch des Kunden insbesondere aus
              nachstehenden Gründen
            </Typography>
            <ol
              style={{
                listStyle: "disc",
                marginTop: "5px",
              }}
            >
              <li>
                bei Verstoß gegen diese AGB inkl. Anzeigenrichtlinien und
                Nutzungsbedingungen, die guten Sitten, das Ansehen, die
                Interessen von VerkaufAlles
              </li>
              <li>
                die Funktionalität der Webseite durch sein Verhalten
                beeinträchtigt
              </li>
              <li>
                bei unvollständigen oder unrichtigen Angaben bei der Bestellung
                oder Nichtbeibringung geforderter Nachweise oder Unterlagen
              </li>
              <li>
                bei fehlender behördlicher Genehmigung (z.B.
                Gewerbeberechtigung), fehlender Rechts-, oder Geschäftsfähigkeit
              </li>
              <li>
                bei beanstandeten Inseraten durch den Österreichischen Werberat
              </li>
              <li>bei Fremdwerbung oder Verlinkung zu anderen Webseiten</li>
              <li>
                bei begründetem Verdacht auf missbräuchliche Verwendung des
                Ankündigungs- und Suchportales oder der Leistungen von
                VerkaufAlles
              </li>
              <li>
                bei Verstoß gegen gesetzliche Bestimmungen, wesentliche
                Vertragsbestimmungen, die guten Sitten, das Ansehen oder
                berechtigte Interessen von VerkaufAlles oder einem
                Partnerunternehmen
              </li>
              <li>
                bei Zahlungsverzug gegenüber VerkaufAlles oder einem
                Partnerunternehmen
              </li>
              <li>bei bewusstem Übergehen der VerkaufAlles Gebührenstruktur</li>
              <li>
                bei Beendigung des Vertragsverhältnisses zwischen dem Kunden und
                VerkaufAlles oder dem Kunden und einem Partnerunternehmen wegen
                Verletzung von wesentlichen Vertragsbestimmungen durch den
                Kunden
              </li>
            </ol>
            <Typography variant="body1" mt={1.7}>
              Sollte es zu einer Auflösung des Vertrags kommen, entfallen
              etwaige gewährte Vergünstigungen auf den Tarif/Preis, wie
              beispielsweise Rabatte für gewerbliche Kunden. In einem solchen
              Fall ist VerkaufAlles berechtigt, den Tarif gemäß der Preisliste
              zu verlangen. Der Kunde ist dann verpflichtet, eventuelle
              Differenzen zwischen dem vereinbarten Preis und dem Preis gemäß
              Tarif seit Vertragsbeginn umgehend nachzuzahlen.
            </Typography>
            <Typography variant="body1" mt={1.7}>
              Bei Nichteinloggen des Kunden über einen Zeitraum von 90 Tagen
              behält sich VerkaufAlles das Recht vor, den Account ohne Ansprüche
              des Kunden zu deaktivieren.
            </Typography>
            <Typography variant="body1" mt={1.7}>
              Bedingungen für Displaywerbung (nur für gewerbliche Kunden)
            </Typography>
            <Typography variant="body1" mt={1.7}>
              Alle erforderlichen Informationen, Dokumente, Unterlagen und
              Dateien für die Schaltung der jeweiligen Werbung (einschließlich
              Grafiken, Rich Media Banner, Texte, Links und andere Elemente)
              müssen spätestens 5 Werktage vor dem festgelegten
              Erstschaltungstermin auf der Website vollständig, fehlerfrei
              übermittelt werden.
            </Typography>
            <Typography variant="body1" mt={1.7}>
              VerkaufAlles behält sich das Recht vor, übermittelte Werbemedien
              auf ihre Darstellungsqualität und technische Eignung (insbesondere
              passendes Format, Darstellungstechnologie und Dateigröße) zu
              prüfen. Falls erforderlich, können Anpassungen in Absprache mit
              dem Kunden vorgenommen werden, wobei die Kosten dafür dem Kunden
              in Rechnung gestellt werden. VerkaufAlles behält sich das Recht
              vor, Werbematerial ohne Angabe von Gründen abzulehnen, wenn es
              nicht mit dem Konzept übereinstimmt.
            </Typography>
            <Typography variant="body1" mt={1.7}>
              VerkaufAlles behält sich das Recht vor, übermittelte Werbemedien
              auf ihre Darstellungsqualität und technische Eignung (insbesondere
              passendes Format, Darstellungstechnologie und Dateigröße) zu
              prüfen. Falls erforderlich, können Anpassungen in Absprache mit
              dem Kunden vorgenommen werden, wobei die Kosten dafür dem Kunden
              in Rechnung gestellt werden. VerkaufAlles behält sich das Recht
              vor, Werbematerial ohne Angabe von Gründen abzulehnen, wenn es
              nicht mit dem Konzept übereinstimmt.
            </Typography>
            <Typography variant="body1" mt={1.7}>
              Falls VerkaufAlles das vereinbarte Leistungsvolumen nicht
              innerhalb des vereinbarten Zeitraums erfüllen kann, ist
              VerkaufAlles berechtigt und verpflichtet, das noch ausstehende
              Leistungsvolumen unverzüglich nachzuliefern, entweder im Anschluss
              an den betreffenden Auftrag oder an einen neuen, bereits vom
              Kunden gebuchten Auftrag. In solchen Fällen besteht kein Anspruch
              auf Rückerstattung bereits geleisteter Zahlungen.
            </Typography>
            <Typography variant="body1" mt={1.7}>
              VerkaufAlles ist von jeglichen Ansprüchen freigestellt, die sich
              aus der Nichterfüllung der technischen Voraussetzungen durch den
              Kunden oder das übermittelte Werbematerial ergeben.
            </Typography>
            <Typography variant="body1" mt={1.7}>
              Wenn eine fehlerfreie Abwicklung nicht gewährleistet werden kann,
              ist VerkaufAlles unabhängig von einem eventuellen Schaden
              berechtigt, das Material unverzüglich von der Seite zu entfernen,
              ohne für etwaige Haftungsansprüche verantwortlich zu sein.
            </Typography>
            <Typography variant="body1" mt={1.7}>
              Die Datenübermittlung erfolgt elektronisch per E-Mail-Anhang und
              muss den Anforderungen des E-Commerce-Gesetzes (ECG) zur
              Kennzeichnung kommerzieller Kommunikation sowie gegebenenfalls den
              einschlägigen Vorschriften des Fernabsatzgesetzes und des
              Mediengesetzes entsprechen.
            </Typography>
            <Typography variant="body1" mt={1.7}>
              Die übermittelten Daten müssen insbesondere eine rechtskonforme
              Kennzeichnung der Auftraggeber kommerzieller Kommunikation
              ermöglichen.
            </Typography>
            <Typography variant="body1" mt={1.7}>
              VerkaufAlles garantiert nicht die Aufteilung der Ad-Impressions
              während der Werbekampagne. VerkaufAlles behält sich das Recht vor,
              Werbeschaltungen und Links aus verschiedenen Gründen (z. B.
              redaktionelle, rechtliche, technische oder moralische Gründe)
              abzulehnen oder nachträglich zu sperren, wobei der Kunde
              informiert wird, jedoch keine vorherige Absprache erforderlich
              ist.
            </Typography>
            <Typography variant="h5" fontSize={25} fontWeight={600} mt={2.5}>
              <li>Besondere Bestimmungen für VerkaufAlles Immobilien</li>
            </Typography>
            <Typography variant="body1" mt={1.7}>
              VerkaufAlles bietet eine digitale Plattform an, auf der Kunden
              Immobilieninserate schalten können. Die Inhalte dieser Inserate
              werden von den jeweiligen Kunden bestimmt und sind ihre alleinige
              Verantwortung. VerkaufAlles agiert hierbei nicht als
              Immobilienmakler im Sinne des Maklergesetzes in Verbindung mit der
              Immobilienmaklerverordnung, sondern lediglich als Anbieter einer
              digitalen Plattform. Daher überprüft VerkaufAlles die Angaben der
              Kunden in den geschalteten Immobilieninseraten nicht auf ihre
              Richtigkeit, Vollständigkeit oder Wahrhaftigkeit. Der Inhalt
              dieser Inserate liegt ausschließlich in der Verantwortung des
              jeweiligen Kunden.
            </Typography>
            <Typography variant="h5" fontSize={25} fontWeight={600} mt={2.5}>
              <li>Support</li>
            </Typography>
            <Typography variant="body1" mt={1.7}>
              VerkaufAlles bietet für gewerbliche und private Kunden Support per
              E-Mail an.
            </Typography>
            <Typography variant="body1" mt={1.7}>
              VerkaufAlles kann keine Gewähr für die Erreichbarkeit des Supports
              übernehmen.
            </Typography>
            <Typography variant="body1" mt={1.7}>
              Mündlich erteilte Auskünfte sind nicht verbindlich. VerkaufAlles
              haftet in diesem Fall nicht.
            </Typography>
            <Typography variant="body1" mt={1.7}>
              Die Durchführung der beauftragten Leistungen durch VerkaufAlles
              erfolgt in der durch VerkaufAlles gewählten Weise und innerhalb
              der normalen Arbeitszeit.
            </Typography>
            <Typography variant="body1" mt={1.7}>
              Im Rahmen des internen Beschwerdemanagementsystems eingereichte
              Beschwerden werden sorgfältig geprüft, nach einer der Komplexität
              und Bedeutung des Problems angemessenen Bearbeitungszeit wird der
              gewerbliche Nutzer über das Ergebnis des internen
              Beschwerdeverfahrens informiert.
            </Typography>
            <Typography
              variant="h5"
              fontSize={35}
              fontWeight={800}
              mt={2.5}
              display="flex"
              textAlign="center"
              justifyContent="center"
            >
              Zahlungspflichten
            </Typography>
            <Typography variant="h5" fontSize={25} fontWeight={600} mt={2.5}>
              <li>Allgemeines</li>
            </Typography>
            <Typography variant="body1" mt={1.7}>
              Es gelten die Entgelte, die in der Preisliste unter{" "}
              <Link
                href="https://verkaufweb.semseosmo.com/"
                target="_blank"
                rel="noopener"
              >
                https://verkaufweb.semseosmo.com/
              </Link>{" "}
              enthalten sind. Kunden tragen Bankspesen, Manipulationsgebühren
              und alle mit ihrer Zahlung (z.B. Überweisung) verbundenen Kosten
              und Spesen selber. Vom Verbraucher zu bezahlende Beträge/Preise
              verstehen sich als Bruttopreise, dh inklusive Umsatzsteuer,
              Abgaben und sonstiger Zuschläge.
            </Typography>
            <Typography variant="body1" mt={1.7}>
              Die Bezahlung muss grundsätzlich vor Aktivierung der
              Dienstleistung erfolgen und kann mit den von VerkaufAlles
              angegebenen Zahlungsmethoden erfolgen, darunter Kreditkarte,
              Online-Banking oder über Gutscheine, Prepaid-Guthaben usw.
            </Typography>
            <Typography variant="body1" mt={1.7}>
              VerkaufAlles behält sich das Recht vor, eine Gesamtrechnung für
              alle von VerkaufAlles erbrachten Leistungen auszustellen,
              unabhängig davon, ob diese Leistungen aus verschiedenen Verträgen
              resultieren.
            </Typography>
            <Typography variant="body1" mt={1.7}>
              Rechnungen müssen innerhalb von 14 Tagen nach Erhalt beanstandet
              werden, andernfalls gelten sie als vom Kunden akzeptiert.
            </Typography>
            <Typography variant="body1" mt={1.7}>
              Bei Einreichung von Zahlungsbelegen muss der gewerbliche Kunde
              seine Kundennummer angeben, während der private Kunde seinen
              Benutzernamen (E-Mailadresse) verwenden muss. Sollte eine
              Recherche zur Zuordnung der Zahlung erforderlich sein, werden die
              entstandenen Kosten dem Kunden in Rechnung gestellt. Die
              schuldbefreiende Wirkung der Zahlung tritt erst mit der korrekten
              Zuordnung ein.
            </Typography>
            <Typography variant="body1" mt={1.7}>
              Beim Bezahlen per Kreditkarte oder Bankeinzug ist der Kunde dafür
              verantwortlich sicherzustellen, dass die Kreditkarte bzw. das
              Konto nicht gesperrt oder abgelaufen ist. Andernfalls trägt der
              Kunde die Verantwortung für entstehende Verzögerungen bei der
              Bezahlung, und es können Verzugszinsen erhoben werden.
            </Typography>
            <Typography variant="body1" mt={1.7}>
              Wenn die Bezahlung über einen Payment Provider auf der Plattform
              abgewickelt wird, übernimmt VerkaufAlles keine Haftung für die
              Sicherheit der Daten oder für Fehler seitens des Payment
              Providers, die zu Fehlbuchungen führen könnten.
            </Typography>
            <Typography variant="body1" mt={1.7}>
              VerkaufAlles übernimmt keine Verantwortung für Missbrauch,
              Diebstahl, Verlust oder ähnliche Vorfälle bezüglich der
              Zugangsdaten, des Guthabens oder anderer mit dem Guthaben-Konto
              verbundener Daten und Informationen, sofern diese nicht von
              VerkaufAlles zu vertreten sind.
            </Typography>
            <Typography variant="body1" mt={1.7}>
              Wenn der Inserent die vereinbarten Anlieferungsfristen nicht
              einhält und dadurch das Inserat nicht oder nicht rechtzeitig
              veröffentlicht werden kann, bleibt der Anspruch von VerkaufAlles
              auf Zahlung der vereinbarten Vergütung bestehen. Dies gilt auch,
              wenn VerkaufAlles den Inserenten berechtigterweise sperrt oder das
              Inserat aufgrund von vom Inserenten zu vertretenden Gründen
              löscht.
            </Typography>
            <Typography variant="h5" fontSize={25} fontWeight={600} mt={2.5}>
              <li>Besondere Bestimmungen für gewerbliche Kunden:</li>
            </Typography>
            <Typography variant="body1" mt={1.7}>
              Die Preise verstehen sich exklusive der gesetzlichen Umsatzsteuer
              sowie aller sonstigen jeweils gültigen Gebühren, Steuern, Abgaben
              (z.B. 5% Werbeabgabe).
            </Typography>
            <Typography variant="body1" mt={1.7}>
              Werden nachträglich Steuern oder sonstige Abgaben eingeführt oder
              vorgeschrieben, gehen diese zu Lasten des Kunden.
            </Typography>
            <Typography
              variant="h5"
              fontSize={35}
              fontWeight={800}
              mt={2.5}
              display="flex"
              textAlign="center"
              justifyContent="center"
            >
              Rücktritt und Kündigung
            </Typography>
            <Typography variant="h5" fontSize={25} fontWeight={600} mt={2.5}>
              <li>Allgemeines</li>
            </Typography>
            <Typography variant="body1" mt={1.7}>
              Die Beendigung des Vertragsverhältnisses durch VerkaufAlles hat
              schriftlich zu erfolgen. Eine Übermittlung via E-Mail ist
              ausreichend und gilt mit Zustellbestätigung des Servers bzw.
              Mailservices des Kunden als zugegangen.
            </Typography>
            <Typography variant="h5" fontSize={25} fontWeight={600} mt={2.5}>
              <li>
                Rücktrittsrecht des Verbrauchers gemäß § 3 KschG bei
                entgeltlichen Verträgen
              </li>
            </Typography>
            <Typography variant="body1" mt={1.7}>
              Wenn der Verbraucher seine Vertragserklärung weder in den
              dauerhaft vom Unternehmer genutzten Geschäftsräumen noch an einem
              Stand des Unternehmers auf einer Messe oder einem Markt abgegeben
              hat, hat er das Recht, von seinem Vertragsantrag oder vom Vertrag
              zurückzutreten. Dieser Rücktritt kann bis zum Zustandekommen des
              Vertrags oder danach innerhalb von 14 Tagen erklärt werden. Die
              Frist beginnt mit der Ausfolgung einer Urkunde, die zumindest den
              Namen und die Anschrift des Unternehmers, die zur Identifizierung
              des Vertrags notwendigen Angaben sowie eine Belehrung über das
              Rücktrittsrecht, die Rücktrittsfrist und die Vorgehensweise für
              die Ausübung des Rücktrittsrechts enthält, an den Verbraucher,
              frühestens jedoch mit dem Zustandekommen des Vertrags. Bei
              Kaufverträgen über Waren beginnt die Frist am Tag, an dem der
              Verbraucher den Besitz an der Ware erlangt. Falls eine solche
              Urkunde nicht ausgehändigt wurde, steht dem Verbraucher das
              Rücktrittsrecht für eine Frist von zwölf Monaten und 14 Tagen nach
              Vertragsabschluss oder Warenlieferung zu. Wenn der Unternehmer die
              Urkunde innerhalb von zwölf Monaten nach Fristbeginn nachreicht,
              endet die verlängerte Rücktrittsfrist 14 Tage nach Erhalt der
              Urkunde durch den Verbraucher.
            </Typography>
            <Typography variant="body1" mt={1.7}>
              Das Rücktrittsrecht steht dem Verbraucher nicht zu:
            </Typography>
            <ol
              style={{
                listStyle: "disc",
                marginTop: "5px",
              }}
            >
              <li>
                wenn er selbst die geschäftliche Verbindung mit dem Unternehmer
                oder dessen Beauftragten zwecks Schließung dieses Vertrages
                angebahnt hat
              </li>
              <li>
                wenn dem Zustandekommen des Vertrags keine Besprechung zwischen
                den Beteiligten oder ihren Beauftragten vorangegangen ist
              </li>
              <li>
                bei Verträgen, bei denen die beiderseitigen Leistungen sofort zu
                erbringen sind, sofern sie üblicherweise von Unternehmern
                außerhalb ihrer Geschäftsräume geschlossen werden und das
                vereinbarte Entgelt 25 Euro nicht übersteigt, oder wenn das
                Unternehmen nach seiner Natur nicht in ständigen Geschäftsräumen
                betrieben wird und das Entgelt 50 Euro nicht übersteigt
              </li>
              <li>
                bei Verträgen, die dem Fern- und Auswärtsgeschäfte-Gesetz oder
                dem Versicherungsvertragsgesetz unterliegen
              </li>
            </ol>
            <Typography variant="body1" mt={1.7}>
              Die Erklärung des Rücktritts ist an keine bestimmte Form gebunden.
              Die Rücktrittsfrist ist gewahrt, wenn die Rücktrittserklärung
              innerhalb der Frist abgesendet wird.
            </Typography>
            <Typography variant="h5" fontSize={25} fontWeight={600} mt={2.5}>
              <li>Widerrufsrecht gem. FAGG</li>
            </Typography>
            <Typography variant="body1" mt={1.7}>
              Der Kunde hat das Recht, binnen vierzehn Tagen ohne Angabe von
              Gründen diesen Vertrag zu widerrufen. Die Widerrufsfrist beträgt
              vierzehn Tage ab dem Tag des Vertragsschlusses.
            </Typography>
            <Typography variant="body1" mt={1.7}>
              Zur Ausübung des Widerrufsrechtes muss der Kunde VerkaufAlles eine
              eindeutige Erklärung mit der Post oder E-Mail zukommen lassen. Es
              kann dafür ein Muster-Widerrufsformular verwendet werden.
            </Typography>
            <Typography variant="body1" mt={1.7}>
              Zur Wahrung der Widerrufsfrist reicht es aus, dass die Mitteilung
              über die Ausübung des Widerrufsrechts vor Ablauf der
              Widerrufsfrist abgesendet wird.
            </Typography>
            <Typography variant="body1" mt={1.7}>
              Wurde der Vertrag vom Kunden widerrufen, hat VerkaufAlles alle
              Zahlungen, die bisher erhalten wurden, einschließlich der
              Lieferkosten (außer wenn der Kunde eine andere Art der Lieferung
              gewählt hat), unverzüglich und spätestens binnen vierzehn Tagen ab
              dem Tag zurückzuzahlen, an dem die Mitteilung über den Widerruf
              dieses Vertrags bei VerkaufAlles eingegangen ist.
            </Typography>
            <Typography variant="body1" mt={1.7}>
              Wurde vor Ablauf der Frist mit der Erbringung der Dienstleistungen
              begonnen, so hat der Kunde ein angemessenes Nutzungsentgelt zu
              bezahlen.
            </Typography>
            <Typography variant="body1" mt={1.7}>
              Wurde die geschuldete Dienstleistung bereits vollständig erbracht
              und mit der Ausführung der Dienstleistung erst begonnen, nachdem
              der Kunde dazu seine ausdrückliche Zustimmung gegeben hat und
              gleichzeitig der Kunde seine Kenntnis davon bestätigt hat, dass er
              sein Widerrufsrecht bei vollständiger Vertragserfüllung verliert.
              Die Dienstleistung von VerkaufAlles gilt jedenfalls mit dem
              Zeitpunkt der Freischaltung als vollständig erfüllt.
            </Typography>
            <Typography variant="body1" mt={1.7}>
              Eine vor Vertragsablauf erfolgte Deaktivierung des Inserates durch
              den Kunden stellt keinen Rücktritt im Sinne des KSchG oder FAGG
              dar. Die Zahlungspflicht des Kunden bleibt in diesem Fall
              bestehen.
            </Typography>
            <Typography variant="h5" fontSize={25} fontWeight={600} mt={2.5}>
              <li>
                Kündigung des Vertrags durch private und gewerbliche Kunden
              </li>
            </Typography>
            <Typography variant="body1" mt={1.7}>
              Der Kunde kann jederzeit durch Löschen des Accounts den diesen AGB
              unterliegenden Vertrag beenden. In diesem Fall hat der Kunde nach
              erfolgter Beendigung keinen Zugang zu den von ihm bereitgestellten
              oder generierten Informationen. Gesetzliche (insb.
              datenschutzrechtliche) Rechte des Nutzers bleiben davon unberührt.
            </Typography>
            <Typography variant="body1" mt={1.7}>
              VerkaufAlles ist berechtigt, den Vertrag jederzeit aus wichtigem
              Grund aufzulösen (außerordentliche Kündigung).
            </Typography>
            <Typography variant="h5" fontSize={25} fontWeight={600} mt={2.5}>
              <li>Kündigung des Paket-/Bannervertrags:</li>
            </Typography>
            <Typography variant="body1" mt={1.7}>
              Verträge, die diesen AGB unterliegen, können aus einem der
              folgenden Gründe heraus beendet werden:
            </Typography>
            <ol
              style={{
                listStyle: "disc",
                marginTop: "5px",
              }}
            >
              <li>Die vereinbarte Vertragslaufzeit ist abgelaufen.</li>
              <li>
                Falls eine Kündigungsmöglichkeit vereinbart wurde, muss diese
                spätestens 30 Tage vor dem Hauptfälligkeitstermin des Vertrags
                erfolgen.
              </li>
              <li>Ein vereinbartes Kontingent wurde aufgebraucht.</li>
              <li>VerkaufAlles stellt die Leistung ein.</li>
            </ol>
            <Typography variant="body1" mt={1.7}>
              VerkaufAlles behält sich das Recht vor, den Vertrag jederzeit aus
              wichtigem Grund außerordentlich zu kündigen.
            </Typography>
            <Typography variant="body1" mt={1.7}>
              Eine ordentliche oder außerordentliche Kündigung durch den Kunden
              muss schriftlich erfolgen; eine E-Mail reicht aus. Eine solche
              Kündigung per E-Mail wird jedoch erst nach Erhalt einer
              schriftlichen Kündigungsbestätigung durch VerkaufAlles als wirksam
              betrachtet.
            </Typography>
            <Typography variant="body1" mt={1.7}>
              Nach Vertragsende hat der Nutzer keinen Zugriff mehr auf die von
              ihm bereitgestellten oder generierten Informationen. Die
              gesetzlichen (insbesondere datenschutzrechtlichen) Rechte des
              Nutzers bleiben unberührt.
            </Typography>
            <Typography variant="h5" fontSize={25} fontWeight={600} mt={2.5}>
              <li>Sicherheit</li>
            </Typography>
            <Typography variant="body1" mt={1.7}>
              Aus Sicherheitsgründen sollte der Kunde nur nachdem er selbst die
              URL:{" "}
              <Link
                href="https://verkaufweb.semseosmo.com/"
                target="_blank"
                rel="noopener"
              >
                https://verkaufweb.semseosmo.com/
              </Link>{" "}
              in der Adresszeile des Browsers eingegeben und damit auf die
              VerkaufAlles Seite zugegriffen hat seinen Benutzernamen/Passwort
              eingeben. Der Kunde ist selbst dafür verantwortlich, sofern er
              Zugangsdaten über gefälschte Seiten weitergegeben hat.
            </Typography>
            <Typography
              variant="h5"
              fontSize={35}
              fontWeight={800}
              mt={2.5}
              display="flex"
              textAlign="center"
              justifyContent="center"
            >
              Allgemeine Vertragsbestimmungen
            </Typography>
            <Typography variant="h5" fontSize={25} fontWeight={600} mt={2.5}>
              <li>Urheberrecht</li>
            </Typography>
            <Typography variant="body1" mt={1.7}>
              Sämtliche Urheber- und Leistungsschutzrechte am Gesamtbild wie
              auch an Teilen der von VerkaufAlles erstellten Inseraten sowie an
              den von Dritten eingestellten Inhalten auf{" "}
              <Link
                href="https://verkaufweb.semseosmo.com/"
                target="_blank"
                rel="noopener"
              >
                https://verkaufweb.semseosmo.com/
              </Link>{" "}
              , einschließlich Texte, Marken, Fotos, Datenbanken und Layout,
              stehen ausschließlich VerkaufAlles oder sonstigen Dritten zu,
              sofern nicht ausdrücklich etwas anderes schriftlich vereinbart
              wurde. Durch die Zahlung des Entgelts gehen auf den Kunden keine
              Rechte an den Inseraten über.
            </Typography>
            <Typography variant="body1" mt={1.7}>
              Sofern nicht anders schriftlich vereinbart, ist es dem Kunden
              nicht gestattet, von VerkaufAlles erstellte Inserate außerhalb der
              Webseiten von VerkaufAlles zu verwenden oder das Layout der
              Webseiten von VerkaufAlles oder eines Inserats zu verändern.
            </Typography>
            <Typography variant="body1" mt={1.7}>
              Durch die Einstellung oder Einspeisung des Inserats räumt der
              Inserent selbst oder im Namen des Rechtsinhabers VerkaufAlles das
              ausschließliche und übertragbare Nutzungsrecht ein, diese Inhalte
              zeitlich und räumlich unbegrenzt im Rahmen von VerkaufAlles und
              dessen Partner zu verbreiten, zu ändern, zu bearbeiten, zu
              veröffentlichen oder öffentlich zur Verfügung zu stellen. Diese
              Rechtseinräumung umfasst auch das Recht für VerkaufAlles, die
              Inserate zu Eigenwerbezwecken zu nutzen. VerkaufAlles ist
              berechtigt, jedoch nicht verpflichtet, rechtswidrige Eingriffe in
              die übertragenen Nutzungsrechte im eigenen Namen abzuwehren oder
              daraus resultierende Ansprüche gerichtlich geltend zu machen. Der
              Inserent wird VerkaufAlles im Falle der Abwehr von Ansprüchen
              bestmöglich unterstützen und jegliche Ansprüche Dritter
              unverzüglich und schriftlich VerkaufAlles mitteilen."
            </Typography>
            <Typography variant="h5" fontSize={25} fontWeight={600} mt={2.5}>
              <li>Gewährleistung</li>
            </Typography>
            <Typography variant="body1" mt={1.7}>
              Für abweichende Druckqualität sowie Übermittlungsfehler wird von
              VerkaufAlles keine Haftung übernommen.
            </Typography>
            <Typography variant="body1" mt={1.7}>
              VerkaufApp sichert nicht zu, dass Inserate zu einem bestimmten
              Zeitpunkt online gehen und/oder deaktiviert/gelöscht werden.
            </Typography>
            <Typography variant="body1" mt={1.7}>
              Es wird keine Gewähr geleistet, keine Garantie übernommen und
              keine Haftung übernommen für die in Inseraten angebotenen Waren,
              Dienstleistungen.
            </Typography>
            <Typography variant="body1" mt={1.7}>
              Die Gewährleistung von VerkaufAlles gegenüber Kunden erstreckt
              sich nur auf die dem derzeitig üblichen technischen Standard
              entsprechende Wiedergabe des entgeltlichen Inserats sowie auf die
              entsprechende Verfügbarkeit von Daten. Unentgeltliche Inserate
              bzw. Serviceleistungen (Suchagent, Merklisten, Zustellung von
              Anfragen) berechtigen nicht zur Geltendmachung von Ansprüchen. Für
              Fehler, die den Sinn des Inserats nicht wesentlich
              beeinträchtigen, wird keine Haftung übernommen bzw. kein Ersatz
              geleistet.
            </Typography>
            <Typography variant="body1" mt={1.7}>
              Die von VerkaufAlles automatisiert oder manuell erstellten oder
              geänderten Inserate stellen immer nur einen Entwurf dar, der vom
              Auftraggeber nach eingehender sachlicher und rechtlicher Prüfung
              freigegeben werden muss.
            </Typography>
            <Typography variant="body1" mt={1.7}>
              Reklamationen: Beanstandungen aller Art des Inserats sind – sofern
              keine Selbsteingabe/Änderung erfolgte – unverzüglich längstens
              innerhalb von 2 Werktagen nach Erscheinen/Änderung des Inserats
              vom Inserenten VerkaufAlles schriftlich und unter Anführung des
              Reklamationsgrundes mitzuteilen. Danach gilt das Inserat als
              mängelfrei anerkannt.
            </Typography>
            <Typography variant="body1" mt={1.7}>
              Bei Mangel eines entgeltlichen Inserats, den VerkaufAlles zu
              vertreten hat, hat der Inserent nach Wahl von VerkaufAlles
              Anspruch auf eine neue Erstanzeige im Umfang der dem Mangel
              zugrunde liegenden Anzeige. Schlägt eine Verbesserung innerhalb
              der angemessenen Frist fehl, so kann der Inserent erst nach
              weiterer angemessener Fristsetzung und Verbesserungsversuch eine
              Preisminderung verlangen. Darüber hinaus stehen dem Inserenten –
              außer es ist gesetzlich zwingend etwas anderes geboten - keine
              Ansprüche zu.
            </Typography>
            <Typography variant="body1" mt={1.7}>
              VerkaufAlles kann keine wie immer geartete Gewähr für die
              Richtigkeit von telefonisch aufgegebenen Inseraten oder Änderungen
              übernehmen.
            </Typography>
            <Typography variant="h5" fontSize={25} fontWeight={600} mt={2.5}>
              <li>Haftung</li>
            </Typography>
            <Typography variant="body1" mt={1.7}>
              Mit Eingabe des Inserats bestätigt der Kunde ausdrücklich die
              Veröffentlichung in dieser Form; VerkaufAlles übernimmt keinerlei
              Haftung.
            </Typography>
            <Typography variant="body1" mt={1.7}>
              VerkaufAlles ist als Ankündigungsunternehmen nicht verpflichtet
              Informationen über die von Kunden angebotenen Leistungen
              einzuholen oder weiterzugeben.
            </Typography>
            <Typography variant="body1" mt={1.7}>
              VerkaufAlles übernimmt keine wie immer geartete Haftung
              (Schadenersatz, Produkthaftung, etc) für
            </Typography>
            <ol
              style={{
                listStyle: "disc",
                marginTop: "5px",
              }}
            >
              <li>
                die vom Kunden über den Kunden gemachten Angaben (Richtigkeit,
                Zuverlässigkeit, etc)
              </li>
              <li>die inserierten Leistungen</li>
              <li>
                ob ein Vertrag auf Grund eines veröffentlichten Inserates
                zustande kommt
              </li>
              <li>
                der Kunde die vertraglichen, gesetzlichen, urheberrechtlichen,
                wettbewerbsrechtlichen, markenschutzrechtlichen und sonstigen
                Dritter zustehender Dritter einhält
              </li>
              <li>
                die Geltendmachbarkeit von Rechten aus einem auf Grund eines
                Inserates abgeschlossenen Vertrages
              </li>
            </ol>
            <Typography variant="body1" mt={1.7}>
              VerkaufAlles haftet nicht für Schäden, die dem Kunden aus dem
              Missbrauch von an Dritte übermittelten Daten (z.B. in Anzeigen,
              E-Mails, Bewerbungen, Weiterempfehlungen etc.) resultieren.
            </Typography>
            <Typography variant="body1" mt={1.7}>
              VerkaufAlles übernimmt keine Haftung dafür ob auf Grund eines
              veröffentlichten Inserates ein Vertrag zustande kommt und auch
              nicht für die Geltendmachbarkeit von Rechten aus einem auf Grund
              eines Inserates geschlossenen Vertrages. Es wird keine Gewähr
              geleistet, keine Garantie übernommen und keine Haftung (auch keine
              Produkthaftung, Schadenersatz) oder sonstige Haftung übernommen
              für die in Inseraten angebotenen Leistungen.
            </Typography>
            <Typography variant="body1" mt={1.7}>
              Wird VerkaufAlles wegen Mängeln oder Schäden aus und im
              Zusammenhang mit den Leistungen von Dritten in Anspruch genommen,
              ist VerkaufAlles in jedem Fall ungeachtet der Natur des Schadens
              oder des Rechtsgrundes berechtigt, sich vollständig beim Kunde zu
              regressieren. Der Kunde haftet daher in dem Umfang und so lange,
              wie auch VerkaufAlles gegenüber Kunden und sonstigen Dritten
              haftet.
            </Typography>
            <Typography variant="body1" mt={1.7}>
              VerkaufAlles haftet nicht für die Kompatibilität des Ankündigungs-
              und Suchportals mit der Hard-, Software, für die ständige
              Verfügbarkeit, für Viren, Missbrauch oder Schäden, die durch
              unsachgemäße Bedienung des Kunden aufgrund von ungeeigneter,
              verwendeter Hard- und Software sowie für Fehlfunktionen, die
              aufgrund der Internetanbindung oder die sonst aus der Nutzung der
              Daten oder des Ankündigungs- und Suchportals entstehen. Die Kunden
              sind auch selbst dafür verantwortlich, welche Verlinkungen sie in
              Anspruch nehmen.
            </Typography>
            <Typography variant="body1" mt={1.7}>
              VerkaufAlles haftet auch nicht für einen Ausfall der Server von
              VerkaufAlles, der gesamt nicht länger als 3% jedoch mindestens 48
              Stunden innerhalb der vertraglich vereinbarten Schaltung andauert.
              VerkaufAlles weist darauf hin, dass VerkaufAlles seine Leistungen
              aus notwendigen Gründen einschränken und vorübergehend einstellen
              kann (z.B. Wartung, Sicherheits-, Kapazitäts-,
              Verbesserungsgründe). Diese Maßnahmen stellen keinen Ausfall dar
              und können auch nicht, selbst wenn sie im selben Leistungszeitraum
              in dem ein Ausfall vorkommt vorgenommen werden, zu einer
              Ausfallszeit addiert werden.
            </Typography>
            <Typography variant="body1" mt={1.7}>
              VerkaufAlles weist ausdrücklich darauf hin, dass die unberechtigte
              Herstellung von Kopien, unberechtigtes Verlinken (Framing) bzw.
              Veröffentlichung von Inseraten als eigenes Angebot eines Dritten
              getarnt nicht gänzlich ausgeschlossen werden kann. VerkaufAlles
              wird sich bemühen, soweit technisch und rechtlich möglich und
              zumutbar, diese Vorgehensweisen zu unterbinden. Der Inserent
              erteilt VerkaufAlles dazu bereits jetzt die allenfalls notwendige
              Zustimmung. Der Inserent hat jedoch keinerlei Ansprüche gegenüber
              VerkaufAlles aus einer trotzdem erfolgten unberechtigten
              Vorgehensweise von Dritten.
            </Typography>
            <Typography variant="body1" mt={1.7}>
              Sofern VerkaufAlles nach den gesetzlichen Bestimmungen für einen
              Schaden einzustehen hat, ist die Haftung von VerkaufAlles auf
              Vorsatz und grobe Fahrlässigkeit beschränkt. Eine weitergehende
              Haftung von VerkaufAlles, insbesondere für leichte Fahrlässigkeit,
              atypische Schäden, entgangenen Gewinn, Mangelschäden, mittelbare-
              und Folgeschäden, Schäden Dritter, etc. ist ausgeschlossen. Die
              Haftung von VerkaufAlles ist jedenfalls betragsmäßig mit dem
              Betrag/Preis des gegenständlichen Auftrags mit dem Kunden
              begrenzt. Die Beweislast für das Verschulden von VerkaufAlles
              trägt der Kunde. Schadenersatzansprüche verjähren jedenfalls
              innerhalb von sechs Monaten. Dies gilt vorbehaltlich
              anderslautender zwingender gesetzlicher Bestimmungen (z.B.
              Konsumentenschutzgesetz).
            </Typography>
            <Typography variant="body1" mt={1.7}>
              Eine Haftung von VerkaufAlles für Schäden aufgrund höherer Gewalt
              (z.B. Streik, Kriegsverbrechen, Erdbeben) ist ausgeschlossen.
            </Typography>
            <Typography variant="body1" mt={1.7}>
              VerkaufAlles haftet daher auch nicht für das Zustandekommen oder
              Nichtzustandekommen eines Vertrags zwischen Kunden sowie für einen
              bestimmten Erfolg eines Inserats. Für die Richtigkeit von
              Identitätsangaben von Kunden bzw. die Erreichbarkeit eines Kunden
              über die angegebenen Kontaktdaten ist VerkaufAlles nicht
              verantwortlich.
            </Typography>
            <Typography variant="body1" mt={1.7}>
              VerkaufAlles übernimmt keine Haftung für Chatinhalte. Fallen
              VerkaufAlles Gesetzwidrigkeiten in Chats auf, werden die Chats und
              das Account des Kunden unverzüglich gesperrt. VerkaufAlles ist
              berechtigt die IP Adresse zu blockieren.
            </Typography>
            <Typography variant="body1" mt={1.7}>
              VerkaufAlles haftet nicht für Schäden, die dem Kunden aus dem
              Missbrauch von an Dritte übermittelten Daten (z.B. in Anzeigen,
              E-Mails, Bewerbungen, Weiterempfehlungen etc.) resultieren.
            </Typography>
            <Typography
              variant="h5"
              fontSize={35}
              fontWeight={800}
              mt={2.5}
              display="flex"
              textAlign="center"
              justifyContent="center"
            >
              Schlussbestimmungen
            </Typography>
            <Typography variant="h5" fontSize={25} fontWeight={600} mt={2.5}>
              <li>Geheimhaltung</li>
            </Typography>
            <Typography variant="body1" mt={1.7}>
              Der Kunde verpflichtet sich sämtliche im Zuge der
              Geschäftsbeziehung mit VerkaufAlles, sei es schriftlich, mündlich
              oder auf dem Weg der elektronischen Datenverarbeitung,
              offengelegte, übergebene oder überlassene, oder auf jede eine
              andere Weise zur Kenntnis gelangte Informationen und Daten,
              Mitteilungen, Unterlagen, Betriebs- und Geschäftsgeheimnisse,
              Know-How, Codes etc. („Vertrauliche Informationen“) vertraulich zu
              behandeln und geheim zu halten.
            </Typography>
            <Typography variant="body1" mt={1.7}>
              Der Kunde verpflichtet sich diese Vertraulichen Informationen
              ausschließlich zur Vertragserfüllung zu verwenden, weder an Dritte
              zur Gänze, noch in Teilen oder auszugsweise weiterzugeben, noch in
              anderer Form Dritten zugänglich zu machen, nicht anderweitig zu
              verarbeiten, und insbesondere auch nicht zu eigenen Zwecken, oder
              sonst wie zu verwenden und zu verwerten.
            </Typography>
            <Typography variant="body1" mt={1.7}>
              Die Vertraulichkeit gilt auch nach einer allfälligen
              Vertragsbeendigung als vereinbart.
            </Typography>
            <Typography variant="body1" mt={1.7}>
              Bereits öffentlich bekannte oder sonst öffentlich zugängliche
              Informationen sind ausgenommen.
            </Typography>
            <Typography variant="h5" fontSize={25} fontWeight={600} mt={2.5}>
              <li>Anwendbares Recht</li>
            </Typography>
            <Typography variant="body1" mt={1.7}>
              Die AGB unterliegen österreichischem Recht unter Ausschluss der
              Regeln des Internationalen Privatrechts und des
              UN-Kaufrechtsübereinkommens. Die Anwendung des österreichischen
              IPRG und sonstiger Kollisionsnormen ist ausdrücklich
              ausgeschlossen.
            </Typography>
            <Typography variant="h5" fontSize={25} fontWeight={600} mt={2.5}>
              <li>Gerichtsstand</li>
            </Typography>
            <Typography variant="body1" mt={1.7}>
              Für sämtliche Streitigkeiten aus oder im Zusammenhang mit diesen
              AGB ist das sachlich in Betracht kommende Gericht in Wien
              zuständig. Zwingende Bestimmungen des Konsumentenschutzgesetzes
              bleiben unberührt.
            </Typography>
            <Typography variant="h5" fontSize={25} fontWeight={600} mt={2.5}>
              <li>Salvatorische Klausel</li>
            </Typography>
            <Typography variant="body1" mt={1.7}>
              Sollte eine Bestimmung der AGB oder des Vertrages ganz oder
              teilweise unwirksam oder undurchführbar sein oder werden, berührt
              dies nicht die Wirksamkeit oder Durchführbarkeit der übrigen
              Bestimmungen. Die unwirksame oder undurchführbare Bestimmung wird
              durch eine wirksame oder durchführbare Bestimmung ersetzt, die in
              ihrem wirtschaftlichen Gehalt der unwirksamen oder
              undurchführbaren Bestimmung möglichst nahe kommt; dasselbe gilt
              entsprechend für Lücken in den AGB.
            </Typography>
            <Typography variant="h5" fontSize={25} fontWeight={600} mt={2.5}>
              <li>Rechtsnachfolge, Übertragbarkeit</li>
            </Typography>
            <Typography variant="body1" mt={1.7}>
              Die AGB verpflichten jede der Parteien und ihre Rechtsnachfolger
              gemäß den Bestimmungen der AGB. Der Kunde ist nicht berechtigt,
              den Vertrag oder die zwischen den Parteien auf Grund dieses
              Vertrages begründeten Rechte und Pflichten aus dem Vertrag ohne
              vorherige schriftliche Zustimmung von VerkaufAlles an einen
              Dritten abzutreten oder auf sonstige Weise, auch im Wege der
              Gesamtrechtsnachfolge, zu übertragen.
            </Typography>
            <Typography variant="h5" fontSize={25} fontWeight={600} mt={2.5}>
              <li>Vertragssprache</li>
            </Typography>
            <Typography variant="body1" mt={1.7}>
              Diese AGB wurden in deutscher Sprache errichtet. Bei
              Widersprüchlichkeiten bzw. Abweichungen zwischen der deutschen und
              einer der anderen Sprachfassungen der AGB gilt vorrangig die
              deutsche Fassung.
            </Typography>
          </ol>
        </Box>
      </Box>
    </>
  );
}

export default TermsConditions;
