import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { getCookie } from "../../utils/cookie";

const FAQ_LIST = `${process.env.REACT_APP_BASE_URL}/faqs`;

const getToken = () => getCookie(process.env.REACT_APP_LOGIN_TOKEN);
const token = getToken();

const initialState = {
  data: [],
  status: null,
  message: null,
};

const FaqListSlice = createSlice({
  name: "FaqList",
  initialState,
  reducers: {
    faqListSuccess: (state, action) => {
      state.data = action.payload.data;
      state.status = action.payload.status;
      state.message = action.payload.message;
    },
    faqListFailure: (state, action) => {
      state.status = action.payload.status;
      state.message = action.payload.message;
    },
  },
});

export const { faqListSuccess, faqListFailure } = FaqListSlice.actions;

export const fetchFaqList = () => async(dispatch) => {
  try {
    const response = await axios.get(FAQ_LIST, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch(
      faqListSuccess({
        status: response.data.status,
        message: response.data.message,
        data: response.data.data,
      })
    );
    return response;
  } catch (error) {
    dispatch(
      faqListFailure({
        status: false,
        message: error.message,
      })
    );
  }
};

export default FaqListSlice.reducer;
