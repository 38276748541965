import React, { useEffect, useState } from "react";
import { Avatar, Box, Button, Grid, Typography } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// Components
import Header from "../../../components/Header/Header";

// Images
import favourite from "../../../assets/images/ProductDetails/favourite-add.svg";
import unfavourite from "../../../assets/images/ProductDetails/unfavourite.svg";
import share from "../../../assets/images/ProductDetails/icons8-share.svg";
import location from "../../../assets/images/ProductDetails/location.svg";
import drop from "../../../assets/images/ProductDetails/drop.svg";
import ProductSlide from "../../../components/ProductsSlide/ProductSlide";
import Footer from "../../../components/Footer/Footer";
import Spinner from "../../../components/Spinner/Spinner";
import { formatPreviewData } from "../../../utils/helper";
import EmailInquiry from "./EmailInquiry";
import { addFavourite } from "../../../store/favourite/favouriteSlice";
import ReportAdDialog from "../../../components/ReportPopup/ReportPopup";
import {
  fetchProductById,
  fetchRecentlyViewed,
} from "../../../store/product/productSlice";
import ShareAdsDialogBox from "../../../components/ShareAdsDialogBox/ShareAdsDialogBox";
import useAppSelectors from "../../../customHooks/useAppSelector";
import { useNavigateAction } from "../../../customHooks/useNavigateAction";
import EnhancedImageViewer from "./EnhancedImageViewer";

// Style
const dotStyle = {
  alignSelf: "center",
  width: "4px",
  height: "4px",
  borderRadius: "2px",
  backgroundColor: "#707187",
};

const state_zip_dist = {
  color: "#26252C",
  fontFamily: "Work Sans",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "18px",
};

const sponsoredText = {
  background: "#F5F9C8",
  padding: "4px",
  color: "#26252C",
  fontFamily: "Work Sans",
  fontSize: "12px",
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "115%",
};

const nickNameText = {
  color: "#26252C",
  fontFamily: "Work Sans",
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "110%",
};

const sinceText = {
  color: "#707187",
  fontFamily: "Work Sans",
  fontSize: "12px",
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "110%",
  textAlign: "left",
};

const otherAdsText = {
  color: "#00A6E8",
  fontFamily: "Work Sans",
  fontSize: "12px",
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "115%",
};

const detailsText = {
  marginTop: "24px",
  color: "#26252C",
  fontFamily: "Work Sans",
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: 600,
  lineHeight: "115%",
};

const detailsText2 = {
  marginTop: "12px",
  color: "#707187",
  fontFamily: "Work Sans",
  fontSize: "12px",
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "115%",
};

const descriptive = {
  color: "#26252C",
  fontFamily: "Work Sans",
  fontSize: "20px",
  fontStyle: "normal",
  fontWeight: 600,
  lineHeight: "115%",
};

const descriptiveText = {
  marginTop: "8px",
  color: "#26252C",
  fontFamily: "Work Sans",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "18px",
};

const noteText = {
  color: "#26252C",
  fontFamily: "Work Sans",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "18px",
};

const iconBtn = {
  width: "32px",
  height: "32px",
  boxShadow: "none",
  backgroundColor: "transparent",
  "&:hover": {
    boxShadow: "none",
    backgroundColor: "transparent",
  },
  "&:active": {
    boxShadow: "none",
    backgroundColor: "transparent",
  },
};

const textDays = {
  marginTop: 0.7,
  marginRight: 1,
  color: "#707187",
  fontWeight: 600,
  font: "Work Sans",
};

const SectionName = ({ sectionData }) => {
  const excludedFields = [
    "image",
    "descriptiveParagraph",
    "priceType",
    "title",
    "price",
    "description",
    "signature",
  ];
  const formatedSectionData = formatPreviewData(sectionData);

  // Filter out the excluded fields
  const dataChunks = Object?.entries(formatedSectionData)?.filter(
    ([key]) => !excludedFields.includes(key)
  );
  return (
    <Grid container spacing={2}>
      {dataChunks?.map(([key, { label, value }], index) => {
        // Case when the value is a string with ' | ' (like additional information)
        const isPipeSeparated =
          typeof value === "string" && value.includes(" | ");

        return (
          <Grid
            item
            xs={isPipeSeparated ? 12 : 6} // Full row for pipe-separated values, otherwise half row
            key={index}
          >
            <Box
              sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}
            >
              <Typography
                sx={{ ...detailsText2, fontWeight: 700, fontSize: "0.87rem" }}
              >
                {label}
              </Typography>

              <Typography
                sx={{ ...detailsText2, textAlign: "end", fontSize: "0.87rem" }}
              >
                {isPipeSeparated ? (
                  <span style={{ display: "block", wordBreak: "break-word" }}>
                    {value || "N/A"}
                  </span>
                ) : Array.isArray(value) ? (
                  // Handling array values
                  value.length ? (
                    value.join(" | ")
                  ) : (
                    "N/A"
                  )
                ) : value === true ? (
                  "Yes"
                ) : value === false ? (
                  "No"
                ) : (
                  value || "N/A"
                )}
              </Typography>
            </Box>
            <div
              style={{
                borderBottom: "1px dashed #cbcbd1",
                marginBottom: "16px",
              }}
            />
          </Grid>
        );
      })}
      <Grid item xs={12}></Grid>
    </Grid>
  );
};

function ProductDetails() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleProtectedAction = useNavigateAction();
  const { slug } = useParams();
  const [isFavourite, setIsFavourite] = useState([]);
  const [isShare, setIsShare] = useState(false);
  const [isEmailInquiryOpen, setEmailInquiryOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isViewerOpen,setIsViewerOpen] = useState(false)
  const { isAuthenticated, currentUserData } = useAppSelectors();

  const recentProductList = useSelector(
    (state) => state?.productReducer?.recentlyViewedData
  );
 
  const productDetail = useSelector(
    (state) => state.productReducer?.productDetailsById
  );
 
  const [selectedImage, setSelectedImage] = useState(
    productDetail?.images?.[0]
  );

  useEffect(() => {
    setLoading(true);
    dispatch(fetchProductById(slug)).then((res) => setLoading(false));
  }, []);

  useEffect(() => {
    dispatch(fetchRecentlyViewed());
  }, []);

  useEffect(() => {
    if (productDetail?.images?.length > 0) {
      setSelectedImage(productDetail.images[0]);
    }
  }, [productDetail]);

  useEffect(() => {
    if (productDetail?.images?.length > 0) {
      setSelectedImage(productDetail.images[0]);
    }
  }, [productDetail]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Navigate to seller profile
  const navigateToSellerProfileHandle = () => {
    handleProtectedAction(() => {
      navigate(`/seller-profile/${productDetail.createdBy.id}`);
    });
  };

  const handleFavourite = (id, event) => {
    handleProtectedAction(() => {
      const updated = [...isFavourite];
      if (updated?.includes(id)) {
        updated.filter((prodIds) => prodIds !== id);
      } else {
        updated.push(id);
      }

      setIsFavourite(updated);
      dispatch(addFavourite(id));
    });
  };

  const handleShare = () => {
    setIsShare(true);
  };

  const handleCloseShare = () => {
    setIsShare(false);
  };
  return (
    <>
      <Header />
      {isEmailInquiryOpen && (
        <EmailInquiry
          productId={productDetail?.id}
          open={isEmailInquiryOpen}
          onClose={setEmailInquiryOpen}
        />
      )}
      {loading && (
        <>
          <Box minHeight={"25rem"}>
            <Spinner />
          </Box>
        </>
      )}
      {!loading && (
        <Box paddingLeft="32px" mt={10.4} marginRight={"24px"}>
          <Grid container spacing={"16px"}>
            {/* Selected images */}
            <Grid item xs={12} sm={6} md={5}>
              <>
                <img
                onClick={() => setIsViewerOpen(true)}
                  src={selectedImage}
                  alt="img"
                  style={{ width: "100%", height: "618px" }}
                />
              </>
              {isViewerOpen && (
                <EnhancedImageViewer
                  src={productDetail?.images}
                  currentIndex={0}
                  disableScroll={true}
                  onClose={() => setIsViewerOpen(false)}
                  closeOnClickOutside={true}
                />
              )}

              {/* Select Images */}
              <Box sx={{ marginTop: "12px" }}>
                <Grid container spacing={2}>
                  {productDetail?.images?.map((pic, index) => (
                    <Grid item xs={3} key={index}>
                      <img
                        src={pic}
                        alt={`Product Img${index + 1}`}
                        onClick={() => setSelectedImage(pic)}
                        style={{
                          width: "100%",
                          height: "94px",
                          objectFit: "cover",
                          borderRadius: "8px",
                          border:
                            selectedImage === pic
                              ? "4px solid #00A6E8"
                              : "2px solid transparent",
                        }}
                      />
                    </Grid>
                  ))}
                </Grid>
              </Box>
            </Grid>

            {/* Data */}
            <Grid item xs={12} sm={6} md={7}>
              {/* Fixed Price text */}
              <Typography
                sx={{
                  color: "#707187",
                  fontFamily: "Work Sans",
                  fontSize: "16px",
                  fontStyle: "normal",
                  fontWeight: 500,
                  lineHeight: "110%",
                }}
              >
                {productDetail?.details?.priceType?.value}
              </Typography>

              {/* Price */}
              <Box sx={{ display: "flex", gap: "8px" }}>
                <Typography
                  sx={{
                    alignSelf: "center",
                    color: "#707187",
                    fontFamily: "Work Sans",
                    fontSize: "28px",
                    fontStyle: "normal",
                    fontWeight: 500,
                    lineHeight: "normal",
                    textDecoration: "strikethrough",
                  }}
                >
                  €
                </Typography>
                <Typography
                  sx={{
                    alignSelf: "center",
                    color: "#707187",
                    fontFamily: "Work Sans",
                    fontSize: "28px",
                    fontStyle: "normal",
                    fontWeight: 500,
                    lineHeight: "normal",
                    textDecoration: "strikethrough",
                  }}
                >
                  {productDetail?.details?.price?.value}
                </Typography>
              </Box>
              <Box display="flex" justifyContent="flex-end" mt={-5}>
                <Typography variant="subtitle2" sx={textDays}>
                  {productDetail?.createdAt}
                </Typography>
                <Button
                  sx={iconBtn}
                  onClick={() => handleFavourite(productDetail?.id)}
                >
                  <img
                    src={
                      isFavourite.includes(productDetail?.id) ||
                      productDetail?.favourite
                        ? unfavourite
                        : favourite
                    }
                    alt="favourite"
                  />
                </Button>

                <Button sx={iconBtn} onClick={handleShare}>
                  <img src={share} alt="share" />
                </Button>
              </Box>

              {/* Product title */}
              <Typography
                sx={{
                  alignSelf: "center",
                  textOverflow: "ellipsis",
                  color: "#26252C",
                  fontFamily: "Work Sans",
                  fontSize: "20px",
                  fontStyle: "normal",
                  fontWeight: 600,
                  lineHeight: "28px",
                  marginTop: "14px",
                }}
              >
                {productDetail?.details?.title?.value}
              </Typography>

              {/* Description */}
              <Box
                sx={{
                  color: "#26252C",
                  // width: "50%",
                }}
              >
                <Typography
                  sx={{
                    textOverflow: "ellipsis",
                    fontFamily: "Work Sans",
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: 500,
                    lineHeight: "18px",
                  }}
                >
                  {productDetail?.title?.value}
                </Typography>
              </Box>

              {/* State pin and village or district */}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "24px",
                }}
              >
                {/* STate pin dist */}
                <Box sx={{ display: "flex", gap: "8px" }}>
                  {/* Image and state */}
                  <Box sx={{ display: "flex", gap: "4px", marginRight: "8px" }}>
                    <img src={location} alt="location icon" />
                    <Typography sx={state_zip_dist}>State</Typography>
                  </Box>

                  {/* Dot */}
                  <div style={dotStyle} />

                  <Typography sx={state_zip_dist}>1010</Typography>

                  {/* Dot */}
                  <div style={dotStyle} />

                  <Typography sx={state_zip_dist}>
                    Village or district
                  </Typography>
                </Box>

                {/* Sponsored */}
                <Box
                  sx={{
                    display: "flex",
                    gap: "4px",
                  }}
                >
                  <Typography sx={sponsoredText}>Sponsored</Typography>
                </Box>
              </Box>

              {/* Highlights */}
              {/* <Box sx={{ marginTop: "26px" }}>
              <Typography sx={highLightText}>Highlights</Typography>
              <Box sx={{ marginTop: "16px" }}>
                {dummyHeighlight?.map((item) => (
                  <Box
                    key={item.id}
                    display={"flex"}
                    gap={"8px"}
                    marginTop={"8px"}
                  >
                    <img src={item.pic} alt="item icon" />
                    <Typography sx={highLightOptionText}>
                      {item.name}
                    </Typography>
                  </Box>
                ))}
              </Box>
            </Box> */}

              {/* Nick name and user profile */}
              <Button
                onClick={navigateToSellerProfileHandle}
                display={"flex"}
                sx={{
                  alignSelf: "center",
                  borderRadius: "4px",
                  border: "1px solid #D8D8DF",
                  background: "#FFF",
                  width: "328px",
                  padding: "12px",
                  marginTop: "24px",
                }}
              >
                <Avatar sx={{ width: "28px", height: "28px" }} />

                {/* Name and Image */}
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                    alignItems: "center",
                    marginLeft: "8px",
                  }}
                >
                  <Box>
                    <Typography sx={nickNameText}>
                      {productDetail?.createdBy?.nickname}
                    </Typography>
                    <Typography sx={sinceText}>Since 2024</Typography>
                  </Box>
                  <Box sx={{ display: "flex", gap: "2px" }}>
                    <Typography sx={otherAdsText}>Other Ads</Typography>
                    <img
                      src={drop}
                      alt="drop icon"
                      style={{ width: "12px", height: "12px" }}
                    />
                  </Box>
                </Box>
              </Button>

              {/* Details */}
              <Typography sx={detailsText}>Details</Typography>

              {/* Section name */}
              <Box sx={{ marginTop: "16px" }}>
                <SectionName sectionData={productDetail?.details} />
              </Box>

              {/* <Box sx={{ marginTop: "16px" }}>
              <SectionName />
            </Box> */}
            </Grid>

            {/* Descriptive Paragraph */}
            <Box marginTop={"19px"} marginLeft={"24px"}>
              <Typography sx={descriptive}>Descriptive Paragraph</Typography>

              <Typography sx={descriptiveText}>
                {productDetail?.details?.descriptiveParagraph?.value}
              </Typography>
            </Box>

            {/* Note text */}
          </Grid>

          {productDetail?.createdBy?.id !== currentUserData?.id && (
            <Box
              marginLeft={"0.5rem"}
              display={"flex"}
              gap="0.4rem"
              mt={"0.8rem"}
            >
              {productDetail?.contacts?.call && (
                <Button variant="contained" color="primary">
                  Call
                </Button>
              )}
              {productDetail?.contacts?.email && (
                <Button
                  onClick={() => setEmailInquiryOpen(true)}
                  variant="contained"
                  color="primary"
                >
                  Email
                </Button>
              )}
              {productDetail?.contacts?.chat && (
                <Button variant="contained" color="primary">
                  Chat
                </Button>
              )}
            </Box>
          )}

          <Box
            marginTop={"1rem"}
            marginLeft={"0.5rem"}
            sx={{
              background: "var(--Red-50, #FEF2F2)",
              padding: "8px",
              borderRadius: "4px",
              border: "1px dashed var(--Red-700, #BB1A1A)",
            }}
          >
            <Typography sx={noteText}>
              Note: Lorem ipsum dolor sit amet consectetur. Viverra congue
              faucibus quis vestibulum amet urna tempus id aenean.
            </Typography>
          </Box>
          {currentUserData?.id !== productDetail?.createdBy?.id && (
            <ReportAdDialog adId={productDetail?.id} />
          )}
          <Box mt={"1rem"} textAlign={"center"}>
            <Typography sx={noteText}>Id: {productDetail?.adId}</Typography>
          </Box>
          {/* Product slide */}
          {isAuthenticated && (
            <ProductSlide
              title="Recently Veiwed Ads"
              products={recentProductList}
            />
          )}
        </Box>
      )}

      {/* Footer */}
      <Box sx={{ marginTop: "41px" }}>
        <Footer />
      </Box>

      {/* Share Popup */}
      <ShareAdsDialogBox
        open={isShare}
        onClose={handleCloseShare}
        slug={productDetail?.slug || "Product"}
      />
    </>
  );
}

export default ProductDetails;
