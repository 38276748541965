import React from "react";
import useAppSelectors from "../../customHooks/useAppSelector";
import { Navigate, useLocation } from "react-router-dom";



const PrivateRoute = ({ children }) => {
  let location = useLocation();
  const {isAuthenticated} = useAppSelectors();
  if(!isAuthenticated){
    return <Navigate to="/auth" state={{ from: location }} replace />;
  }
  return children;
};

export default PrivateRoute;
