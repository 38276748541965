import React, { useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Button,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";

// Images
import back from "../../../assets/images/SellerProfile/back.svg";
import add from "../../../assets/images/SellerProfile/add.svg";
import remove from "../../../assets/images/SellerProfile/remove.svg";

// Components
import Header from "../../../components/Header/Header";
import ProductSlide from "../../../components/ProductsSlide/ProductSlide";
import Footer from "../../../components/Footer/Footer";
import { useDispatch, useSelector } from "react-redux";
import { getProfileById } from "../../../store/profile/profileSlice";
import ProductCard from "../../../components/ProductCard/ProductCard";
import {
  fetchFollowList,
  followUser,
  unfollowUser,
} from "../../../store/follow/followSlice";
import Spinner from "../../../components/Spinner/Spinner";
import useAppSelectors from "../../../customHooks/useAppSelector";

// Styles
const backBtn = {
  margin: 0,
  padding: 0,
  marginTop: "18px",
  marginLeft: "32px",
  gap: "12px",
  boxShadow: "none",
  backgroundColor: "transparent",
  "&:hover": {
    boxShadow: "none",
    backgroundColor: "transparent",
  },
  "&:active": {
    boxShadow: "none",
    backgroundColor: "transparent",
  },
};

const backButtonText = {
  color: "#707187",
  fontFamily: "Work Sans",
  fontSize: "20px",
  fontStyle: "normal",
  fontWeight: 600,
  lineHeight: "28px",
  textTransform: "none",
};

const nickNameText = {
  color: "#26252C",
  fontFamily: "Work Sans",
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "110%",
};

const followersText = {
  color: "#707187",
  fontFamily: "Work Sans",
  fontSize: "12px",
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "115%",  
};

const followButton = {
  color: "#00A6E8",
  fontFamily: "Work Sans",
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "22px",
};

const tableHead = {
  color: "#26252C",
  fontFamily: "Work Sans",
  fontSize: "12px",
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "115%",
};

function SellerProfile() {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [followed, setFollowed] = useState(false);
  const [loading, setLoading] = useState(false);
  const { currentUserData } = useAppSelectors();
  const sellerData = useSelector((state) => state?.profileReducer?.data);  

  const productList = useSelector((state) => state?.productReducer?.data);

  useEffect(() => {
    if (id) {
      setLoading(true);
      dispatch(getProfileById(id)).then(() => {
        setLoading(false);
      });
    }
  }, [dispatch, id]);

  useEffect(() => {
    dispatch(fetchFollowList());
  }, [dispatch]);

  useEffect(() => {
    setFollowed(sellerData?.profile?.follow);
  }, [sellerData]);

  const handleFollow = (id) => {
    if (followed) {
      dispatch(unfollowUser(id));
    } else {
      dispatch(followUser(id));
    }
    setFollowed(!followed);
  };

  return (
    <>
      <Header />
      <Box mt={8.4}>
      {/* Back button */}
      <Button sx={backBtn} onClick={() => navigate(-1)}>
        <img
          src={back}
          alt="back icon"
          style={{ width: "32px", height: "32px", boxShadow: "none" }}
        />
        <Typography sx={backButtonText}>Back</Typography>
      </Button>

      {/* Icon, Name, and Follow button and Table */}
      {loading && (
        <>
          <Box minHeight={"11rem"}>
            <Spinner />
          </Box>
        </>
      )}
      {!loading && (
        <Box
          sx={{
            padding: "44px 38px",
            marginTop: "12px",
            marginLeft: "32px",
            marginRight: "32px",
            backgroundColor: "#fff",
            border: "1px solid #D8D8DF",
            background: " #FFF",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
              <Avatar
                alt="Icon"
                src=""
                sx={{ width: "32px", height: "32px" }}
              />
              <Box>
                <Typography sx={nickNameText}>
                  {sellerData?.profile?.nickname}
                </Typography>
                <Typography sx={followersText}>
                  {sellerData?.profile?.followers} Followers
                  {sellerData?.ads?.length || 0} Ads
                </Typography>
              </Box>
            </Box>
            {currentUserData?.id !== sellerData?.profile?.id && (
              <Button
                sx={followButton}
                onClick={() => handleFollow(sellerData?.profile?.id)}
              >
                <img
                  src={followed ? remove : add}
                  alt={followed ? "unfollow icon" : "follow icon"}
                />
                {followed ? "Unfollow" : "Follow"}
              </Button>
            )}
          </Box>

          {sellerData?.profile?.type === 0 ? (
            // Company Details
            <TableContainer sx={{ marginTop: "24px" }}>
              <Typography sx={{ ...tableHead, marginBottom: "12px" }}>
                Company Details
              </Typography>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell style={followersText}>Company Name:</TableCell>
                    <TableCell style={followersText}>
                      {sellerData?.profile?.firstName || "N/A"}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            // Personal Details
            <TableContainer sx={{ marginTop: "24px" }}>
              <Typography sx={{ ...tableHead, marginBottom: "12px" }}>
                Personal Details
              </Typography>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell style={followersText}>Full Name:</TableCell>
                    <TableCell style={followersText}>
                      {`${sellerData?.profile?.firstName || "N/A"} ${
                        sellerData?.profile?.lastName || ""
                      }`}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          )}

          <ProductSlide title="Seller's Ads" products={sellerData?.ads} />
        </Box>
      )}

      <Box sx={{ marginLeft: "32px", marginRight: "32px" }}>
        <ProductSlide title="Relative Products" products={productList} />
      </Box>
</Box>
      <Footer />
    </>
  );
}

export default SellerProfile;
