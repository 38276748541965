import React, { useState } from "react";
import { TextField, InputAdornment } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ComplexSelectPopup from "./ComplexSelectPopup";

const ComplexSelect = ({ field, values, setFieldValue }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleDropdownClick = () => {
    setIsModalOpen(true);
  };

  const handlePopupClose = () => {
    setIsModalOpen(false);
  };

 const showData = (values)=>{
  let displayText = "";
  if (Array.isArray(values)) {
    displayText = values
      .map((item) => item.displayData)
      .filter(Boolean) 
      .join(", ");
  } else {
    displayText = values || "";
  }
  return displayText;
 }
  const handleSave = (formattedData) => {
    setFieldValue(field.props.name, formattedData);
    setIsModalOpen(false);
  };

  return (
    <>
      <TextField
        fullWidth
        variant="standard"
        name={field.props.name}
        label={field.props.label}
        value={showData(values)}
        onClick={handleDropdownClick}
        InputProps={{
          readOnly: true,
          endAdornment: (
            <InputAdornment position="end">
              <ArrowDropDownIcon />
            </InputAdornment>
          ),
        }}
      />

      {/* Render the Popup Modal */}
      {isModalOpen && (
        <ComplexSelectPopup
          open={isModalOpen}
          onClose={handlePopupClose}
          parentField={field}
          childrenFields={field.props.childrenFields}
          multiple={field.props.multiple}
          childFieldValues={values}
          onSave={handleSave} // Only save to display values on save
        />
      )}
    </>
  );
};

export default ComplexSelect;
