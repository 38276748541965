import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { sendEmail } from "../../../store/product/productSlice";
import { toast } from "react-toastify";

const EmailInquiry = ({ open, onClose, productId }) => {
  const [emailContent, setEmailContent] = useState("");
  const dispatch = useDispatch();
  const handleSendEmail = () => {
    const reqBody = {
      type: "email",
      data: { message: emailContent },
    };
    dispatch(sendEmail(productId, reqBody)).then((res) => {
      if (res?.data?.status) {
        onClose(false);
        toast.success(res?.data?.message);
      } else {
        onClose(false);

        toast.success(res?.data?.message);
      }
    });
  };
  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Send Email</DialogTitle>
      <DialogContent>
        <Box mt={"1rem"}>
          <TextField
            multiline
            rows={4}
            fullWidth
            label="Email Content"
            value={emailContent}
            onChange={(e) => setEmailContent(e.target.value)}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose(false)} color="secondary">
          Cancel
        </Button>
        <Button onClick={handleSendEmail} variant="contained" color="primary">
          Send
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EmailInquiry;
