import React, { useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import {
  EmailOutlined,
  HttpsOutlined,
  Person,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import { FacebookOutlined, Apple, Google } from "@mui/icons-material";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { encryptPassword } from "../../../utils/encryption";
import { useDispatch } from "react-redux";
import { registerUser } from "../../../store/auth/authSlice";
import * as Yup from "yup";
import { useFormik } from "formik";
import "./register.css";
import Email from "../../../assets/images/LoginImages/mail.svg"
import Password from "../../../assets/images/LoginImages/password.svg";
import { FireBaseSignUp } from "../../../utils/helper";

// Validation schema
const validationSchema = Yup.object({
  nickname: Yup.string(),
  email: Yup.string().email("Invalid email").required("Required"),
  password: Yup.string()
    // .min(8, "Password must be 8+ characters")
    // .matches(/^(?=.*[a-z])(?=.*[A-Z])/, "Must contain upper/lowercase")
    // .matches(/\d/, "Must contain a number")
    // .matches(
    //   /[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/,
    //   "Must contain a special character"
    // )
    .required("Required"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password")], "Passwords must match")
    .required("Required"),
});

function Register() {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [isSubscribed, setSubscribed] = useState(false);
  const [type, setType] = useState(0);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const handleEncrypt = (text) => encryptPassword.encrypt(text);

  const formik = useFormik({
    initialValues: {
      nickname: "",
      email: "",
      password: "",
      confirmPassword: "",
    },
    validationSchema,
    onSubmit: async (values, { resetForm }) => {
      setLoading(true);
      const reqBody = {
        nickname: values.nickname,
        email: values.email,
        password: handleEncrypt(values.password),
        type,
        is_subscribed: isSubscribed,
      };

      try {
        const res = await dispatch(registerUser(reqBody));
        sessionStorage.setItem("userEmail", values.email);
        toast.success(res?.message || "Registration successful!");
       // FireBaseSignUp(values.email,values.password)
        navigate("/email-verification");
        resetForm();
      } catch (error) {
        toast.error(error.message || "Registration failed");
      } finally {
        setLoading(false);
      }
    },
  });

  const isButtonDisabled = !termsAccepted || loading;

  const inputField = (label, field, type, icon, show, setShow) => (
    <FormControl variant="standard" fullWidth>
      <InputLabel>{label}</InputLabel>
      <Input
        type={show ? "text" : type}
        name={field}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values[field]}
        error={formik.touched[field] && Boolean(formik.errors[field])}
        startAdornment={
          <InputAdornment position="start">{icon}</InputAdornment>
        }
        endAdornment={
          (field === "password" || field === "confirmPassword") && (
            <InputAdornment position="end">
              <IconButton onClick={() => setShow(!show)}>
                {show ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          )
        }
      />
      {formik.touched[field] && formik.errors[field] && (
        <Typography color="error">{formik.errors[field]}</Typography>
      )}
    </FormControl>
  );

  return (
    <Box
      sx={{
        border: "0.5px solid #D8D8DF",
        width: "936px",
        minHeight: "462px",
        bgcolor: "#FFF",
        boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
        overflow: "hidden",
      }}
    >
      <Box sx={{ padding: "48px" }}>
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
          Welcome to VerkaufAllen!
        </Typography>
        <Typography
          variant="subtitle2"
          sx={{ color: "#707187", fontFamily: "Work Sans", fontSize: "16px" }}
        >
          Please enter your credentials to access your account.
        </Typography>
        <Stack direction="row" spacing={2} mt={4}>
          <Button
            onClick={() => setType(0)}
            variant="outlined"
            sx={{
              bgcolor: type === 0 && "#1976d2",
              color: type === 0 && "#fff",
            }}
          >
            Private
          </Button>
          <Button
            onClick={() => setType(1)}
            variant="outlined"
            sx={{
              bgcolor: type === 1 && "#1976d2",
              color: type === 1 && "#fff",
            }}
          >
            Commercial
          </Button>
        </Stack>
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={2} mt={3}>
            <Grid item xs={12} sm={4}>
              {inputField(
                "Nick Name",
                "nickname",
                "nickname",
                <Person />,
                false,
                () => {}
              )}
            </Grid>
            <Grid item xs={12} sm={4}>
              {inputField(
                "Email Id",
                "email",
                "email",
                <img
                  src={Email}
                  alt="icon"
                  style={{ width: 20, height: 20 }}
                />,
                false,
                () => {}
              )}
            </Grid>
            <Grid item xs={12} sm={4}>
              {inputField(
                "Password",
                "password",
                "password",
                <img
                  src={Password}
                  alt="icon"
                  style={{ width: 20, height: 20 }}
                />,
                showPassword,
                setShowPassword
              )}
            </Grid>
            <Grid item xs={12} sm={4}>
              {inputField(
                "Confirm Password",
                "confirmPassword",
                "password",
                <img
                  src={Password}
                  alt="icon"
                  style={{ width: 20, height: 20 }}
                />,
                showConfirmPassword,
                setShowConfirmPassword
              )}
            </Grid>
          </Grid>
          <Grid container spacing={2} mt={2}>
            <Grid item xs={12} sm={6}>
              <FormControlLabel
                control={
                  <Switch onChange={(e) => setSubscribed(e.target.checked)} />
                }
                label="Yes, I look forward to receiving regular updates by email"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={(e) => setTermsAccepted(e.target.checked)}
                  />
                }
                label="By signing up, you agree to our terms and conditions and Privacy Policy."
              />
            </Grid>
          </Grid>
          <Button
            type="submit"
            variant="contained"
            sx={{ mt: 3, width: "300px" }}
            disabled={isButtonDisabled}
          >
            {loading ? "Registering..." : "Register"}
          </Button>
        </form>
        <div className="otherOption">
          <p> Or continue with</p>
        </div>
        <Divider className="horizontalLine" />
        <Stack direction="row" spacing={2} justifyContent="center" mt={2}>
          <Button
            startIcon={<Google />}
            sx={{ backgroundColor: "#eeeeee", textTransform: "none" }}
          >
            Continue With Google
          </Button>
          <Button
            startIcon={<FacebookOutlined />}
            sx={{ backgroundColor: "#eeeeee", textTransform: "none" }}
          >
            Continue With Facebook
          </Button>
          <Button
            startIcon={<Apple />}
            sx={{ backgroundColor: "#eeeeee", textTransform: "none" }}
          >
            Continue With Apple
          </Button>
        </Stack>
      </Box>
    </Box>
  );
}

export default Register;
