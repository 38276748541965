import React from "react";
import { Avatar, Box, Typography } from "@mui/material";

// Images
import downArrow from "../../assets/images/CategoryImages/arrow-down.png";
import { useNavigate } from "react-router-dom";

const Category = ({ categories }) => {  
  const navigate = useNavigate();

  const handleSelectCategory = (value) => {    
    navigate(`/category/${value.slug}`);
  };

  return (
    <Box
      mt={10.4}
      display="flex"
      flexDirection="row"
      justifyContent="space-evenly"
      padding="24px"
      sx={{
        background: "#fff",
        marginTop: "24px",
        borderRadius: "4px",
        border: "1px solid  #D8D8DF",
      }}
    >
      {/* Category options */}
      {categories &&
        categories.length > 0 &&
        categories.map((item) => (
          <Box
            key={item.id}
            aria-label={`Category ${item.name}`}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
            onClick={() => handleSelectCategory(item)}
          >
            {/* Image */}
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "60px", // set width to match image
                height: "60px", // set height to match image
                borderRadius: "50%", // make the box circular
                overflow: "hidden", // ensure image stays within the circular box
                cursor: "pointer",
              }}
            >
              <img
                src={item.icon}
                alt={item.name}
                style={{
                  width: "100%", // make the image fill the box
                  height: "100%",
                  objectFit: "cover", // ensure image covers the box without distortion
                }}
              />
            </Box>

            {/* name and dropdown icon */}
            <Box
              display="flex"
              alignItems="center"
              sx={{ gap: "4px", marginTop: "13px" }}
            >
              <Typography
                sx={{
                  color: "#26252C",
                  textAlign: "center",
                  fontFamily: "Work Sans",
                  fontSize: "12px",
                  fontStyle: "normal",
                  fontWeight: "500",
                  lineHeight: " 115%",
                }}
              >
                {item?.name}
              </Typography>
              <Avatar
                src={downArrow}
                alt="dropdown logo"
                sx={{ width: 16, height: 16 }}
              />
            </Box>
          </Box>
        ))}
    </Box>
  );
};

export default Category;
