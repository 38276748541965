import { createSlice } from "@reduxjs/toolkit";
import axios from "../../utils/axios";
import { getCookie } from "../../utils/cookie";

// API of prduct
// https://verkaufdev.semseosmo.com/api/v1/product/newlisting
const PRODUCT_API = `${process.env.REACT_APP_BASE_URL}`;
const REPORT_ADS = `${process.env.REACT_APP_BASE_URL}//product/interact`;

const initialState = {
  submitData: {},
  localPreviewProductData: {},
  publishData: {},
  productDetailsById: {},
  recentlyViewedData : {},
  data: [],
  status: null,
  message: null,
};

export const ProductListSlice = createSlice({
  name: "ProductList",
  initialState,
  reducers: {
    productListSuccess: (state, action) => {
      state.data = action.payload.data;
      state.status = action.payload.status;
      state.message = action.payload.message;
    },
    productDetailsById: (state, action) => {
      state.productDetailsById = action.payload.productDetailsById;
      state.status = action.payload.status;
      state.message = action.payload.message;
    },
    saveLocalPreviewProduct: (state, action) => {
      state.localPreviewProductData = action.payload.data;
    },
    saveProductPublishData: (state, action) => {
      state.publishData = action.payload.data;
    },
    fetchRecentlyViewedSuccess: (state, action) => {
      state.recentlyViewedData = action.payload.data;
      state.status = action.payload.status;
      state.message = action.payload.message;
    },
    productListFailure: (state, action) => {
      state.status = action.payload.status;
      state.message = action.payload.message;
    },
  },
});

export const {
  productListSuccess,
  productDetailsById,
  productListFailure,
  saveLocalPreviewProduct,
  saveProductPublishData,
  fetchRecentlyViewedSuccess,
} = ProductListSlice.actions;

export const headerConfig = () => {
  const token = getCookie(process.env.REACT_APP_LOGIN_TOKEN);

  const config = {
    headers: {},
  };

  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
};

export const fetchProductList = () => async (dispatch) => {
  try {
    const response = await axios.get(
      `${PRODUCT_API}/product/new-listing`,
      headerConfig()
    );

    dispatch(
      productListSuccess({
        status: response.data.status,
        message: response.data.message,
        data: response.data.data,
      })
    );
    return response;
  } catch (error) {
    dispatch(
      productListFailure({
        status: false,
        message: error.message,
      })
    );
  }
};

export const fetchProductById = (id) => async (dispatch) => {
  try {
    const response = await axios.get(
      `${PRODUCT_API}/product/${id}`,
      headerConfig()
    );
    dispatch(
      productDetailsById({
        status: response.data.status,
        message: response.data.message,
        productDetailsById: response.data.data,
      })
    );
    return response;
  } catch (error) {
    dispatch(
      productListFailure({
        status: false,
        message: error.message,
      })
    );
  }
};

export const reportAds = (id, reportData) => async (dispatch) => {
  const token = getCookie(process.env.REACT_APP_LOGIN_TOKEN);
  try {
    const response = await axios.post(`${REPORT_ADS}/${id}`, reportData, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
    // Dispatch a success action if needed, for now log the response
    return response;
  } catch (error) {
    console.error("Error reporting ad:", error.message);
    dispatch(
      productListFailure({
        status: false,
        message: error.message,
      })
    );
  }
};

export const uploadProduct = (formData) => async (dispatch) => {
  const token = getCookie(process.env.REACT_APP_LOGIN_TOKEN);
  try {
    const response = await axios.post(`${PRODUCT_API}/productt`, formData, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    });
    return response;
  } catch (error) {
    // Dispatch failure action with error message
  }
};

export const editMyAds = (formData,adId) => async (dispatch) => {
  const token = getCookie(process.env.REACT_APP_LOGIN_TOKEN);
  try {
    const response = await axios.put(`${PRODUCT_API}/my-ads/${adId}`, formData, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    });
    return response;
  } catch (error) {
    // Dispatch failure action with error message
  }
};

export const sendEmail = (id, data) => async (dispatch) => {
  const token = getCookie(process.env.REACT_APP_LOGIN_TOKEN);
  try {
    const response = await axios.post(
      `${PRODUCT_API}/product/interact/${id}`,
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return response;
  } catch (error) {
    // Dispatch failure action with error message
  }
};

export const localPreviewProduct = (previewData) => async (dispatch) => {
  try {
    dispatch(
      saveLocalPreviewProduct({
        data: previewData,
      })
    );
  } catch (error) {
    // Dispatch failure action with error message
  }
};

export const fetchRecentlyViewed = () => async (dispatch) => {
  try {
    const response = await axios.get(
      `${PRODUCT_API}/product/recently-viewed`,
      headerConfig()
    );
  
    dispatch(
      fetchRecentlyViewedSuccess({
        status: response.data.status,
        message: response.data.message,
        data: response.data.data,
      })
    );
    return response?.data?.data;
  } catch (error) {
    dispatch(
      productListFailure({
        status: false,
        message: error.message,
      })
    );
  }
};

export const Productpublish = (data) => async (dispatch) => {
  try {
    dispatch(
      saveProductPublishData({
        data: data,
      })
    );
  } catch (error) {
    // Dispatch failure action with error message
  }
};

export default ProductListSlice.reducer;
