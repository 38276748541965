import React, { useState } from "react";

// css
import "./ProductSlide.css";
import ProductCard from "../ProductCard/ProductCard";

// images
import Car4 from "../../assets/images/ProductImages/car-04.png";
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Typography,
} from "@mui/material";

function ProductSlide({ title, showDiv, products }) {
  return (
    <Box sx={{ marginTop: "24px" }} className="productslide-container">
      {/* product header text  */}
      <p>{title}</p>

      <Box sx={{ display: "flex" }}>
        {showDiv && (
          <Card
            sx={{
              width: 500,
              height: 314,
              overflowX: "auto",
              alignItems: "flex-start",
              gap: "16px",
              marginLeft: "16px",
              // marginRight: "16px",
              marginBottom: "16px",
              backgroundColor: "#00A6E8",
            }}
          >
            <CardActionArea>
              <CardMedia
                component="img"
                marginTop="10px"
                height="258px"
                width="62px"
                image={Car4}
                alt="green iguana"
                sx={{
                  objectFit: "contain",
                }}
              />
              <CardContent sx={{ paddingBottom: "10px" }}>
                <Typography
                  gutterBottom
                  variant="h5"
                  component="div"
                  textAlign="center"
                  color="white"
                >
                  Trendy Deal
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        )}
        {/*  product card*/}
        <ProductCard products={products} />
      </Box>
    </Box>
  );
}

export default ProductSlide;
