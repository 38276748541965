import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

// css
import "./ProductCard.css";

// Images
import location from "../../assets/images/ProductImages/location.png";
import favourite from "../../assets/images/ProductDetails/favourite-add.svg";
import unfavourite from "../../assets/images/ProductDetails/unfavourite.svg"

// COmponents
import { Box, Button } from "@mui/material";
import { addFavourite } from "../../store/favourite/favouriteSlice";
import { useNavigateAction } from "../../customHooks/useNavigateAction";

const iconBtn = {
  width: "32px",
  height: "32px",
  boxShadow: "none",
  backgroundColor: "transparent",
  "&:hover": {
    boxShadow: "none",
    backgroundColor: "transparent",
  },
  "&:active": {
    boxShadow: "none",
    backgroundColor: "transparent",
  },
};

function ProductCard({ products }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleProtectedAction = useNavigateAction();
  const [isFavourite, setIsFavourite] = useState([]);
  

  // Navigate handler
  const navigateToProductDetailsHandler = (id, slug) => {
    navigate(`/product-details/${slug}`);
  };


  const handleFavourite = (id, event) => {
     event.stopPropagation();
      handleProtectedAction(() => {
        let updated = [...isFavourite];
        if (updated?.includes(id)) {
          updated = updated.filter((prodIds) => prodIds !== id);
        } else{
          updated.push(id);
        }
        setIsFavourite(updated);
        dispatch(addFavourite(id));
      });
    };
  

  return (
    <>
      <div className="product-card-container">
        {products?.length > 0 &&
          products?.map((item, index) => (
            <Box
              key={item.id}
              className="product-card"
              onClick={() =>
                navigateToProductDetailsHandler(item.id, item.slug)
              }
            >
              <img
                style={{ objectFit: "fill" }}
                src={item.images[0]}
                alt={item.title}
              />
              {/* price and wishlist */}
              <div className="price-wishlist">
                {/* price */}
                <div className="product-price">
                  <p className="productPrice-2">{item.price}</p>
                </div>

                {/* favourite */}
                <Button
                  sx={iconBtn}
                  onClick={(event) => handleFavourite(item?.id, event)}
                >
                  <div className="wishlist">
                    <img
                      src={
                        isFavourite.includes(item.id) || item.favourite
                          ? unfavourite
                          : favourite
                      }
                      alt={isFavourite ? "unfavourite icon" : "favourite icon"}
                    />
                  </div>
                </Button>
              </div>

              {/* Name */}
              <div className="product-name">
                <p>
                  {item.title}
                  {index == 0 &&
                    "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Dignissimos ducimus dolores molestias ipsam maiores sapiente veniam cum, nihil soluta excepturi non ipsum id nesciunt quia saepe dicta accusamus sit expedita?"}
                </p>
              </div>

              {/* state and pin */}
              <div className="state-pin">
                <img src={location} alt="location icon" />
                <p style={{ marginLeft: "1.94px" }}>State</p>
                <div className="big-dot" />
                <p style={{ marginLeft: "1.94px" }}>{item.pin}</p>
                <div className="big-dot" />
              </div>

              {/* Villae or district */}
              <div className="village-district">
                <p>Village of district</p>
              </div>

              {/* Sponsored and days*/}
              <div className="sponsored">
                <div>
                  <p className="sponsored-text">Sponsored</p>
                </div>
                {item.sponsored && (
                  <p className="sponsored-days">{item.days}</p>
                )}
              </div>
            </Box>
          ))}
      </div>
    </>
  );
}

export default ProductCard;
