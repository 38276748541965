import React, { useState, useEffect, useLayoutEffect } from "react";
import {
  Button,
  Divider,
  Box,
  Typography,
  Stack,
  Switch,
  FormControlLabel,
} from "@mui/material";
import styled from "@emotion/styled";

// Components
import CenterHeader from "../../../components/CenterHeader/CenterHeader";
import { KeyboardBackspace } from "@mui/icons-material";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Productpublish } from "../../../store/product/productSlice";
import useAppSelectors from "../../../customHooks/useAppSelector";

const Container = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 24px;
  background-color: #f5f5f5;
`;

const WhiteBox = styled(Box)`
  background-color: #fff;
  width: 100%;
  height: 100vh;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
`;

const Heading = styled(Typography)`
  padding: 7px 0px 5px 20px;
  color: #000;
  font-family: "Work Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
`;

const ButtonContainer = styled(Box)`
  margin-top: auto;
  display: flex;
  justify-content: flex-end;
  gap: 16px;
  margin-bottom: 24px;
  margin-right: 24px;
`;

const ContactInfo = () => {
  const location = useLocation();
  const [switches, setSwitches] = useState({
    chat: false,
    call: false,
    email: false,
  });
  const { currentUserData } = useAppSelectors();
  const publishData = useSelector(
    (state) => state?.productReducer?.publishData
  );
  const editAdsData = useSelector(
    (state) => state?.myadsReducer?.adsDetailsData
  );
  useLayoutEffect(() => {
    setSwitches({
      ...switches,
      chat: editAdsData?.contacts?.chat,
      call: editAdsData?.contacts?.call,
      email: editAdsData?.contacts?.email,
    });
  }, []);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleChange = (event) => {
    setSwitches({
      ...switches,
      [event.target.name]: event.target.checked,
    });
  };

  function addContactsInsideData(formData, contacts) {
    const existingData = JSON.parse(formData.get("data"));

    existingData.contacts = contacts;

    formData.set("data", JSON.stringify(existingData));
    dispatch(Productpublish(formData));
  }

  const previewPage = () => {
    addContactsInsideData(publishData, switches);
    location.pathname.startsWith("/edit-post/")
      ? navigate("/edit-post/preview")
      : navigate("/post/preview");
  };

  return (
    <>
      <CenterHeader />
      <Container>
        <WhiteBox>
          <Heading sx={{ marginLeft: "-25px" }}>
            <Button
              sx={{
                "&:hover": {
                  border: "none",
                  boxShadow: "none",
                  backgroundColor: "transparent",
                },
              }}
            >
              <KeyboardBackspace sx={{ color: "black" }} />
            </Button>
            Contact Information
          </Heading>
          <Divider />
          <Box sx={{ marginLeft: "20px" }}>
            <Stack
              direction="row"
              divider={<Divider orientation="vertical" flexItem />}
              spacing={2}
              sx={{ marginTop: "20px", gap: 2 }}
            >
              <FormControlLabel
                control={
                  <Switch
                    checked={switches.chat}
                    onChange={handleChange}
                    name="chat"
                    size="small"
                  />
                }
                label={
                  <>
                    <Typography
                      variant="body2"
                      sx={{
                        fontFamily: "Work Sans",
                        fontSize: "15px",
                        fontStyle: "normal",
                        fontWeight: 600,
                        lineHeight: "18px",
                      }}
                    >
                      VerkaufAlles Chat
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      sx={{
                        fontFamily: "Work Sans",
                        fontSize: "12px",
                        fontStyle: "normal",
                        lineHeight: "10px",
                      }}
                    >
                      You will be notified by VerkaufAlles
                    </Typography>
                  </>
                }
              />
              {currentUserData?.phone?.lenght > 0 && (
                <FormControlLabel
                  control={
                    <Switch
                      checked={switches.call}
                      onChange={handleChange}
                      name="call"
                      size="small"
                    />
                  }
                  label={
                    <>
                      <Typography
                        variant="body2"
                        sx={{
                          fontFamily: "Work Sans",
                          fontSize: "15px",
                          fontStyle: "normal",
                          fontWeight: 600,
                          lineHeight: "18px",
                        }}
                      >
                        VerkaufAlles Call
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        sx={{
                          fontFamily: "Work Sans",
                          fontSize: "12px",
                          fontStyle: "normal",
                          lineHeight: "10px",
                        }}
                      >
                        {currentUserData?.phone[0]}
                      </Typography>
                    </>
                  }
                />
              )}

              <FormControlLabel
                control={
                  <Switch
                    checked={switches.email}
                    onChange={handleChange}
                    name="email"
                    size="small"
                  />
                }
                label={
                  <>
                    <Typography
                      variant="body2"
                      sx={{
                        fontFamily: "Work Sans",
                        fontSize: "15px",
                        fontStyle: "normal",
                        fontWeight: 600,
                        lineHeight: "18px",
                      }}
                    >
                      Email
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      sx={{
                        fontFamily: "Work Sans",
                        fontSize: "12px",
                        fontStyle: "normal",
                        lineHeight: "10px",
                      }}
                    ></Typography>
                  </>
                }
              />
            </Stack>
          </Box>
          <ButtonContainer>
            <Button variant="contained" color="secondary">
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              // disabled={!allSwitchesOn}
              onClick={previewPage}
            >
              Preview
            </Button>
          </ButtonContainer>
        </WhiteBox>
      </Container>
    </>
  );
};

export default ContactInfo;
