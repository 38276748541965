import React from 'react'
import Header from '../../../components/Header/Header';

function SuggestionsComplaint() {
  return (
    <>
      <Header />
    </>
  );
}

export default SuggestionsComplaint 
