import { useRef } from "react";
import styled from "@emotion/styled";
import { Box, Typography } from "@mui/material";
import uploadIcon from "../../assets/images/MarkePlace/uploadImage.svg";
import closeIcon from "../../assets/images/AdsPage/close_icon.svg";
import { useLocation } from "react-router-dom";

const UploadBox = styled(Box)(({ theme }) => ({
  border: "1px solid #D8D8DF",
  background: "#FFF",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  padding: "16px",
  cursor: "pointer",
  marginBottom: "16px",
  marginRight: "16px",
}));

const UploadImages = ({
  onImagesSelected,
  selectedImages,
  uploadedImageUrls,
  setUploadedImageUrls,
  setRemovedUploadedImageUrls,
  removedUploadedImageUrls
}) => {
  const fileInputRef = useRef(null);
  const location = useLocation();
  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    onImagesSelected([...files, ...selectedImages]);
    event.target.value = "";
  };

  const handleUploadClick = () => {
    fileInputRef.current.click();
  };

  const handleRemoveLocalImage = (index) => {
    const newImages = [...selectedImages];
    newImages.splice(index, 1);
    onImagesSelected(newImages);
  };

  const handleRemoveUploadedImageUrls = (index) => {
    const newImages = [...uploadedImageUrls];
    const removedImage = newImages.splice(index, 1);
    setUploadedImageUrls(newImages);
    setRemovedUploadedImageUrls([...removedImage,...removedUploadedImageUrls]);
    
  };
  return (
    <Box>
      {/* Uploaded Image render while uploading */}
      {location.pathname.startsWith("/edit-post/") && (
        <Box
          width={"80%"}
          mb={"0.85rem"}
          sx={{
            display: "flex",
            gap: "8px",
            overflowX: "auto",
            overflowY: "hidden",
          }}
        >
          {uploadedImageUrls.map((image, index) => (
            <Box
              position={"relative"}
              key={index}
              sx={{
                minWidth: "150px",
              }}
            >
              <img
                style={{
                  height: "8.5rem",
                  width: "8.5rem",
                  objectFit: "cover",
                }}
                src={image}
                alt={`uploadedImage ${index}`}
              />
              <Box
                sx={{ cursor: "pointer" }}
                zIndex={100}
                position={"absolute"}
                top={"-0.04rem"}
                right={"0.9rem"}
                onClick={() => handleRemoveUploadedImageUrls(index)}
              >
                <img src={closeIcon} alt="close" />
              </Box>
            </Box>
          ))}
        </Box>
      )}

      {/* This is for local Image which user is selecting for upload */}

      <Box display={"flex"} columnGap={"11px"}>
        {/* Upload Section */}
        <Box width={"25%"}>
          <UploadBox onClick={handleUploadClick}>
            <input
              accept="image/*"
              style={{ display: "none" }}
              id="upload-button"
              type="file"
              multiple
              ref={fileInputRef}
              onChange={handleFileChange}
            />
            <img
              src={uploadIcon}
              alt="upload icon"
              style={{
                height: "40px",
                width: "40px",
                paddingTop: "23px",
                paddingBottom: "23px",
              }}
            />
            <Typography
              sx={{
                color: "#000",
                fontFamily: "Work Sans",
                fontSize: "12px",
                fontStyle: "normal",
                fontWeight: 500,
                lineHeight: "115%",
                paddingLeft: "38px",
                paddingRight: "38px",
              }}
            >
              Upload Images
            </Typography>
          </UploadBox>
        </Box>

        {/* Scrollable Image Preview Section */}
        <Box
          width={"80%"}
          sx={{
            display: "flex",
            gap: "8px",
            overflowX: "auto",
            overflowY: "hidden",
            // scrollbarWidth: "none", // Hides scrollbar for Firefox
            // "-ms-overflow-style": "none", // Hides scrollbar for IE and Edge
            // "&::-webkit-scrollbar": {
            //   display: "none", // Hides scrollbar for Chrome, Safari, and Edge
            // },
          }}
        >
          {selectedImages.map((image, index) => (
            <Box
              position={"relative"}
              key={index}
              sx={{
                minWidth: "150px",
              }}
            >
              <img
                style={{
                  height: "8.5rem",
                  width: "8.5rem",
                  objectFit: "cover",
                }}
                src={URL.createObjectURL(image)}
                alt={`selected ${index}`}
              />
              <Box
                sx={{ cursor: "pointer" }}
                zIndex={100}
                position={"absolute"}
                top={"-0.04rem"}
                right={"0.9rem"}
                onClick={() => handleRemoveLocalImage(index)}
              >
                <img src={closeIcon} alt="close" />
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};
export default UploadImages;
