import { useLocation, useNavigate } from "react-router-dom";
import useAppSelectors from "./useAppSelector";

export const useNavigateAction = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { isAuthenticated } = useAppSelectors();
   
    const handleProtectedAction = (callback) => {
      if (!isAuthenticated) {
        // Store current location and any additional action data
        navigate('/auth', {
          state: {
            from: location.pathname,
            actionData: {
              type: 'REDIRECT_AFTER_LOGIN',
              path: location.pathname
            }
          }
        });
        return false;
      }
      
      // If authenticated, execute the callback
      return callback();
    };
  
    return handleProtectedAction;
  };