import { Box, Button, Grid } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";

const CategoryList = ({categoryId}) => {
  const categoriesData = useSelector((state) => state.categoryReducer || []);

  const renderSubcategoryButtons = (subCategories) => {
    return subCategories?.map((subcategory) => (
      <Grid item key={subcategory.id} xs={12 / 7} sm={2}>
        <Button
          variant="text"
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: 2,
          }}
          aria-label={subcategory.name}
        >
          <img
            src={subcategory.icon || "https://via.placeholder.com/40"}
            alt={subcategory.name}
            style={{
              marginBottom: "5px",
              borderRadius: "50%",
              width: "60px",
              height: "60px",
            }}
          />
          {subcategory.name}
        </Button>
      </Grid>
    ));
  };

  const renderCategoryButtons = () => {
    return (
      categoriesData?.subCategoryMap[categoryId]?.length > 0 &&
      categoriesData?.subCategoryMap[categoryId]?.map((category) => (
        <Grid container spacing={2} key={category.id} justifyContent="flex-start">
          <Grid item xs={12 / 7} sm={2}>
            <Button
              variant="text"
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                padding: 2,
              }}
              aria-label={category.name}
            >
              <img
                src={category.icon || "https://via.placeholder.com/40"}
                alt={category.name}
                style={{
                  marginBottom: "5px",
                  borderRadius: "50%",
                  width: "60px",
                  height: "60px",
                }}
              />
              {category.name}
            </Button>
          </Grid>
          {category.subcategories && (
            <Grid container spacing={2} justifyContent="center">
              {renderSubcategoryButtons(category.subcategories)}
            </Grid>
          )}
        </Grid>
      ))
    );
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        "& > *": {
          m: 1,
        },
      }}
    >
      {renderCategoryButtons()}
    </Box>
  );
};

export default CategoryList;
