import {
  Box,
  InputBase,
  List,
  ListItem,
  ListItemText,
  Paper,
  Popper,
  styled,
  Typography,
} from "@mui/material";

// Icons
import dropIcon from "../../assets/images/CategoryImages/arrow-down.png";

const DropDown = styled(Box)`
  width: 100%;
  max-width: 644px;
  height: 40px;
  flex-shrink: 0;
  border-bottom: 1px solid #d8d8df;
  background: #f7f7f8;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 16px;
  cursor: pointer;
`;

const SelectCategory = styled(Typography)`
  color: #707187;
  font-family: "Work Sans";
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  padding-left: 16px;
`;

const PopperContainer = styled(Paper)`
  width: 100%;
  max-width: 644px;
  padding: 8px;
`;

const SearchBar = styled(InputBase)`
  width: 100%;
  padding: 8px;
  border-bottom: 1px solid #d8d8df;
`;

function DropdownSelect({
  selectedSubCategories,
  setSearch,
  search,
  anchorEl,
  subcategories,
  handleSelect,
  handleClick,
}) {
  const open = Boolean(anchorEl);
  const id = open ? "simple-popper" : undefined;
  return (
    <div>
      <DropDown onClick={handleClick}>
        <SelectCategory>
          {selectedSubCategories
            .map((category) => category.name)
            .join(" >> ") || "Select Category"}
        </SelectCategory>
        <img
          src={dropIcon}
          alt="drop icon"
          style={{ width: "24px", height: "24px" }}
        />
      </DropDown>
      <Popper id={id} open={open} anchorEl={anchorEl} placement="bottom-start">
        <PopperContainer>
          <SearchBar
            placeholder="Search..."
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
          <Typography variant="h6" component="div" sx={{ mt: 2, mb: 1 }}>
            Select Category
          </Typography>
          <List>
            {subcategories.length > 0 ? (
              subcategories
                .filter((option) =>
                  option.name.toLowerCase().includes(search.toLowerCase())
                )
                .map((option, index) => (
                  <ListItem
                    button
                    key={index}
                    onClick={() => handleSelect(option)}
                  >
                    <ListItemText primary={option.name} />
                  </ListItem>
                ))
            ) : (
              <ListItem>
                <ListItemText primary="No more subcategories available." />
              </ListItem>
            )}
          </List>
        </PopperContainer>
      </Popper>
    </div>
  );
}

export default DropdownSelect;
