import { createSelector } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";

//Base Selectors
const authSelector = (state) => state.authReducer;
const categorySelector = (state) => state.categoryReducer;

// Auth Slectors

export const isAuthenticatedSelector = createSelector(
  authSelector,
  (authData) => authData?.isAuthenticated
);

export const currentUserDataSelector = createSelector(
  authSelector,
  (authData) => authData?.currentUserData
);



const useAppSelectors = () => {
  // Profile selectors
  const isAuthenticated = useSelector(isAuthenticatedSelector);
  const currentUserData = useSelector(currentUserDataSelector);
 
  return {
    isAuthenticated,
    currentUserData,
  };
};

export default useAppSelectors;
