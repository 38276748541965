import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
} from "firebase/auth";
import { auth } from "../config/firebase.config";

export function formatString(str) {
  return str
    .split("_") // Split the string at underscores
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize the first letter of each word
    .join(" "); // Join the words with spaces
}

export const formatPreviewData = (data = {}) => {
  return Object.keys(data).reduce((acc, key) => {
    const field = data[key];
    const { label, value } = field;

    // Case 1: If value is an object with year and month (like age)
    // if (value && typeof value === "object" && value.year !== undefined) {
    //   const year = value.year ? `${value.year} year` : "";
    //   const month = value.month ? `${value.month} month` : "";
    //   const formattedValue = [year, month].filter(Boolean).join(" ");
    //   acc[key] = { label: label, value: formattedValue };
    // }
    // Case 2: Array with objects containing displayData
     if (Array.isArray(value)) {
      if (value.length > 0 && value[0]?.displayData) {
        const formattedValue = value
          .map((item) => item.displayData || item.value || "")
          .join(" | ");
        acc[key] = { label: label, value: formattedValue };
      }
      // Case 3: Array of strings
      else if (typeof value[0] === "string") {
        acc[key] = { label: label, value: value.join(" | ") };
      }
      // Case 4: Array of objects, likely image case
      else if (value.length > 0 && value[0]?.value === undefined) {
        acc[key] = { label: label, value: value };
      }
    }
    // Case 5: Regular field with a simple value
    else if (typeof value === "boolean") {
      acc[key] = {
        label: label,
        value: field?.displayData ? field?.displayData : value ? "Yes" : "No",
      };
    } else if (typeof value === "string" || typeof value === "number") {
      acc[key] = {
        label: label,
        value: field?.displayData ? field?.displayData : value,
      };
    }

    return acc;
  }, {});
};

// FireBase funs()

// Sign up a new user
export const FireBaseSignUp = async (email, password) => {
  await createUserWithEmailAndPassword(auth, email, password);
};

// Log in an existing user
export const FireBaseLogIn = async (email, password) => {
  await signInWithEmailAndPassword(auth, email, password);
};
