import React, { useState, useEffect } from "react";
import Header from "../../../components/Header/Header";
import Sidebar from "../../../components/Sidebar/Sidebar";
import {
  Box,
  Button,
  Chip,
  Divider,
  Grid,
  Paper,
  Stack,
  Typography,
  colors,
  styled,
} from "@mui/material";
import AdsCard from "../../../components/AdsCard/AdsCard";
import { useDispatch, useSelector } from "react-redux";
import { fetchAds } from "../../../store/myads/myadsSlice";
import location from "../../../assets/images/ChatRoom/locationChat.svg";
import ChatSideBar from "../../../components/ChatSideBar/ChatSideBar";

const dotStyle = {
  alignSelf: "center",
  width: "4px",
  height: "4px",
  borderRadius: "2px",
  backgroundColor: "#707187",
};

const state_zip_dist = {
  color: "#707187",
  fontFamily: "Work Sans",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "18px",
};

const chipOnline = {
  backgroundColor: "#17D017",
  color: "white",
  height: "20px",
  marginLeft: "5px",
};

const DividerChip = {
    width: "150px",
};

const divider = {
  marginTop: "15px",
};

const ChatRoom = () => {
  const dispatch = useDispatch();
  const [adType, setAdType] = useState(0);
  const adsList = useSelector(
    (state) => state?.myadsReducer?.data?.data?.result
  );

  const adsCount = Array.isArray(adsList) ? adsList.length : 0;

  useEffect(() => {
    dispatch(fetchAds(adType));
  }, [dispatch, adType]);

  const handleTypeChange = (selectedType) => {
    setAdType(selectedType);
  };

  return (
    <>
      <Header/>
      <Box sx={{ display: "flex" }} mt={8.4}>
        <Grid container>
          <Grid
            item
            xs={12}
            overflow="hidden"
            margin={2}
            border="1px solid #D8D8DF"
            borderRadius={2}
          >
            <Box bgcolor="white" gap={12}>
              <Box
                display="flex"
                position="relative"
                justifyContent="space-between"
              >
                <Box paddingLeft={3} paddingTop={4}>
                  <Typography
                    variant="h5"
                    fontFamily="Work Sans"
                    fontSize="20px"
                    fontWeight="600"
                    lineHeight="28px"
                  >
                    Chats ({adsCount})
                  </Typography>
                </Box>
                <Stack
                  direction="row"
                  spacing={2}
                  paddingTop={4}
                  marginRight={4}
                  paddingBottom={3}
                >
                  <Button variant="outlined">All</Button>
                  <Button
                    variant={adType === 0 ? "contained" : "outlined"}
                    sx={{
                      backgroundColor: adType === 0 ? "#2196F3" : "#fff",
                      color: adType === 0 ? "#fff" : "#2196F3",
                      boxShadow: "none",
                    }}
                    onClick={() => handleTypeChange(0)}
                  >
                    Selling
                  </Button>
                  <Button
                    variant={adType === 1 ? "contained" : "outlined"}
                    sx={{
                      backgroundColor: adType === 1 ? "#2196F3" : "#fff",
                      color: adType === 1 ? "#fff" : "#2196F3",
                    }}
                    onClick={() => handleTypeChange(1)}
                  >
                    Buying
                  </Button>
                </Stack>
              </Box>
              <Divider/>
              <Grid container>
                <Grid item xs={3} borderRight="1px solid #D8D8DF">
                  <ChatSideBar/>
                </Grid>
                <Grid item xs={9}>
                  <Box display="flex" mt={1}>
                    <Typography
                      fontFamily="Work Sans"
                      fontSize="16px"
                      fontWeight="600"
                      lineHeight="22px"
                      ml={1}
                    >
                      User Name
                    </Typography>
                    <Chip
                      label="Online"
                      variant="contained"
                      sx={chipOnline}
                      size="small"
                    />
                  </Box>
                  {/* State pin and village or district */}
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginLeft: "5px",
                    }}
                  >
                    {/* STate pin dist */}
                    <Box sx={{ display: "flex", gap: "8px" }}>
                      {/* Image and state */}
                      <Box
                        sx={{ display: "flex", gap: "4px", marginRight: "8px" }}
                      >
                        <img src={location} alt="location icon" />
                        <Typography sx={state_zip_dist}>State</Typography>
                      </Box>

                      {/* Dot */}
                      <div style={dotStyle} />

                      <Typography sx={state_zip_dist}>1010</Typography>

                      {/* Dot */}
                      <div style={dotStyle} />

                      <Typography sx={state_zip_dist}>
                        Village or district
                      </Typography>
                    </Box>
                  </Box>
                  <Divider sx={divider}/>
                  <Divider sx={divider}>
                    <Chip label="15 May" sx={DividerChip} />
                  </Divider>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default ChatRoom;
