 import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { getCookie } from "../../utils/cookie";

const FOLLOW_LIST = `${process.env.REACT_APP_BASE_URL}/follow`; 
const getToken = () => getCookie(process.env.REACT_APP_LOGIN_TOKEN);


const initialState = {
  data: [],
  status: null,
  message: null,
  loading: false,
};

const FollowListSlice = createSlice({
  name: "FollowList",
  initialState,
  reducers: {
    followListStart: (state) => {
      state.loading = true;
      state.status = null;
      state.message = null;
    },
    followListSuccess: (state, action) => {
      state.data = action.payload.data;
      state.status = action.payload.status;
      state.message = action.payload.message;
      state.loading = false;
    },
    followListFailure: (state, action) => {
      state.status = action.payload.status;
      state.message = action.payload.message;
      state.loading = false;
    },
    followUserSuccess: (state, action) => {
      const userId = action.payload;
       state.data = state.data.map((user) =>
         user.id === userId ? { ...user, isFollowed: true } : user
       );
      state.message = "Followed successfully";
      state.status = true;
      state.loading = false;
    },
    followUserFailure: (state, action) => {
      state.message = action.payload || "Follow action failed";
      state.status = true;
      state.loading = false;
    },
    unfollowUserSuccess: (state, action) => {
      const userId = action.payload;
    
      state.data = state.data.filter((user) => user.id !== userId);
      state.message = "Unfollowed successfully";
      state.status = true;
      state.loading = false;
    },
    unfollowUserFailure: (state, action) => {
      state.status = false;
      state.message = action.payload;
      state.loading = false;
    },
  },
});

export const {
  followListStart,
  followListSuccess,
  followListFailure,
  followUserSuccess,
  followUserFailure,
  unfollowUserSuccess,
  unfollowUserFailure,
} = FollowListSlice.actions;

export const fetchFollowList = () => async (dispatch) => {
  const token = getToken();

  if (!token) {
    dispatch(
      followListFailure({
        status: false,
        message: "Authorization token is missing",
      })
    );
    return;
  }

  dispatch(followListStart());

  try {
    const response = await axios.get(FOLLOW_LIST, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (response.status === 200 && response.data.status) {
      dispatch(
        followListSuccess({
          status: response.data.status,
          message: response.data.message,
          data: response.data.data,
        })
      );
    } else {
      dispatch(
        followListFailure({
          status: false,
          message: response.data.message || "Failed to fetch data",
        })
      );
    }
  } catch (error) {
    dispatch(
      followListFailure({
        status: false,
        message: error.response?.data?.message || error.message,
      })
    );
  }
};

export const followUser = (userId) => async (dispatch) => {
  const token = getToken();
  

  if (!token) {
    dispatch(followUserFailure("Authorization token is missing"));
    return;
  }

  dispatch(followListStart());

  try {
    const response = await axios.post(
      FOLLOW_LIST,
      { id: userId },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );

    if (response.status === 200 && response.data.status) {
      dispatch(followUserSuccess(userId));
    } else {
      dispatch(
        followUserFailure(response.data.message || "Failed to follow user")
      );
    }
  } catch (error) {
    dispatch(followUserFailure(error.response?.data?.message || error.message));
  }
};


export const unfollowUser = (userId) => async (dispatch) => {
   const token = getToken();


  if (!token) {
    dispatch(unfollowUserFailure("Authorization token is missing"));
    return;
  }

  dispatch(followListStart());

  try {
    const response = await axios.post(
      FOLLOW_LIST,
      { id: userId },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );

    if (response.status === 200 && response.data.status) {
      dispatch(unfollowUserSuccess(userId));
    } else {
      dispatch(
        unfollowUserFailure(response.data.message || "Failed to unfollow")
      );
    }
  } catch (error) {
    dispatch(
      unfollowUserFailure(error.response?.data?.message || error.message)
    );
  }
};


export default FollowListSlice.reducer;
