import React, { useState, useEffect } from "react";

// css
import "./Banner.css";

// Icons
import left from "../../assets/images/CategoryImages/arrow-down.png";
import AdvertisementDialogBox from "../AdvertisementDialogBox/AdvertisementDialogBox";

const Banner = ({ data }) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [selectedAd, setSelectedAd] = React.useState(null);

  useEffect(() => {
    const interval = setInterval(() => {
      nextSlide();
    }, 3000);
    return () => clearInterval(interval);
    // eslint-disable-next-line
  }, [currentSlide]);

  const nextSlide = () => {
    setCurrentSlide((currentSlide + 1) % data.length);
  };

  const prevSlide = () => {
    setCurrentSlide((currentSlide - 1 + data.length) % data.length);
  };

  const handleSlideClick = (ad) => {
    setSelectedAd(ad);
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
    setSelectedAd(null);
  };

 const handleContinue = () => {
   const currentAd = data[currentSlide];
   if (currentAd?.url) {
     window.open(currentAd.url, "_blank", "noopener,noreferrer");
   } else {
     alert("No link found for this advertisement.");
   }
   handleCloseDialog();
 };


  return (
    <>
      <div className="banner-slider">
        <div className="slider-container">
          {data.map((data, index) => (
            <div
              key={index}
              className={`slide ${index === currentSlide ? "active" : ""}`}
              onClick={handleSlideClick}
            >
              <img src={data?.image} alt={data?.name} className="slide-image" />
            </div>
          ))}
          <button className="prev" onClick={prevSlide}>
            <div>
              <img src={left} alt="left dropdown" />
            </div>
          </button>
          <button className="next" onClick={nextSlide}>
            <div>
              <img src={left} alt="left dropdown" />
            </div>
          </button>
        </div>
      </div>
      {dialogOpen && (
        <AdvertisementDialogBox
          adId={selectedAd?.id}
          onClose={handleCloseDialog}
          onContinue={handleContinue}
        />
      )}
    </>
  );
};

export default Banner;
