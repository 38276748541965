import React, { useEffect, useState } from "react";
import Header from "../../../components/Header/Header";
import Sidebar from "../../../components/Sidebar/Sidebar";
import { Box, Grid, Paper, Typography, styled } from "@mui/material";
import FavouriteCard from "../../../components/FavouriteCard/FavouriteCard";
import { fetchFavouriteList } from "../../../store/favourite/favouriteSlice";
import { useDispatch, useSelector } from "react-redux";


const Favourite = () => {
  const dispatch = useDispatch();
  const favouriteList = useSelector((state) => state?.favouriteReducer?.data);
 

  const [products, setProducts] = useState([]);

  useEffect(() => {
    dispatch(fetchFavouriteList());
  }, []);

  React.useEffect(() => {
    setProducts(favouriteList);
  }, [favouriteList]);


  return (
    <>
      <Header />
      <Box sx={{ display: "flex" }}>
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <Sidebar />
          </Grid>
          <Grid item xs={8.9} overflow="hidden" marginTop={10.4}>
            <Box bgcolor="white" gap={12} sx={{ height: "100%" }}>
              <Typography
                variant="h5"
                fontFamily="Work Sans"
                fontSize="20px"
                fontWeight="600"
                fontStyle="normal"
                lineHeight="28px"
                paddingLeft={3}
                paddingTop={4}
              >
                Favourite (
                {Array.isArray(favouriteList) ? favouriteList.length : 0})
              </Typography>
              <Typography
                variant="subtitle2"
                color="#707187"
                fontFamily="Work Sans"
                fontSize="16px"
                fontStyle="normal"
                fontWeight="500"
                lineHeight="22px"
                marginTop={2}
                marginLeft={3}
              >
                Please fill in the following details to create your account.
              </Typography>

              <FavouriteCard products={favouriteList || []} />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Favourite;
