import { configureStore, combineReducers } from "@reduxjs/toolkit";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist-indexeddb-storage";
import { encryptTransform } from "../utils/encryption";

// Import your reducers
import AuthReducer from "../store/auth/authSlice";
import BannerReducer from "./helper/banner";
import CategoryReducer from "./helper/categorySlice";
import TemplateReducer from "./template/templateSlice";
import FavouriteReducer from "../store/favourite/favouriteSlice";
import ProfileReducer from "../store/profile/profileSlice";
import FaqReducer from "../store/faq/faqSlice";
import ProductReducer from "./product/productSlice";
import FollowReducer from "./follow/followSlice";
import MyadsReducer from "./myads/myadsSlice";

const appReducer = combineReducers({
  authReducer: AuthReducer,
  profileReducer: ProfileReducer,
  followReducer: FollowReducer,
  myadsReducer: MyadsReducer,
  favouriteReducer: FavouriteReducer,
  faqReducer: FaqReducer,
  bannerReducer: BannerReducer,
  categoryReducer: CategoryReducer,
  templateReducer: TemplateReducer,
  productReducer: ProductReducer,
});

const rootReducer = (state, action) => {
  return appReducer(state, action);
};

const persistStorage = storage("MyAppStorage");

const persistConfig = {
  key: "root",
  storage: persistStorage,
  transforms: [encryptTransform],
  timeout: 5000,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

// handling middleware
const errorHandlingMiddleware = (store) => (next) => (action) => {
  try {
    return next(action);
  } catch (error) {
    console.error("Middleware Error:", error);
    return next(action);
  }
};

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [
          FLUSH,
          REHYDRATE,
          PAUSE,
          PERSIST,
          PURGE,
          REGISTER,
          "ProductList/saveLocalPreviewProduct",
        ],
      },
    }).concat(errorHandlingMiddleware),
  devTools: process.env.NODE_ENV !== "production",
});

export const persistor = persistStore(store);

// storage quota exceeded
window.addEventListener("unhandledrejection", (event) => {
  if (event.reason?.name === "QuotaExceededError") {
    console.error("Storage quota exceeded");
    // quota exceeded error
    store.dispatch({
      type: "STORAGE_ERROR",
      payload: "Storage quota exceeded",
    });
  }
});

export default store;
