import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  FormControlLabel,
  Checkbox,
  Typography,
  Box,
  Switch,
  InputAdornment,
} from "@mui/material";
import { Field } from "formik";

const ComplexSelectPopup = ({
  open,
  onClose,
  parentField,
  childrenFields,
  onSave,
  childFieldValues,
}) => {
  const [values, setValues] = useState({});
  const [checkbox_TextinputValues, setCheckbox_TextinputValues] = useState({});

  const transformArrayToObject = (data) => {
    if (Array.isArray(data)) {
      return data.reduce((acc, item) => {
        acc[item.name] = item.value;
        return acc;
      }, {});
    }
    if (typeof data === "string") {
      return data; // Return the string as is
    }
    return data || {}; // If it's already an object, return it as is
  };

  useEffect(() => {
    if (open) {
      const transformedValues = childFieldValues
        ? transformArrayToObject(childFieldValues)
        : {};
      setValues(transformedValues);
      setCheckbox_TextinputValues(childFieldValues?.checkbox_Textinput || {});
    }
  }, [open, childFieldValues]);

  // Set prefilled values when the component mounts or when `open` changes
  // useEffect(() => {
  //   if (open) {
  //     setValues(childFieldValues || {});
  //     setCheckbox_TextinputValues(childFieldValues?.checkbox_Textinput || {});
  //   }
  // }, [open, childFieldValues]);

  const handleChange = (childField, value, checkbox_TextinputType = null) => {
    const { type, name, checkboxTextinputProps } = childField;

    if (type === "checkbox_Textinput") {
      const checkboxKey = checkboxTextinputProps?.checkboxLabel;

      if (checkbox_TextinputType === "checkbox") {
        setCheckbox_TextinputValues((prev) => {
          const updated = { ...prev };
          if (value) {
            updated[checkboxKey] = prev[checkboxKey] || ""; // Add with default value if checked
          } else {
            const { [checkboxKey]: _, ...rest } = updated; // Remove key immutably
            return rest;
          }
          return updated;
        });
      } else if (checkbox_TextinputType === "textinput") {
        setCheckbox_TextinputValues((prev) => ({
          ...prev,
          [checkboxKey]: value, // Update the text input value for the checked box
        }));
      }
    } else {
      if (parentField.props.multiple) {
        setValues((prev) => ({
          ...prev,
          [name]: value,
        }));
      } else {
        setValues({
          [name]: value,
        });
      }
    }
  };

  const handleSave = () => {
    const formatWithAffixes = (value, prefix = "", suffix = "", label = "") => {
      const prefixStr = prefix ? `${prefix} ` : "";
      const suffixStr = suffix ? ` ${suffix}` : "";
      const labelStr = label ? `${label} ` : "";
      return `${labelStr}${prefixStr}${value || ""}${suffixStr}`.trim();
    };

    const formattedValues = parentField.props.multiple
      ? childrenFields
          .flatMap((rowFields) =>
            rowFields.map((childField) => {
              const {
                type,
                name,
                label,
                prefix,
                suffix,
                checkboxTextinputProps,
                showLabelWithValue,
              } = childField;

              if (showLabelWithValue) {
                if (type === "checkbox_Textinput") {
                  const checkboxKey = checkboxTextinputProps?.checkboxLabel;
                  if (checkbox_TextinputValues.hasOwnProperty(checkboxKey)) {
                    return {
                      label: checkboxKey,
                      name: checkboxTextinputProps?.checkboxName,
                      value: checkbox_TextinputValues[checkboxKey] || "",
                      displayData:
                        typeof values[name] === "boolean"
                          ? label
                          : formatWithAffixes(
                              checkbox_TextinputValues[checkboxKey],
                              prefix,
                              suffix,
                              checkboxKey
                            ),
                    };
                  }
                  return null; // Exclude unchecked checkboxes
                } else {
                  if (
                    (type === "checkbox" && values[name] !== true) ||
                    !values[name]
                  ) {
                    return null; // Exclude checkboxes whose value is not true
                  }
                  return {
                    label,
                    name,
                    value: values[name] || "",
                    displayData:
                      typeof values[name] === "boolean"
                        ? label
                        : formatWithAffixes(
                            values[name],
                            prefix,
                            suffix,
                            label
                          ),
                  };
                }
              } else {
                if (
                  (type === "checkbox" && values[name] !== true) ||
                  values[name] === undefined
                ) {
                  return null; // Exclude checkboxes whose value is not true
                }
                return {
                  label,
                  name,
                  value: values[name] || "",
                  displayData:
                    typeof values[name] === "boolean"
                      ? label
                      : formatWithAffixes(values[name], prefix, suffix),
                };
              }
            })
          )
          .filter(Boolean) // Remove null entries
      : (() => {
          // Handle single-value case (not multiple)
          const keyToFind = Object.keys(values)[0]; // E.g., "from_date"
          const foundField = childrenFields
            .flat()
            .find((field) => field.name === keyToFind);

          if (!foundField) return [];

          const { prefix, suffix, label } = foundField;
          return [
            {
              label: foundField.label || "",
              name: keyToFind,
              value: values[keyToFind] || "",
              displayData: formatWithAffixes(values[keyToFind], prefix, suffix),
            },
          ];
        })();

    onSave(formattedValues);
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Select Value</DialogTitle>
      <DialogContent>
        {childrenFields.map((rowFields, rowIndex) => (
          <Box display="flex" gap="1rem" key={`row-${rowIndex}`}>
            {rowFields.map((childField) => {
              return (
                // Ensure you return the JSX here
                <Box mb="1rem" width={"100%"} key={childField.name}>
                  {(() => {
                    switch (childField.type) {
                      case "text":
                      case "number":
                        return (
                          <Box width={"100%"} mb={"0.85rem"}>
                            <TextField
                              type={childField.type}
                              variant="standard"
                              label={childField.label}
                              name={childField.name}
                              value={values[childField.name] || ""}
                              placeholder={childField.placeholder}
                              onChange={(e) =>
                                handleChange(childField, e.target.value)
                              }
                              fullWidth
                              margin="normal"
                              InputProps={{
                                startAdornment: childField?.prefix ? (
                                  <InputAdornment position="start">
                                    {childField?.prefix}
                                  </InputAdornment>
                                ) : null,
                                endAdornment: childField?.suffix ? (
                                  <InputAdornment position="end">
                                    {childField?.suffix}
                                  </InputAdornment>
                                ) : null,
                              }}
                            />
                          </Box>
                        );

                      case "textarea":
                        return (
                          <Box
                            width={"100%"}
                            key={childField.name}
                            mb={"0.85rem"}
                          >
                            <Field
                              as={TextField}
                              fullWidth
                              multiline
                              rows={4}
                              label={childField.label}
                              name={childField.name}
                              value={values[childField.name] || ""}
                              placeholder={childField.placeholder}
                              onChange={(e) =>
                                handleChange(childField, e.target.value)
                              }
                              // helperText={
                              //   <ErrorMessage
                              //     name={name}
                              //     component="div"
                              //     style={{ color: "red" }}
                              //   />
                              // }
                            />
                          </Box>
                        );

                      case "checkbox":
                        return (
                          <Box width={"100%"} mb={"0.85rem"}>
                            {childField.viewType === "switch" ? (
                              <FormControlLabel
                                control={
                                  <Switch
                                    name={childField.name}
                                    checked={values[childField.name] || false}
                                    onChange={(e) =>
                                      handleChange(childField, e.target.checked)
                                    }
                                  />
                                }
                                label={childField.label}
                                labelPlacement="start"
                                sx={{
                                  width: "100%",
                                  justifyContent: "space-between", // Aligns label and switch neatly
                                  margin: 0, // Optional to remove default margin
                                }}
                              />
                            ) : (
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    name={childField.name}
                                    checked={values[childField.name] || false}
                                    onChange={(e) =>
                                      handleChange(childField, e.target.checked)
                                    }
                                  />
                                }
                                label={childField.label}
                              />
                            )}
                          </Box>
                        );

                      case "date":
                        const minDate = childField.pastDate
                          ? "1900-01-01"
                          : new Date().toISOString().split("T")[0];
                        const maxDate = childField.futureDate
                          ? "2100-12-31"
                          : new Date().toISOString().split("T")[0];
                        const dateValue =
                          typeof values === "object" &&
                          childField.name in values
                            ? values[childField.name]
                            : values || "";
                        return (
                          <Box width={"100%"} mb={"0.85rem"}>
                            <TextField
                              variant="standard"
                              label={childField.label}
                              type="date"
                              name={childField.name}
                              value={dateValue}
                              onChange={(e) =>
                                handleChange(childField, e.target.value)
                              }
                              fullWidth
                              margin="normal"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              inputProps={{
                                min: minDate,
                                max: maxDate,
                              }}
                            />
                          </Box>
                        );

                      case "plaintext":
                        return (
                          <Box width={"100%"} mb={"0.85rem"} padding="0.5rem 0">
                            <Typography
                              sx={{ cursor: "pointer" }}
                              variant="body1"
                              onClick={() =>
                                handleChange(childField, childField.label)
                              }
                            >
                              {childField.label}
                            </Typography>
                          </Box>
                        );
                      case "checkbox_Textinput":
                        return (
                          <Box
                            width={"100%"}
                            mb={"0.85rem"}
                            display="flex"
                            alignItems="center"
                            gap="0.4rem"
                          >
                            <Box width="50%">
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    name={
                                      childField.checkboxTextinputProps
                                        ?.checkboxName
                                    }
                                    checked={checkbox_TextinputValues.hasOwnProperty(
                                      childField.checkboxTextinputProps
                                        ?.checkboxLabel
                                    )}
                                    onChange={(e) =>
                                      handleChange(
                                        childField,
                                        e.target.checked,
                                        "checkbox"
                                      )
                                    }
                                  />
                                }
                                label={
                                  childField.checkboxTextinputProps
                                    ?.checkboxLabel
                                }
                              />
                            </Box>
                            <Box width="50%">
                              <TextField
                                variant="standard"
                                label={
                                  childField.checkboxTextinputProps
                                    ?.textInputPlaceholder
                                }
                                disabled={
                                  !checkbox_TextinputValues.hasOwnProperty(
                                    childField.checkboxTextinputProps
                                      ?.checkboxLabel
                                  )
                                }
                                name={
                                  childField.checkboxTextinputProps
                                    ?.textInputName
                                }
                                value={
                                  checkbox_TextinputValues[
                                    childField.checkboxTextinputProps
                                      ?.checkboxLabel
                                  ] || ""
                                }
                                onChange={(e) =>
                                  handleChange(
                                    childField,
                                    e.target.value,
                                    "textinput"
                                  )
                                }
                                fullWidth
                                margin="normal"
                                InputProps={{
                                  startAdornment: childField?.prefix ? (
                                    <InputAdornment position="start">
                                      {childField?.prefix}
                                    </InputAdornment>
                                  ) : null,
                                  endAdornment: childField?.suffix ? (
                                    <InputAdornment position="end">
                                      {childField?.suffix}
                                    </InputAdornment>
                                  ) : null,
                                }}
                              />
                            </Box>
                          </Box>
                        );

                      default:
                        return null;
                    }
                  })()}
                </Box>
              );
            })}
          </Box>
        ))}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Cancel
        </Button>
        <Button onClick={handleSave} variant="contained" color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ComplexSelectPopup;
