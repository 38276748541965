import React, { useCallback, useEffect, useState, useRef } from "react";
import styles from "./styles.module.css";

const useDoubleTap = (onDoubleTap, delay = 300) => {
  const lastTap = useRef(0);

  return useCallback(() => {
    const now = Date.now();
    if (now - lastTap.current < delay) {
      onDoubleTap();
    }
    lastTap.current = now;
  }, [onDoubleTap, delay]);
};

const EnhancedImageViewer = (props) => {
  const [currentIndex, setCurrentIndex] = useState(props.currentIndex ?? 0);
  const [scale, setScale] = useState(1);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [isDragging, setIsDragging] = useState(false);
  const [dragStart, setDragStart] = useState({ x: 0, y: 0 });
  const imageRef = useRef(null);

  const resetZoom = useCallback(() => {
    setScale(1);
    setPosition({ x: 0, y: 0 });
  }, []);

  const changeImage = useCallback(
    (delta) => {
      resetZoom();
      let nextIndex = (currentIndex + delta) % props.src.length;
      if (nextIndex < 0) nextIndex = props.src.length - 1;
      setCurrentIndex(nextIndex);
    },
    [currentIndex, props.src.length, resetZoom]
  );

  const handleDoubleTap = useCallback(() => {
    setScale(scale === 1 ? 2 : 1);
    setPosition({ x: 0, y: 0 });
  }, [scale]);

  const handleTap = useDoubleTap(handleDoubleTap);

  const handleMouseDown = useCallback((e) => {
    if (scale > 1) {
      setIsDragging(true);
      setDragStart({
        x: e.clientX - position.x,
        y: e.clientY - position.y
      });
    }
  }, [scale, position]);

  const handleMouseMove = useCallback((e) => {
    if (isDragging && scale > 1) {
      const newX = e.clientX - dragStart.x;
      const newY = e.clientY - dragStart.y;
      
      // Calculate bounds
      const maxX = (scale - 1) * (imageRef.current?.width || 0) / 2;
      const maxY = (scale - 1) * (imageRef.current?.height || 0) / 2;
      
      setPosition({
        x: Math.max(-maxX, Math.min(maxX, newX)),
        y: Math.max(-maxY, Math.min(maxY, newY))
      });
    }
  }, [isDragging, scale, dragStart]);

  const handleMouseUp = useCallback(() => {
    setIsDragging(false);
  }, []);

  const handleWheel = useCallback((e) => {
    if (e.ctrlKey) {
      e.preventDefault();
      const delta = e.deltaY > 0 ? -0.2 : 0.2;
      setScale(prevScale => Math.max(1, Math.min(3, prevScale + delta)));
    } else if (!props.disableScroll) {
      if (e.deltaY > 0) {
        changeImage(1);
      } else {
        changeImage(-1);
      }
    }
  }, [changeImage, props.disableScroll]);

  const handleClick = useCallback(
    (event) => {
      if (!event.target || !props.closeOnClickOutside) return;
      
      const target = event.target;
      if (
        target.id === 'EnhancedImageViewer' ||
        target.classList.contains('enhanced-image-viewer__slide')
      ) {
        event.stopPropagation();
        props.onClose?.();
      }
    },
    [props.closeOnClickOutside, props.onClose]
  );

  const handleKeyDown = useCallback(
    (event) => {
      if (event.key === "Escape") {
        resetZoom();
        props.onClose?.();
      }
      if (["ArrowLeft", "h"].includes(event.key)) {
        changeImage(-1);
      }
      if (["ArrowRight", "l"].includes(event.key)) {
        changeImage(1);
      }
    },
    [props.onClose, changeImage, resetZoom]
  );

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    document.addEventListener("wheel", handleWheel, { passive: false });
    
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
      document.removeEventListener("wheel", handleWheel);
    };
  }, [handleKeyDown, handleWheel]);

  return (
    <div
      id="EnhancedImageViewer"
      className={`${styles.wrapper} react-simple-image-viewer__modal`}
      onClick={handleClick}
      style={props.backgroundStyle}
    >
      <span
        className={`${styles.close} react-simple-image-viewer__close`}
        onClick={() => {
          resetZoom();
          props.onClose?.();
        }}
      >
        {props.closeComponent || "×"}
      </span>
      
      {props.src.length > 1 && (
        <span
        className={`${styles.navigation} ${styles.prev} react-simple-image-viewer__previous`}
          onClick={() => changeImage(-1)}
        >
          {props.leftArrowComponent || "❮"}
        </span>
      )}
      
      {props.src.length > 1 && (
        <span
        className={`${styles.navigation} ${styles.next} react-simple-image-viewer__next`}
          onClick={() => changeImage(1)}
        >
          {props.rightArrowComponent || "❯"}
        </span>
      )}
      
      <div className={`${styles.content} react-simple-image-viewer__modal-content`}>
        <div
          className={`${styles.slide} react-simple-image-viewer__slide`}
          onMouseDown={handleMouseDown}
          onMouseMove={handleMouseMove}
          onMouseUp={handleMouseUp}
          onMouseLeave={handleMouseUp}
          onClick={handleTap}
        >
          <img
            ref={imageRef}
            className={styles.image}
            src={props.src[currentIndex]}
            alt=""
            style={{
              ...props.imageStyle,
              maxHeight: '100%',
              maxWidth: '100%',
              transform: `scale(${scale}) translate(${position.x}px, ${position.y}px)`,
              transition: isDragging ? 'none' : 'transform 0.3s ease-out',
              cursor: scale > 1 ? 'grab' : 'default',
            }}
            draggable={false}
          />
        </div>
      </div>
    </div>
  );
};


export default EnhancedImageViewer;