import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Divider,
  Box,
  Typography,
  Grid,
  Avatar,
  Stack,
} from "@mui/material";
import styled from "@emotion/styled";

// Components
import Header from "../../../components/Header/Header";


// Icon
import Store from "../../../assets/images/popularCategory/store.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getCategoryById } from "../../../store/helper/categorySlice";
import {
  localPreviewProduct,
  Productpublish,
} from "../../../store/product/productSlice";
import AdSubmissionForm from "../../../components/PostNewAds/AdSubmissionForm";
import { formatPreviewData } from "../../../utils/helper";

const Container = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 24px;
  background-color: #f5f5f5;
`;

const WhiteBox = styled(Box)`
  background-color: #fff;
  width: 100%;
  min-height: 100vh;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
`;

const Heading = styled(Typography)`
  padding: 7px 0px 5px 20px;
  color: #000;
  font-family: "Work Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
`;

const ButtonContainer = styled(Box)`
  margin-top: auto;
  display: flex;
  justify-content: flex-end;
  gap: 16px;
  margin-bottom: 24px;
  margin-right: 24px;
`;

const AdsCategoryText = styled(Typography)`
  color: #707187;
  font-family: "Work Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
`;

const Buttons = styled(Button)`
  border-radius: 4px;
  border: 0.5px solid #d8d8df;
  background: ${(props) => (props.selected ? "#00A6E8" : "#fff")};
  color: ${(props) => (props.selected ? "white" : "#707187")};
  &:hover {
    background: ${(props) => (props.selected ? "#00A6E8" : "#f0f0f0")};
    color: ${(props) => (props.selected ? "white" : "#707187")};
  }
`;

const AdsButtons = styled(Button)`
  border-radius: 4px;
  border: 0.5px solid #d8d8df;
  background: ${(props) => (props.adsselected ? "#00A6E8" : "#fff")};
  color: ${(props) => (props.adsselected ? "white" : "#707187")};
  &:hover {
    background: ${(props) => (props.adsselected ? "#00A6E8" : "#f0f0f0")};
    color: ${(props) => (props.adsselected ? "white" : "#707187")};
  }
`;

const PostNewAdd = () => {
  const location = useLocation();
  const [selectedAdsTypeButton, setSelectedAdsTypeButton] = useState(null);
  const [selectedMainCategory, setSelectedMainCategory] = useState(null);
  const [currentSelectedCategoryId, setCurrentSelectedCategoryId] =
    useState(null); // Track selected category ID
  const [selectedSubCategories, setSelectedSubCategories] = useState([]);
  const [showTemplate, setShowTemplate] = useState(false);
  const [btnDissabled, setBtnDissabled] = useState(true);
  const formHandleSubmitRef = useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const categoryData = useSelector((state) => state?.categoryReducer);
  const editAdsData = useSelector(
    (state) => state?.myadsReducer?.adsDetailsData
  );

  // Fetch subcategories on category button click
  const handleCategoryClick = (categoryData) => {
    setSelectedSubCategories([]);
    setShowTemplate(false);
    setSelectedMainCategory(categoryData);
    setCurrentSelectedCategoryId(categoryData?.id);
    dispatch(getCategoryById(categoryData?.id));
  };

  const handleNextClick = () => {
    if (formHandleSubmitRef.current) {
      // Trigger the hidden submit button
      formHandleSubmitRef.current.click();
    }
  };

  const handleFormSubmit = (formData, removedUploadedImageUrls) => {
    const previewData = formatPreviewData(formData);
    const publishData = convertToFormData(formData, removedUploadedImageUrls);
    dispatch(Productpublish(publishData));
    dispatch(localPreviewProduct(previewData));
    location.pathname.startsWith("/edit-post/")
      ? navigate("/edit-post/contact-information")
      : navigate("/post/contact-information");
  };
  function convertToFormData(inputData, removedUploadedImageUrls) {
    const formData = new FormData();

    // Handle the "image" field separately
    if (Array.isArray(inputData.image.value)) {
      inputData.image.value.forEach((image) => {
        formData.append("images", image);
      });
    }
    const data = {};
    Object.keys(inputData).forEach((key) => {
      if (key !== "image") {
        data[key] = inputData[key];
      }
    });
    const template_id = localStorage.getItem("template_id");
    const template_type = localStorage.getItem("templateType");
    const selected_categories = template_id.split("_");
    const removed_images = removedUploadedImageUrls;
    // Append the structured "data" to FormData
    if (location.pathname.startsWith("/edit-post/")) {
      formData.append(
        "data",
        JSON.stringify({
          details: data,
          removed_images
        })
      );
    } else {
      formData.append(
        "data",
        JSON.stringify({
          details: data,
          template_id,
          template_type,
          selected_categories,
        })
      );
    }

    return formData;
  }
  // useEffect(() => {
  //   if (location.pathname.startsWith("/edit-post/")) {
  //     // const templateId = editAdsData?.template_id?.split("_");
  //     // const mainCategory = categoryData?.mainCategorydata?.find(
  //     //   (item) => +templateId?.[0] === item?.id
  //     // );
  //     // setSelectedAdsTypeButton(editAdsData?.template_type);
  //     // setSelectedMainCategory(mainCategory);
  //     // dispatch(getCategoryById(mainCategory?.id));
  //   }
  // }, []);

  return (
    <>
      <Header />
      <Container mt={8.4}>
        <WhiteBox>
          <Heading>Basic Information</Heading>
          <Divider />

          {/* Ads Type and Select Category */}
          <Grid container spacing={2} sx={{ mt: "24px", ml: "32px" }}>
            {location.pathname.startsWith("/edit-post/") && (
              <Grid item>
                <Box width={"100%"} display="flex" gap="2rem">
                  <AdsCategoryText>
                    Ads Type :{" "}
                    {editAdsData?.template_type === 0
                      ? "I Want to Sell"
                      : "I am Looking for "}
                  </AdsCategoryText>
                  <AdsCategoryText>
                    Category : {editAdsData?.template_id}
                  </AdsCategoryText>
                </Box>
              </Grid>
            )}
            {!location.pathname.startsWith("/edit-post/") && (
              <>
                <Grid item xs={3}>
                  <AdsCategoryText>Ads Type*</AdsCategoryText>
                  <Box display="flex" gap="10px" mt="8px">
                    <AdsButtons
                      adsselected={selectedAdsTypeButton === 0}
                      onClick={() => {
                        setSelectedAdsTypeButton(0);
                        setBtnDissabled(false);
                        setSelectedSubCategories([]);
                        setShowTemplate(false);
                      }}
                    >
                      I Want to Sell
                    </AdsButtons>
                    <AdsButtons
                      adsselected={selectedAdsTypeButton === 1}
                      onClick={() => {
                        setSelectedAdsTypeButton(1);
                        setBtnDissabled(false);
                        setSelectedSubCategories([]);
                        setShowTemplate(false);
                      }}
                    >
                      I am Looking for
                    </AdsButtons>
                  </Box>
                </Grid>

                {/* Select Category */}
                <Grid item xs={9}>
                  <AdsCategoryText>Select Category</AdsCategoryText>
                  <Box display="flex" gap="6px" mt="8px">
                    {categoryData?.mainCategorydata?.map((item) => (
                      <Buttons
                        key={item?.id + item.name}
                        selected={selectedMainCategory?.id === item?.id}
                        onClick={() => handleCategoryClick(item)}
                        disabled={btnDissabled}
                        startIcon={
                          <Avatar
                            src={Store}
                            sx={{
                              height: "20px",
                              width: "20px",
                              marginRight: "-5px",
                            }}
                          />
                        }
                      >
                        {item?.name}
                      </Buttons>
                    ))}
                  </Box>
                </Grid>
              </>
            )}
          </Grid>

          {/* Contents show */}
          <Box sx={{ mt: "24px", ml: "50px", mr: "50px" }}>
            <Divider />
            {(selectedMainCategory?.id ||
              location.pathname.startsWith("/edit-post/")) && (
              <AdSubmissionForm
                currentSelectedCategoryId={currentSelectedCategoryId}
                setCurrentSelectedCategoryId={setCurrentSelectedCategoryId}
                selectedSubCategories={selectedSubCategories}
                setSelectedSubCategories={setSelectedSubCategories}
                selectedMainCategory={selectedMainCategory}
                selectedAdsTypeButton={selectedAdsTypeButton}
                setShowTemplate={setShowTemplate}
                showTemplate={showTemplate}
                formHandleSubmitRef={formHandleSubmitRef}
                handleFormSubmit={handleFormSubmit}
                handleNextClick={handleNextClick}
              />
            )}
          </Box>

          {/* Cancel and Next buttons */}
          <ButtonContainer>
            <Button variant="contained" color="secondary">
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleNextClick}
            >
              Next
            </Button>
          </ButtonContainer>
        </WhiteBox>
      </Container>
    </>
  );
};

export default PostNewAdd;