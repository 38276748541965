import React, { useState } from "react";

import { Box, Grid, Typography } from "@mui/material";
import CommonCards from "../common/CommonCards";
import { useDispatch } from "react-redux";
import {
  addFavourite,
  removeFavourite,
} from "../../store/favourite/favouriteSlice";
import { useNavigateAction } from "../../customHooks/useNavigateAction";

function FavouriteCard({ products }) {
  const dispatch = useDispatch();
  const handleProtectedAction = useNavigateAction();
  const [isFavourite, setIsFavourite] = useState([]);
 const [favouriteSet, setFavouriteSet] = useState(new Set()); 

 const handleToggleFavourite = (id, isFavourite, event) => {
   event.stopPropagation();
   handleProtectedAction(() => {
     if (isFavourite) {
       setFavouriteSet((prev) => {
         const updatedSet = new Set(prev);
         updatedSet.delete(id);
         return updatedSet;
       });
       dispatch(removeFavourite(id));
     } else {
       setFavouriteSet((prev) => new Set(prev).add(id));
       dispatch(addFavourite(id));
     }
   });
 };

  // const theme = useTheme();
  return (
    <>
      <Box sx={{ flexGrow: 1, p: 3 }}>
        <Grid container spacing={2}>
          {products && products.length > 0 ? (
            products.map((item, index) => (
              <Grid item xs={12} sm={6} md={6} key={index}>
                <CommonCards
                  key={item.id || index}
                  item={item}
                  // onAddFavourite={() => handleAddFavourite(item.id)}
                  // onRemoveFavourite={() => handleRemoveFavourite(item.id)}
                  isFavourite={isFavourite}
                  onToggleFavourite={(event) =>
                    handleToggleFavourite(item.id, isFavourite, event)
                  }
                />
              </Grid>
            ))
          ) : (
            <Box sx={{ position: "relative", top: 100, left: 420 }}>
              <Typography variant="h6">No Favourite found</Typography>
            </Box>
          )}
        </Grid>
      </Box>
    </>
  );
}

export default FavouriteCard;
