import React from "react";
import { Grid, Button, Avatar, Typography, Box } from "@mui/material";

// Images
import CycleImage from "../../assets/images/popularCategory/cycle.svg";
import MotorBike from "../../assets/images/popularCategory/bike.svg";
import Car from "../../assets/images/popularCategory/car.svg";
import Fish from "../../assets/images/popularCategory/fish.svg";
import House from "../../assets/images/popularCategory/house.svg";
import Sofa from "../../assets/images/popularCategory/sofa.svg";
import Table from "../../assets/images/popularCategory/table.svg";
import Boat from "../../assets/images/popularCategory/boat.svg";
import Book from "../../assets/images/popularCategory/book.svg";
import BabyCloth from "../../assets/images/popularCategory/baby.svg";
import Computer from "../../assets/images/popularCategory/computer.svg";
import Phone from "../../assets/images/popularCategory/phone.svg";

export function ImageWithTextButton({ image, text, onClick }) {
  return (
    <Button onClick={onClick} fullWidth>
      <Grid container direction="column" alignItems="center" spacing={1}>
        <Grid item>
          <Avatar src={image} alt="Icon" sx={{ width: 64, height: 64 }} />
        </Grid>
        <Grid item>
          <Typography
            sx={{
              color: "#26252C",
              textAlign: "center",
              fontFamily: "Work Sans",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: "500",
              lineHeight: "18px",
            }}
          >
            {text}
          </Typography>
        </Grid>
      </Grid>
    </Button>
  );
}

function PopularCategory() {
  // Handle for click icons
  const handleClick = () => {
  };

  return (
    <Box
      sx={{
        borderRadius: "4px",
        border: "1px solid#D8D8DF",
        background: "#FFF",
        marginTop: 2.4,
        paddingBottom: 3.2,
      }}
    >
      <Typography
        sx={{
          margin: 0,
          marginTop: 1.6,
          marginLeft: 2.4,
          marginBottom: 2.4,
          color: "#000",
          fontFamily: "Work Sans",
          fontSize: "20px",
          fontStyle: "normal",
          fontWeight: "600",
          lineHeight: "28px",
        }}
      >
        Popular Category
      </Typography>
      <Grid container justifyContent="center" spacing={3}>
        {/* bicycle */}
        <Grid item>
          <ImageWithTextButton
            image={CycleImage}
            text="Cycle"
            onClick={handleClick}
          />
        </Grid>

        {/* Motorbike */}
        <Grid item>
          <ImageWithTextButton
            image={MotorBike}
            text="MotorBike"
            onClick={handleClick}
          />
        </Grid>

        {/* Car */}
        <Grid item>
          <ImageWithTextButton image={Car} text="Car" onClick={handleClick} />
        </Grid>

        {/* Fish */}
        <Grid item>
          <ImageWithTextButton image={Fish} text="Fish" onClick={handleClick} />
        </Grid>

        {/* House */}
        <Grid item>
          <ImageWithTextButton
            image={House}
            text="House"
            onClick={handleClick}
          />
        </Grid>

        {/* Sofa */}
        <Grid item>
          <ImageWithTextButton image={Sofa} text="Sofa" onClick={handleClick} />
        </Grid>

        {/* Table */}
        <Grid item>
          <ImageWithTextButton
            image={Table}
            text="Table"
            onClick={handleClick}
          />
        </Grid>

        {/* Boat */}
        <Grid item>
          <ImageWithTextButton image={Boat} text="Boat" onClick={handleClick} />
        </Grid>

        {/* Book */}
        <Grid item>
          <ImageWithTextButton image={Book} text="Book" onClick={handleClick} />
        </Grid>

        {/* BabyCloth */}
        <Grid item>
          <ImageWithTextButton
            image={BabyCloth}
            text="BabyCloth"
            onClick={handleClick}
          />
        </Grid>

        {/* Computer */}
        <Grid item>
          <ImageWithTextButton
            image={Computer}
            text="Computer"
            onClick={handleClick}
          />
        </Grid>

        {/* Phone */}
        <Grid item>
          <ImageWithTextButton
            image={Phone}
            text="Phone"
            onClick={handleClick}
          />
        </Grid>
      </Grid>
    </Box>
  );
}

export default PopularCategory;
