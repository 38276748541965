import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { getCookie } from "../../utils/cookie";

const ADS_LIST = `${process.env.REACT_APP_BASE_URL}/my-ads`;

const initialState = {
  data: [],
  adsDetailsData: {},
  appAdsData: [],
  loading: false,
  message: null,
  error: null,
};

const MyadsSlice = createSlice({
  name: "ads",
  initialState,
  reducers: {
    fetchAppAdsSuccess(state, action) {
      state.appAdsData = action.payload;
    },
    fetchAdsStart(state) {
      state.loading = true;
      state.error = null;
      state.message = null;
    },
    adsDataById: (state, action) => {
      state.adsDetailsData = action.payload?.adsDetailsData;
    },
    fetchAdsSuccess(state, action) {
      state.loading = false;
      state.data = action.payload;
      state.message = "Ads fetched successfully";
    },
    fetchAdsFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
      state.message = action.payload;
    },
    reserveAdStart(state) {
      state.loading = true;
      state.error = null;
    },

    reserveAdSuccess(state, action) {
      state.loading = false;
      state.message = "Ad reserved successfully";
      const updatedAd = action.payload;
      state.data = state.data.map((ad) =>
        ad.id === updatedAd.id ? updatedAd : ad
      );
    },

    changeAdStatusSuccess(state, action) {
      state.loading = false;
      state.message = "Ad status updated successfully";
      if (state.data?.result) {
        state.data.result = state.data.result.map((ad) =>
          ad.id === action.payload.id ? { ...ad, status: !ad.status } : ad
        );
      }
    },
    deleteAdsSuccess(state, action) {
      state.loading = false;
      state.message = "Ad status updated successfully";
      if (state.data?.result) {
        const updateAdsData = state.data.result.filter(
          (ad) => ad.id !== action.payload.id
        );
        state.data.result = updateAdsData;
      }
    },
    reserveAdFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
      state.message = action.payload;
    },
  },
});

export const {
  fetchAdsStart,
  adsDataById,
  fetchAdsSuccess,
  fetchAppAdsSuccess,
  deleteAdsSuccess,
  fetchAdsFailure,
  changeAdStatusSuccess,
  reserveAdStart,
  reserveAdSuccess,
  reserveAdFailure,
} = MyadsSlice.actions;

// https://verkaufdev.semseosmo.com/api/v1/advertisements?page=homepage
export const fetchAppAds = (place) => async (dispatch) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/advertisements?page=homepage`
    );
    dispatch(fetchAppAdsSuccess(response?.data?.data));
    return response;
  } catch (error) {}
};

export const fetchAds = (type) => async (dispatch) => {
  const token = getCookie(process.env.REACT_APP_LOGIN_TOKEN);
  if (!token) {
    dispatch(
      fetchAdsFailure({
        status: true,
        message: "Authorization token is missing",
      })
    );
    return;
  }

  dispatch(fetchAdsStart());

  try {
    const response = await axios.get(`${ADS_LIST}?type=${type}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    dispatch(fetchAdsSuccess(response.data?.data));
  } catch (error) {
    dispatch(fetchAdsFailure(error.response?.data?.message || error.message));
  }
};

export const fetchAdsById = (id) => async (dispatch) => {
  const token = getCookie(process.env.REACT_APP_LOGIN_TOKEN);
  try {
    const response = await axios.get(`${ADS_LIST}/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch(
      adsDataById({
        adsDetailsData: response.data.data,
      })
    );
    return response;
  } catch (error) {
    // dispatch(
    //   productListFailure({
    //     status: false,
    //     message: error.message,
    //   })
    // );
  }
};

export const reserveAd = (adId, reserveData) => async (dispatch) => {
  const token = getCookie(process.env.REACT_APP_LOGIN_TOKEN);
  if (!token) {
    dispatch(reserveAdFailure("Authorization token is missing"));
    return;
  }

  dispatch(reserveAdStart());

  try {
    const response = await axios.patch(
      `${ADS_LIST}/${adId}`,
      { reserve: reserveData },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );

    dispatch(reserveAdSuccess(response.data));
  } catch (error) {
    dispatch(reserveAdFailure(error.response?.data?.message || error.message));
  }
};

export const changeAdStatus = (adId, reqBody) => async (dispatch) => {
  const token = getCookie(process.env.REACT_APP_LOGIN_TOKEN);
  // https://verkaufdev.semseosmo.com/api/v1/my-ads/status/25

  try {
    const response = await axios.patch(`${ADS_LIST}/status/${adId}`, reqBody, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
    if (response?.data?.status) {
      dispatch(changeAdStatusSuccess({ id: adId }));
    }
  } catch (error) {}
};

export const deleteMyAds = (adId, reqBody) => async (dispatch) => {
  const token = getCookie(process.env.REACT_APP_LOGIN_TOKEN);

  try {
    const response = await axios.patch(`${ADS_LIST}/delete/${adId}`, reqBody, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
    if (response?.data?.status) {
      dispatch(deleteAdsSuccess({ id: adId }));
    }
  } catch (error) {}
};

export default MyadsSlice.reducer;
