import {
  Box,
  Button,
  FormControl,
  Grid,
  Input,
  InputAdornment,
} from "@mui/material";
import React, { useState } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import Header from "../../../components/Header/Header";
import Password from "../../../assets/images/LoginImages/password.svg";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { resendOtp, verifyEmailOtp } from "../../../store/auth/authSlice";

function VerificationPage() {
  const [otp, setOtp] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const endpoint = window.location.pathname;
  const backHomeBtn = () => {
    navigate("/login");
  };
  const handleResendOtp = () => {
    if (sessionStorage.getItem("userEmail")) {
      const reqBody = {
        email: sessionStorage.getItem("userEmail"),
      };
      dispatch(resendOtp(reqBody)).then((res) => {
        if (res?.data?.status) {
          toast.success(res?.data?.message || "Otp sent successfully!");
        } else {
          toast.error(res?.data?.message || "something went wrong");
        }
      });
    }
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    if (sessionStorage.getItem("userEmail") && otp) {
      let reqBody = {
        email: sessionStorage.getItem("userEmail"),
        otp: otp,
        oldEmail: "",
        updateEmail: false,
        signIn: false,
      };
      let shouldRedirectHome =
        endpoint === "/email-verification" ? true : false;
      dispatch(verifyEmailOtp(reqBody,shouldRedirectHome)).then((res) => {
        if (res?.status) {
          toast.success(res?.data?.message || "Otp verified successfully!");
          if (endpoint === "/email-verification") {
            navigate("/");
          } else {
            navigate("/forgot-password/verification/newpassword");
          }
        } else {
          toast.error(res?.data?.message || "Otp verification failed");
        }
      });
    }
  };

  return (
    <>
      <Header />
      <Button
        startIcon={<ArrowBackIcon />}
        sx={{
          color: " #707187",
          position: "relative",
          top: "50px",
          left: "220px",
        }}
        onClick={backHomeBtn}
      >
        Back To Login Page
      </Button>
      <Box
        container
        sx={{
          border: "0.5px solid #D8D8DF",
          position: "relative",
          height: "410px",
          width: "936px",
          left: "200px",
          top: "50px",
          bgcolor: "#FFF",
          boxShadow:
            "3px 10px 3px 0px rgba(0, 0, 0, 0.00), 2px 7px 3px 0px rgba(0, 0, 0, 0.01), 1px 4px 2px 0px rgba(0, 0, 0, 0.05), 0px 2px 2px 0px rgba(0, 0, 0, 0.09), 0px 0px 1px 0px rgba(0, 0, 0, 0.10)",
        }}
      >
        <Box sx={{ padding: "45px" }}>
          <div className="loginfom">
            <h1 className="headline">Check Your Email.</h1>
            <p className="subtitle">
              Please check {sessionStorage.getItem("userEmail")} email and enter
              6 digit code.
            </p>
            <p
              onClick={handleResendOtp}
              style={{ color: "#1976d2", cursor: "pointer" }}
              className="subtitle"
            >
              Resend Otp
            </p>
            <form onSubmit={handleSubmit} action="" className="form">
              <Grid
                container
                spacing={{ xs: 2, md: 3 }}
                columns={{ xs: 4, sm: 8, md: 12 }}
              >
                <Grid item xs={12} sm={3} display="flex" alignItems="center">
                  <FormControl variant="standard">
                    <Input
                      onChange={(e) => setOtp(e.target.value)}
                      placeholder="Enter Here"
                      sx={{ width: "250px", color: "grey", marginTop: "20px" }}
                      id="input-with-icon-adornment"
                      startAdornment={
                        <InputAdornment position="start">
                          <img src={Password} alt="password" className="icon" />
                        </InputAdornment>
                      }
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Button
                variant="contained"
                //   disabled
                type="submit"
                sx={{
                  marginBottom: "50px",
                  width: "300px",
                  marginTop: "60px",
                  backgroundColor: "#00A6E8",
                  color: "white",
                }}
              >
                Verify
              </Button>
            </form>
          </div>
        </Box>
      </Box>
    </>
  );
}

export default VerificationPage;
