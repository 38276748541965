import React, { useState } from "react";
import {
  Dialog,
  DialogContent,
  Box,
  DialogTitle,
  Typography,
  Button,
  DialogActions,
  TextField,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";

import { useDispatch } from "react-redux";
import { deleteMyAds } from "../../store/myads/myadsSlice";

// Enum for deletion reasons
const DeletionReasons = {
  SOLD_ON_PLATFORM: "I sold on VerkaufAlles",
  SOLD_ELSEWHERE: "I sold somewhere else",
  COULD_NOT_SELL: "I couldn't sell",
  OTHER: "Other reason",
};

const DeleteMyAds = ({ open, onClose, adId }) => {
  const dispatch = useDispatch();
  const [selectedReason, setSelectedReason] = useState("");
  const [otherReason, setOtherReason] = useState("");

  const handleReasonChange = (event) => {
    setSelectedReason(event.target.value);
    if (event.target.value !== DeletionReasons.OTHER) {
      setOtherReason("");
    }
  };

  const handleDelete = () => {
    if (!selectedReason) return;

    const payload = {
      reason: selectedReason,
      comment: selectedReason === DeletionReasons.OTHER ? otherReason : "",
    };

    dispatch(deleteMyAds(adId, payload)).then(() => {
      onClose(false);
      setSelectedReason("");
      setOtherReason("");
    });
  };



  return (
    <Box mt={2}>
      <Dialog
        open={open}
        onClose={onClose}
        PaperProps={{
          sx: {
            maxWidth: "360px",
            backgroundColor: "#fff",
            borderTopLeftRadius: "12px",
            borderTopRightRadius: "12px",
          },
        }}
      >
        <DialogTitle>
          <Typography variant="h6" sx={{ marginBottom: "8px" }}>
            Please tell us why you want to delete your Ad?
          </Typography>
          <Typography variant="subtitle2" sx={{ color: "grey.600" }}>
            This action cannot be undone and all your data will be lost.
          </Typography>
        </DialogTitle>

        <DialogContent>
          <FormControl component="fieldset">
            <RadioGroup value={selectedReason} onChange={handleReasonChange}>
              {Object.values(DeletionReasons).map((reason) => (
                <FormControlLabel
                  key={reason}
                  value={reason}
                  control={<Radio />}
                  label={reason}
                />
              ))}
            </RadioGroup>
          </FormControl>

          {selectedReason === DeletionReasons.OTHER && (
            <TextField
              fullWidth
              multiline
              rows={3}
              variant="outlined"
              value={otherReason}
              onChange={(e) => setOtherReason(e.target.value)}
              placeholder="Please specify your reason..."
              sx={{ mt: 2 }}
            />
          )}
        </DialogContent>

        <DialogActions>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
              padding: 2,
            }}
          >
            <Button
              onClick={() => onClose(false)}
              variant="outlined"
              color="primary"
              sx={{
                textTransform: "none",
                padding: "6px 24px",
                marginRight: "20px",
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={handleDelete}
              variant="contained"
              color="info"
              sx={{ textTransform: "none", padding: "6px 24px" }}
              disabled={
                !selectedReason ||
                (selectedReason === DeletionReasons.OTHER && !otherReason)
              }
            >
              Delete
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default DeleteMyAds;
