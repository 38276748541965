import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { getCookie } from "../../utils/cookie";

const FAVOURITE_LIST = `${process.env.REACT_APP_BASE_URL}/favourite`;


const initialState = {
  favouriteList: [],
  status: null,
  message: null,
};

export const FavouriteListSlice = createSlice({
  name: "FavouriteList",
  initialState,
  reducers: {
    favouriteListSuccess: (state, action) => {
      state.data = action.payload.data;
      state.status = action.payload.status;
      state.message = action.payload.message;
    },
    favouriteListFailure: (state, action) => {
      state.status = action.payload.status;
      state.message = action.payload.message;
    },
    adsFavouriteKeyUpdate: (state, action) => {
      state.data = state.data.map((item) =>
        item.id === action.payload.id ? { ...item, favourite: !item.favourite } : item
      );
      state.message = "Added to favourites successfully!";

      // state.data isme uss id ko find krke uska favuorit update krdena
    },
    removeFavouriteSuccess: (state, action) => {
      state.data = state.data.filter((item) => item.id !== action.payload.id);
      state.message = "Removed from favourites successfully!";
    },
  },
});

export const {
  favouriteListSuccess,
  favouriteListFailure,
  adsFavouriteKeyUpdate,
  removeFavouriteSuccess,
} = FavouriteListSlice.actions;

export const fetchFavouriteList = () => async (dispatch) => {
const token = getCookie(process.env.REACT_APP_LOGIN_TOKEN);

  try {
    const response = await axios.get(FAVOURITE_LIST, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch(
      favouriteListSuccess({
        status: response.data.status,
        message: response.data.message,
        data: response.data.data,
      })
    );
    return response;
  } catch (error) {
    dispatch(
      favouriteListFailure({
        status: false,
        message: error.message,
      })
    );
  }
};

export const addFavourite = (id) => async (dispatch) => {
const token = getCookie(process.env.REACT_APP_LOGIN_TOKEN);

  try {
    const response = await axios.post(
      FAVOURITE_LIST,
      { id },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
    dispatch(adsFavouriteKeyUpdate({ id }));
  } catch (error) {
    console.error("Failed to add favourite:", error);
  }
};

export const removeFavourite = (id) => async (dispatch) => {
const token = getCookie(process.env.REACT_APP_LOGIN_TOKEN);

  try {
    const response = await axios.post(
      FAVOURITE_LIST,
      { id },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
    dispatch(removeFavouriteSuccess({ id, ...response.data }));
  } catch (error) {
    console.error("Failed to remove favourite:", error);
  }
};

export default FavouriteListSlice.reducer;
