import React, { useState } from "react";
import Header from "../../../components/Header/Header";
import Sidebar from "../../../components/Sidebar/Sidebar";
import {
  Box,
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  Input,
  InputAdornment,
  InputLabel,
  Radio,
  RadioGroup,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import { encryptPassword } from "../../../utils/encryption";
import { useDispatch } from "react-redux";
import { changePassword } from "../../../store/auth/authSlice";
import { toast } from "react-toastify";

const Account = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [darkMode, setDarkMode] = useState(false);
  const dispatch = useDispatch();

  const handleEncrypt = (text) => {
    const encrypted = encryptPassword.encrypt(text);
    return encrypted;
  };

  const validatePasswords = () => {
    if (!currentPassword || !newPassword || !confirmPassword) {
      setError("All fields are required.");
      return false;
    }
    if (newPassword.length < 8) {
      setError("New password must be at least 8 characters long.");
      return false;
    }
    if (newPassword !== confirmPassword) {
      setError("New password and confirm password do not match.");
      return false;
    }
    setError("");
    return true;
  };

  const handleChangePassword = (event) => {
    event.preventDefault();
    if (!validatePasswords()) return;

    const encryptedCurrentPassword = handleEncrypt(currentPassword);
    const encryptedNewPassword = handleEncrypt(newPassword);
    const reqBody = {
      current_password: encryptedCurrentPassword,
      new_password: encryptedNewPassword,
    };

    dispatch(changePassword(reqBody)).then((res) => {
      if (res?.data?.status) {
        toast.success(res?.data?.message);
      } else {
        toast.error("Something went wrong!");
      }
    });
  };

  return (
    <>
      <Header />
      <Box sx={{ display: "flex" }}>
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <Sidebar />
          </Grid>
          <Grid item xs={8.9} overflow="hidden" mt={10.4}>
            <Box bgcolor="white">
              <Box padding={3}>
                <Typography
                  variant="h5"
                  fontFamily="Work Sans"
                  fontSize="20px"
                  fontWeight="600"
                  lineHeight="28px"
                  // color={olor}
                >
                  My Account
                </Typography>
                <Typography
                  variant="subtitle2"
                  color="#707187"
                  fontFamily="Work Sans"
                  fontSize="16px"
                  fontWeight="500"
                  lineHeight="22px"
                  marginTop={2}
                >
                  Please fill in the following details to create your account.
                </Typography>
                <Box>
                  <div
                    style={{
                      marginTop: "43px",
                      fontFamily: "Work Sans",
                      fontSize: "16px",
                      fontWeight: 500,
                      lineHeight: "22px",
                    }}
                  >
                    <p> General Information </p>
                  </div>
                  <Divider
                    className="horizontalLine"
                    style={{
                      marginLeft: "14px",
                      marginTop: "-2px",
                    }}
                  />
                  <FormGroup>
                    <Stack
                      direction={{ xs: "column", sm: "row" }}
                      spacing={{ xs: 1, sm: 2, md: 4 }}
                      paddingBottom={3}
                    >
                      <Switch
                        inputProps={{ "aria-label": "chat notification" }}
                        size="small"
                      />
                      <Typography
                        style={{
                          fontFamily: "Work Sans",
                          fontSize: "14px",
                          fontWeight: 500,
                        }}
                      >
                        Chat Notification
                      </Typography>
                      <Switch
                        inputProps={{ "aria-label": "activity notification" }}
                        size="small"
                      />
                      <Typography
                        style={{
                          fontFamily: "Work Sans",
                          fontSize: "14px",
                          fontWeight: 500,
                        }}
                      >
                        Activity Notification
                      </Typography>
                      {/* <Switch
                        inputProps={{ "aria-label": "dark mode" }}
                        size="small"
                        // checked={darkMode}
                        // onChange={handleToggleDarkMode}
                      />
                      <Typography
                        style={{
                          fontFamily: "Work Sans",
                          fontSize: "14px",
                          fontWeight: 500,
                        }}
                      >
                        Dark Mode
                      </Typography> */}
                    </Stack>
                  </FormGroup>
                  <div
                    style={{
                      fontFamily: "Work Sans",
                      fontSize: "16px",
                      fontWeight: 500,
                    }}
                  >
                    <p> Select Language </p>
                  </div>
                  <Divider
                    className="horizontalLine"
                    style={{ marginLeft: "-13px", marginTop: "-2px" }}
                  />
                  <FormControl>
                    <RadioGroup
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                    >
                      <Stack
                        direction={{ xs: "column", sm: "row" }}
                        spacing={{ xs: 1, sm: 2, md: 4 }}
                        flex={3}
                        marginLeft="-10px"
                      >
                        <FormControlLabel
                          value="english"
                          control={<Radio />}
                          label="English"
                          style={{
                            border: "1px solid #D8D8DF",
                            margin: "10px",

                            fontSize: "10px",
                            width: "229px",
                            fontFamily: "Work Sans",
                            fontWeight: 600,
                            borderRadius: "4px",
                          }}
                        />
                        <FormControlLabel
                          value="alemana"
                          control={<Radio />}
                          label="Alemana"
                          style={{
                            border: "1px solid #D8D8DF",
                            padding: "10px 0px",
                            margin: "10px",

                            fontSize: "16px",
                            width: "229px",
                            fontFamily: "Work Sans",
                            fontWeight: 600,
                            borderRadius: "4px",
                          }}
                        />
                      </Stack>
                    </RadioGroup>
                  </FormControl>
                  <div
                    style={{
                      fontFamily: "Work Sans",
                      fontSize: "16px",
                      fontWeight: 500,
                      marginTop: "32px",
                    }}
                  >
                    <p> Password Change </p>
                  </div>
                  <Divider
                    className="horizontalLine"
                    style={{ marginLeft: "-3px", marginTop: "-2px" }}
                  />
                  <form
                    action=""
                    className="form"
                    onSubmit={handleChangePassword}
                  >
                    <Grid container spacing={3}>
                      <Grid
                        item
                        xs={12}
                        sm={3}
                        display="flex"
                        alignItems="center"
                      >
                        <FormControl variant="standard">
                          <InputLabel
                            htmlFor="input-with-icon-adornment"
                            style={{
                              fontFamily: "Work Sans",
                              fontSize: "17px",
                              fontWeight: 500,
                            }}
                          >
                            Current Password
                          </InputLabel>
                          <Input
                            type={showPassword ? "text" : "password"} // Toggle password visibility
                            onChange={(e) => setCurrentPassword(e.target.value)}
                            placeholder="Enter Here"
                            id="input-with-icon-adornment"
                            startAdornment={
                              <InputAdornment position="start"></InputAdornment>
                            }
                          />
                        </FormControl>
                      </Grid>

                      <Grid
                        item
                        xs={12}
                        sm={3}
                        display="flex"
                        alignItems="center"
                      >
                        <FormControl variant="standard">
                          <InputLabel
                            htmlFor="standard-adornment-password"
                            style={{
                              fontFamily: "Work Sans",
                              fontSize: "17px",
                              fontWeight: 500,
                            }}
                          >
                            New Password
                          </InputLabel>
                          <Input
                            type={showPassword ? "text" : "password"} // Toggle password visibility
                            onChange={(e) => setNewPassword(e.target.value)}
                            placeholder="Enter Here"
                            id="standard-adornment-password"
                            startAdornment={
                              <InputAdornment position="start"></InputAdornment>
                            }
                          />
                        </FormControl>
                      </Grid>

                      <Grid
                        item
                        xs={12}
                        sm={3}
                        display="flex"
                        alignItems="center"
                      >
                        <FormControl variant="standard">
                          <InputLabel
                            htmlFor="standard-adornment-password"
                            style={{
                              fontFamily: "Work Sans",
                              fontSize: "17px",
                              fontWeight: 500,
                            }}
                          >
                            Confirm Password
                          </InputLabel>
                          <Input
                            type={showPassword ? "text" : "password"} // Toggle password visibility
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            placeholder="Enter Here"
                            id="standard-adornment-password"
                            startAdornment={
                              <InputAdornment position="start"></InputAdornment>
                            }
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                    {error && (
                      <Typography
                        color="error"
                        variant="body2"
                        style={{ marginTop: 10 }}
                      >
                        {error}
                      </Typography>
                    )}
                    <Button
                      type="submit"
                      variant="contained"
                      style={{
                        backgroundColor: "#11142D",
                        color: "white",
                        marginTop: "30px",
                        borderRadius: "5px",
                      }}
                    >
                      Change Password
                    </Button>
                    <div
                      style={{
                        color: "#26252C",
                        fontFamily: "Work Sans",
                        fontSize: "16px",
                        fontStyle: "normal",
                        fontWeight: 500,
                        lineHeight: "22px",
                        marginTop: "32px",
                      }}
                    >
                      <p> Permanent Delete My Account </p>
                    </div>
                    <Divider
                      className="horizontalLine"
                      style={{ marginLeft: "98px", marginTop: "-2px" }}
                    />
                    <Button
                      disabled
                      variant="outlined"
                      type="submit"
                      style={{
                        width: "300px",

                        textTransform: "none",
                        // backgroundColor: "#00A6E8",
                        // color: "black",
                        marginBottom: "100px",
                      }}
                    >
                      Account Delete
                    </Button>
                  </form>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Account;
