import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";

const ImageViewer = ({
  images,
  initialSelectedImage,
  altText = "Image",
  isFile = false,
}) => {
  const [selectedImage, setSelectedImage] = useState(
    initialSelectedImage || images[0]
  );
  const [imageObjectURL, setImageObjectURL] = useState("");

  useEffect(() => {
    // Clean up the previous Object URL when the component is unmounted or image changes
    if (isFile && selectedImage instanceof File) {
      const objectURL = URL.createObjectURL(selectedImage);
      setImageObjectURL(objectURL);
      return () => {
        // Revoke the previous Object URL when the component unmounts
        URL.revokeObjectURL(objectURL);
      };
    } else {
      // If it's not a file, clear the Object URL
      setImageObjectURL("");
    }
  }, [selectedImage, isFile]);

  const getImageSrc = (image) => {
    if (isFile && image instanceof File) {
      return URL.createObjectURL(image);
    }
    return image; // URL for regular images
  };

  return (
    <Box>
      {/* Selected Image */}
      <img
        src={
          isFile && selectedImage instanceof File
            ? imageObjectURL
            : selectedImage
        }
        alt={altText}
        style={{ width: "100%", height: "618px" }}
      />

      {/* Thumbnails */}
      <Box sx={{ display: "flex", marginTop: "12px", gap: "8px" }}>
        {images?.length > 0 &&
          images?.map((image, index) => (
            <img
              key={index}
              src={getImageSrc(image)}
              alt={altText}
              onClick={() => setSelectedImage(image)}
              style={{
                width: "144px",
                height: "94px",
                objectFit: "cover",
                borderRadius: "4px",
                border:
                  selectedImage === getImageSrc(image)
                    ? "4px solid #00A6E8"
                    : "2px solid transparent",
                cursor: "pointer",
              }}
            />
          ))}
      </Box>
    </Box>
  );
};

export default ImageViewer;
