import React from "react";
import { Button, Grid, Icon, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Facebook, Instagram, YouTube } from "@mui/icons-material";

// Style
const categoryHeading = {
  color: "#FFF",
  fontFamily: "Work Sans",
  fontSize: "20px",
  fontStyle: "normal",
  fontWeight: 600,
  lineHeight: "28px",
};

const categoryDataText = {
  padding: 0,
  textAlign: "left",
  marginTop: "12px",
  color: "#FFF",
  fontFamily: "Work Sans",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "18px",
  justifyContent: "flex-start",
};

const myLink = [
  { id: 1, name: "My Account" },
  { id: 2, name: "My Ads" },
  { id: 3, name: "Following" },
  { id: 4, name: "Favourite" },
  { id: 5, name: "Help" },
];

const others = [
  { id: 1, name: "About Us" },
  { id: 2, name: "Terms and Conditions" },
  { id: 3, name: "Privacy & Policy" },
];

const socialMedia = [
  // { id: 1, name: "TikTok" },
  { id: 2, name: "Facebook", icon:<Facebook/> },
  { id: 3, name: "Instagram", icon:<Instagram/> },
  { id: 4, name: "YouTube", icon:<YouTube/> },
];

const contactInfo = [
  { id: 1, name: "Email 1" },
  { id: 2, name: "Email 2" },
];

function Footer() {
  const navigate = useNavigate();

   const categoryList = useSelector(
     (state) => state?.categoryReducer?.mainCategorydata
  );  

  const handleNavOthers = (name) => {
    if (name === "Terms and Conditions") {
      navigate('/terms-and-conditions');
    } else if (name === "Privacy & Policy") {
      navigate('/privacy-and-policies');
    } else if (name === "About Us") {
      navigate('/about-us');
    }
  };

  const handleMyLink = (name) => {
    if (name === "My Ads") {
      navigate("/my-ads");
    } else if (name === "My Account") {
      navigate("/account");
    } else if (name === "Following") {
      navigate("/following");
    } else if (name === "Favourite") {
      navigate("/favourite");
    } else if (name ==="Help") {
      navigate("/help");
    }
  };

  const handleCategory = (slug) => {
    navigate(`/category/${slug}`)
  };

  return (
    <Grid
      container
      spacing={2}
      sx={{
        background: "#00a6e8",
        marginTop: "24px",
        paddingBottom: "24px",
      }}
      justifyContent={"center"}
    >
      {/* Category */}
      <Grid item xs={12} sm={6} md={4} lg={2} xl={2}>
        <Typography sx={categoryHeading}>Category</Typography>
        {categoryList.map((item, index) => (
          <Grid key={item.id}>
            <Button
              key={index}
              sx={categoryDataText}
              onClick={() => handleCategory(item.slug
              )}
            >
              {item.name}
            </Button>
          </Grid>
        ))}
      </Grid>

      {/* MyLink */}
      <Grid item xs={12} sm={6} md={4} lg={2} xl={2}>
        <Typography sx={categoryHeading}>My Link</Typography>
        {myLink.map((item, index) => (
          <Grid key={item.id}>
            <Button
              key={index}
              sx={categoryDataText}
              onClick={() => handleMyLink(item.name)}
            >
              {item.name}
            </Button>
          </Grid>
        ))}
      </Grid>

      {/* Others */}
      <Grid item xs={12} sm={6} md={4} lg={2} xl={2}>
        <Typography sx={categoryHeading}>Others</Typography>
        {others.map((item, index) => (
          <Grid key={item.id}>
            <Button
              key={index}
              sx={categoryDataText}
              onClick={() => handleNavOthers(item.name)}
            >
              {item.name}
            </Button>
          </Grid>
        ))}
      </Grid>

      {/* Social media */}
      <Grid item xs={12} sm={6} md={4} lg={2} xl={2}>
        <Typography sx={categoryHeading}>
          Social Media
        </Typography>
        <Grid container direction="row">
        {socialMedia.map((item, index) => (
          <Grid key={item.id}>
            <Button key={index} sx={categoryDataText}>
              {item.icon}
            </Button>
          </Grid>
        ))}
      </Grid>
        </Grid>

      {/* Contact Info */}
      <Grid item xs={12} sm={6} md={4} lg={2} xl={2}>
        <Typography sx={categoryHeading}>Contact Info</Typography>
        {contactInfo.map((item, index) => (
          <Grid key={item.id}>
            <Button key={index} sx={categoryDataText}>
              {item.name}
            </Button>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
}

export default Footer;
