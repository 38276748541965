import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { eraseCookie, getCookie, setCookie } from "../../utils/cookie";

const getToken = () => getCookie(process.env.REACT_APP_LOGIN_TOKEN);

export const AuthSlice = createSlice({
  name: "auth",
  initialState: {
    isAuthenticated: false,
    currentUserData: {},
    accountType: null,
  },
  reducers: {
    setAuthData: (state, action) => {
      setCookie(process.env.REACT_APP_LOGIN_TOKEN, action?.payload?.token, 30);
      state.isAuthenticated = action?.payload?.isAuthenticated;
    },

    setCurrentUserData(state, action) {
      state.currentUserData = action?.payload?.currentUserData;
      state.accountType =
        action?.payload?.currentUserData?.type === 1 ? "COMMERCIAL" : "PRIVATE";
    },
    authenticate: (state, action) => {
      state.isAuthenticated = action?.payload?.isAuthenticated;
    },
    logout: (state) => {
      eraseCookie(process.env.REACT_APP_LOGIN_TOKEN);
      state.isAuthenticated = false;
    },
  },
});

const authApi = `${process.env.REACT_APP_BASE_URL}`;
const token = getToken();
export const registerUser = (reqBody) => async (dispatch) => {
  try {
    const response = await axios.post(`${authApi}/register`, reqBody);
    if (response?.data?.status) {
      dispatch(
        setAuthData({
          token: response?.data?.data?.token,
          isAuthenticated: false,
        })
      );
      dispatch(
        setCurrentUserData({
          currentUserData: response?.data?.data,
        })
      );
    }
    return response;
  } catch (error) {
    console.error("Login error: ", error);
  }
};

export const loginUser = (reqBody) => async (dispatch) => {
  try {
    const response = await axios.post(`${authApi}/signin`, reqBody);
    if (response?.data?.status) {
      dispatch(
        setAuthData({
          token: response?.data?.data?.token,
          isAuthenticated: true,
        })
      );
      dispatch(
        setCurrentUserData({
          currentUserData: response?.data?.data,
        })
      );
    }
    return response;
  } catch (error) {
    console.error("Login error: ", error);
  }
};

export const logoutUser = () => async (dispatch) => {
  if (!token) {
    console.error("No token found, cannot log out.");
    return;
  }
  try {
    const response = axios.get(`${authApi}/signout`, {
      headers: { Authorization: `Bearer ${token}` },
    });

    if (response) {
      dispatch(logout());
    } else {
      console.error("Logout error: ", response);
    }
  } catch (error) {
    console.error("Logout error: ", error);
  }
};

export const forgotPassword = (reqBody) => async (dispatch) => {
  try {
    const response = await axios.post(`${authApi}/forget-password`, reqBody);
    return response;
  } catch (error) {}
};

export const changePassword = (reqBody) => async (dispatch) => {
  try {
    const response = await axios.post(`${authApi}/changepassword`, reqBody, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (error) {}
};

export const verifyEmailOtp =
  (reqBody, shouldRedirectHome = false) =>
  async (dispatch) => {
    try {
      const response = await axios.post(`${authApi}/verify-email`, reqBody);
      if (response?.data?.status && shouldRedirectHome) {
        dispatch(
          authenticate({
            isAuthenticated: true,
          })
        );
      }
      return response?.data;
    } catch (error) {}
  };

export const resendOtp = (reqBody) => async (dispatch) => {
  try {
    const response = await axios.post(`${authApi}/send-otp`, reqBody);
    return response;
  } catch (error) {}
};

export const resetPassword = (reqBody) => async (dispatch) => {
  try {
    const response = await axios.post(`${authApi}/reset-password`, reqBody);
    return response;
  } catch (error) {}
};

export const { setAuthData, setCurrentUserData, logout, authenticate } =
  AuthSlice.actions;

export default AuthSlice.reducer;
