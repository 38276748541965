import React, { useState } from "react";
import {
  Dialog,
  DialogContent,
  Box,
  DialogTitle,
  Typography,
  Button,
  DialogActions,
  styled,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useDispatch } from "react-redux";
import { reserveAd } from "../../store/myads/myadsSlice";
import dayjs from "dayjs";

const CustomDatePicker = styled(DatePicker)(({ theme }) => ({
  "& .MuiOutlinedInput-root": {
    border: "none",
    // borderBottom: "2px solid #A259FF",
    // borderRadius: "0",
    // padding: "4px 0",
  },
  "& .MuiOutlinedInput-input": {
    fontSize: "14px",
    color: theme.palette.grey[600],
  },
  "& .MuiInputLabel-root": {
    color: theme.palette.grey[700],
    fontWeight: "bold",
    fontSize: "12px",
  },
  "& .MuiSvgIcon-root": {
    color: theme.palette.grey[600],
  },
}));

const ReserveDialogBox = ({ open, onClose, adId }) => {
  const dispatch = useDispatch();
  const [selectedDate, setSelectedDate] = useState(null);

  const handleReserve = () => {
    if (!selectedDate) {
      alert("Please select a date.");
      return;
    }

    const reserveData = {
      status: true,
      label: selectedDate.format("YYYY-MM-DD"),
      value: selectedDate.unix(),
    };

    dispatch(reserveAd(adId, reserveData));
    onClose();
  };

  return (
    <>
      <Box mt={2}>
        <Dialog
          open={open}
          onClose={onClose}
          PaperProps={{
            sx: {
              maxWidth: "360px",
              backgroundColor: "#fff",
              borderTopLeftRadius: "12px",
              borderTopRightRadius: "12px",
            },
          }}
        >
          <DialogTitle>
            <Typography variant="h6" sx={{ marginBottom: "8px" }}>
              Reserve product for few Days.
            </Typography>
            <Typography variant="subtitle2" sx={{ color: "grey.600" }}>
              You can remove reservation anytime, and reservation end after
              entered date.
            </Typography>
          </DialogTitle>

          <DialogContent>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <CustomDatePicker
                label="Select Date"
                sx={{ width: "100%", border: "transparent" }}
                value={selectedDate}
                onChange={(date) => setSelectedDate(dayjs(date))}
              />
            </LocalizationProvider>
          </DialogContent>
          <DialogActions>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
                padding: 2,
              }}
            >
              <Button
                onClick={onClose}
                variant="outlined"
                color="primary"
                sx={{
                  textTransform: "none",
                  padding: "6px 24px",
                  marginRight: "20px",
                }}
              >
                Cancel
              </Button>
              <Button
                onClick={handleReserve}
                variant="contained"
                color="info"
                sx={{ textTransform: "none", padding: "6px 24px" }}
              >
                Reserve
              </Button>
            </Box>
          </DialogActions>
        </Dialog>
      </Box>
    </>
  );
};

export default ReserveDialogBox;
