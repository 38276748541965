import React, { useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  MenuList,
  MenuItem,
  TextField,
  Typography,
  Box,
  Divider,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; // Import styles
import { reportAds } from "../../store/product/productSlice";
import { ThumbDownAltOutlined } from "@mui/icons-material";

const ProductFeedback = {
  scam: "Scam or fraud?",
  illegal: "Selling illegal or regulated goods?",
  nudity: "Nudity or sexual acts?",
  hate: "Hate speech or symbols?",
  other: "Other reasons?",
};

const reportIcon = {
  color: "#BB1A1A",
};

const reportBtn = {
  textTransform: "none",
  color: "#000",
  fontSize: "13px",
  lineHeight: "18px",
  font: "Work Sans",
};

const ReportAdDialog = ({ adId }) => {
  const [selectedReason, setSelectedReason] = useState("");
  const [open, setOpen] = useState(false);
  const [otherReason, setOtherReason] = useState("");
  const dispatch = useDispatch();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleReasonSelect = (reason) => {
    setSelectedReason(reason);
    if (reason !== "other") {
      setOtherReason("");
    }
  };

  const handleOtherReasonChange = (event) => {
    setOtherReason(event.target.value);
  };

  const handleReport = () => {
    const reportReason =
      selectedReason === "other"
        ? otherReason
        : ProductFeedback[selectedReason];

    const reportData = {
      type: "report",
      data: {
        type: selectedReason,
        message: reportReason,
      },
    };

    dispatch(reportAds(adId, reportData));
    toast.success("Ad has been reported successfully!", {
    });
    handleClose();
  };

  return (
    <>
      <Box mt={2}>
        <Button
          onClick={handleClickOpen}
          startIcon={<ThumbDownAltOutlined sx={reportIcon} />}
          sx={reportBtn}
        >
          Report an issue for this listing
        </Button>
        <Dialog
          open={open}
          onClose={handleClose}
          PaperProps={{
            sx: {
              width: "600px",
              maxWidth: "800px",
              backgroundColor: "#F7F7F8",
            },
          }}
        >
          <DialogTitle>
            <Typography variant="h6" fontWeight={600}>Why are you reporting this ad?</Typography>
          </DialogTitle>
          <Divider />
          <DialogContent>
            <MenuList>
              {Object.keys(ProductFeedback).map((key) => (
                <MenuItem
                  key={key}
                  selected={selectedReason === key}
                  onClick={() => handleReasonSelect(key)}
                  sx={{
                    border: "0.5px solid #FFFFFF",
                    borderRadius: "4px",
                    marginBottom: "8px",
                    backgroundColor: "#FFFFFF",
                    fontSize: "14px",
                    lineHeight: "18px",
                    font: "Work Sans",
                    "&.Mui-selected": {
                      backgroundColor: "#00A6E8",
                      color: "#fff",
                    },
                  }}
                >
                  {ProductFeedback[key]}
                </MenuItem>
              ))}
            </MenuList>
            {selectedReason === "other" && (
              <TextField
                label="Please specify"
                multiline
                variant="standard"
                value={otherReason}
                onChange={handleOtherReasonChange}
                fullWidth
                margin="normal"
              />
            )}
          </DialogContent>
          <Divider />
          <DialogActions sx={{ backgroundColor: "#fff" }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
                padding: 2,
              }}
            >
              <Button
                onClick={handleClose}
                variant="outlined"
                color="primary"
                sx={{
                  textTransform: "none",
                  padding: "6px 24px",
                  marginRight: "20px",
                }}
              >
                Cancel
              </Button>
              <Button
                onClick={handleReport}
                variant="contained"
                color="info"
                sx={{ textTransform: "none", padding: "6px 24px" }}
              >
                Report
              </Button>
            </Box>
          </DialogActions>
        </Dialog>
        <ToastContainer />
      </Box>
    </>
  );
};

export default ReportAdDialog;
