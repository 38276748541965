import React, { useState } from "react";
import { Avatar, ListItem, ListItemText, MenuItem, MenuList, Paper } from "@mui/material";
import { Link } from "react-router-dom";
import Car from "../../assets/images/ChatRoom/Car.jpg";

const Styles = {
  menuItemStyle: {
    paddingLeft: "0",
    paddingRight: "0",
    },
    avatorList: {
        width: "70px",
        height: "70px",
        borderRadius: "10%",
    },
    listItems: {
        marginLeft: "10px",
    }
};

const menuItems = [
  { path: "/account", label: "My Account" },
  { path: "/profile", label: "Profile" },
  { path: "/my-ads", label: "My Ads" },
  { path: "/following", label: "Following" },
  { path: "/favourite", label: "Favourite" },
  { path: "/help", label: "Help" },
  { path: "/suggestions-complaints", label: "Suggestions & Complaints" },
];

const ChatSideBar = () => {
  const [openProfilePopup, setOpenProfilePopup] = useState(null);
  const endpoint = window.location.pathname;

  const handleCloseProfile = () => {
    setOpenProfilePopup(null);
  };

  return (
    <Paper
      sx={{
        direction: "column",
        height: "100%",
      }}
    >
      <MenuList>
        {menuItems.map((item) => (
          <MenuItem key={item.path} sx={Styles.menuItemStyle}>
            <ListItem
              sx={{
                bgcolor: endpoint === item.path ? "#00A6E8" : "#fff",
                color: endpoint === item.path ? "#fff" : "#000",
                height: "100px",
              }}
              component={Link}
              to={item.path}
              onClick={handleCloseProfile}
            >
              <Avatar variant="square" sx={Styles.avatorList}>
                <img
                  src={Car}
                  alt="img"
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover", // Ensures the image covers the avatar without stretching
                  }}
                />
              </Avatar>
              <ListItemText primary={item.label} sx={Styles.listItems} />
            </ListItem>
          </MenuItem>
        ))}
      </MenuList>
    </Paper>
  );
};

export default ChatSideBar;
